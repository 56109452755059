/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ad76eb95-a86c-4090-9a27-c70990f8b381",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iyJm0xcjb",
    "aws_user_pools_web_client_id": "fltu53o7ar545a33l9j5k4vbh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ommobileb60e6796b93b46e694852720faf795a4142905-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
