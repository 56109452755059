import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DonateCheckout from './DonateCheckout';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
//const promise = loadStripe("pk_test_51HHMFBLb5KZXfDYgLFQAetYb7cGGuiegoT1Sjm9RE8FSyLA5QgYmlvJ2lkBlPVhQAkm3kVQHsbziLS18WztEybFy00SiEfx3Mm");
//const promise = loadStripe("pk_live_51HHMFBLb5KZXfDYguK930v3O6ydugI72ThwFDVzIaKdqIVVOsmUDEHJCBm9E6eJAeLGSjvTNmdXNYLXfD9Q9G8R0005gh9V8zN");
const promise = loadStripe("pk_live_51LT6XoK9SNThUuvs0xz38Hjy7AvD8QPZk7Jvt3B6wbJ9XUwkg67BkjQzP3qe0Kqox1C77oVTgVQ3NscU7bA9Jrts001KpBTOGO"); // our mindset live
//const promise = loadStripe("pk_test_51LT6XoK9SNThUuvsRM76KS5P9qmZKWl4lyWUh7EwUPCd7ZhLX5oPLdvUPIgyUwn7lY2uS1WW5fVxoglLAh1YEikN00m4AmwdWT"); //our mindset test
class Donate extends Component {
constructor(props) {
    super(props);
  }

    componentDidMount() {
    window.scroll(0, 0);
  }

  render() {

        let props = {
            charge: "2000",
            price: "20.00"
        }
   return ( <div className="Donate page-container">
    <div className="inner-container">
        <Header history={this.props.history} />
        <div className="payment-box">
        <div className="flexbox-container-column">
        <div className="flex-item pb-10">
                <h5 className="text-center">Your donations make it possible for us to provide amazing content here at Our Mindset. Thank you!</h5>
                </div>
<div className="flex-item">
                                      <Elements stripe={promise}>
                            <DonateCheckout {...props} />
                          </Elements>


            </div>
            </div>
            </div>
            </div>
        <Footer />
    </div>
    );
  }

}

export default Donate;