import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/OMTransparentLogo.png';
import {APIHost} from '../GlobalVariables.js';
import { BellOutlined, MailTwoTone, MailOutlined } from '@ant-design/icons';
import Popup from "reactjs-popup";
import Avatar from '@mui/material/Avatar';
import placeholder from '../images/placeholder-profile-image.jpeg';


class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display_items: false,
			isLogged: this.props.loggedIn,
			connection_requests: [],
			unread_messages: false,
			user_messages: [],
			user_details: []
		}
        this.DirectToRegister = this.DirectToRegister.bind(this);
		this.ToggleItems = this.ToggleItems.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.Logout = this.Logout.bind(this);
		this.logoutCallBack = this.logoutCallBack.bind(this);
		this.CheckLogin = this.CheckLogin.bind(this);
		this.GetConnectionRequests = this.GetConnectionRequests.bind(this);
		this.Connect = this.Connect.bind(this);
		this.ConnectionCallback = this.ConnectionCallback.bind(this);
		this.GetDirectMessages = this.GetDirectMessages.bind(this);
		this.DirectToMail = this.DirectToMail.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		var promises = [];
		var tmp_user_msgs = [];
		var tmp_user_details = [];
		var tmp_connections = [];
		var tmp_all_users = [];
        this.CheckLogin().then(res => {
            if (res.data.loggedIn) {
				var message_promise = this.GetDirectMessages().then((messages) => {
					if (messages.data != "no messages") {
						tmp_user_msgs = messages.data.direct_messages;
					} else {
						tmp_user_msgs = "no messages";
					}
				}).catch((error) => {
					console.log("GetDirectMessages failed " + error);
					this.setState({server_failure: true});
				});
            	var user_promise = this.GetUserDetails().then(user_details => {
            		tmp_user_details = user_details.data.user_details;
                    return user_details;
                }).catch(error => {
                     console.log("GetUserDetails failed " + error);
                    this.setState({server_failure: true});
                });
            	var connections_promise = this.GetConnectionRequests().then(requests => {
            		if (requests.data != "no requests") {
            			tmp_connections = requests.data.connection_requests;
                	} 
                }).catch((error) => {
                    console.log("GetConnectionRequests failed " + error);
                    this.setState({server_failure: true});
                });
                var all_promise = this.GetAllUsers().then(all_users => {
                	tmp_all_users = all_users.data.user_details;
                }).catch((error) => {
                    console.log("GetAllUsers failed " + error);
                    this.setState({server_failure: true});
                });

                promises.push(user_promise);
                promises.push(connections_promise);
                promises.push(all_promise);
                promises.push(message_promise);

                Promise.all(promises).then(() => {
                	this.setState({ user_details: tmp_user_details, connection_requests: tmp_connections, all_users: tmp_all_users, isLogged: true, user_messages: tmp_user_msgs, unread_messages: false }, () => { this.setState({loading: false})});
                	var msg_flag = false;
							const newMessageId = setInterval(() => {
							this.GetDirectMessages().then((messages) => {
                				if (messages.data.direct_messages != "no messages") {
                				messages.data.direct_messages.forEach((msg) => {
		                			if (msg.unread_messages) {
		                				msg_flag = true;
		                			}
		                		});
		               		 } else {
		                		msg_flag = false;
		                	}
		                    if (msg_flag) {
                				this.setState({ user_messages: messages.data.direct_messages, unread_messages: true });

                			} else {
                		 		this.setState({ user_messages: messages.data.direct_messages, unread_messages: false });
                			}
							}).catch((error) => {
								clearInterval(newMessageId);
							 	
							});
							}, 5000);
					      this.setState(prevState => {
							return {
						      ...prevState,
						      messageInterval:  newMessageId,
						    };
						  });

							const newConnectionsId = setInterval(() => {
							this.GetConnectionRequests().then((requests) => {

								  if (requests.data != "no requests") {
								  	if (requests.data.connection_requests.length > this.state.connection_requests.length) {
								  		this.setState({connection_requests: requests.data.connection_requests});
								  	}
                				} 
							}).catch((error) => {
								clearInterval(newConnectionsId);
							 	
							});
							}, 5000);
					      this.setState(prevState => {
							return {
						      ...prevState,
						      connectionInterval:  newConnectionsId,
						    };
						  });
     


            	}).catch((error) => {
            		console.log("Promise failed " + error);
            	});
            } else {
                this.setState({ isLogged: false, loading: false });
            }
        }).catch(error => {
            this.setState({ isLogged: false, loading: false });
        });
	}

	componentWillUnmount(){
    clearInterval(this.state.messageInterval);
        clearInterval(this.state.connectionInterval);

  }

	async GetConnectionRequests() {
        const res = await axios.get(APIHost + '/api/getConnectionRequestsForResponse', { withCredentials: true });
        return await res;
    }
    async GetDirectMessages() {
        const res = await axios.get(APIHost + '/api/getDirectMessages', { withCredentials: true });
        return await res;
    }
    async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async GetAllUsers() {
        const res = await axios.get(APIHost + '/api/getAllUsers', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }
	/*componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
        this.setState({
           isLogged: this.props.loggedIn
        })
    }
}*/

	ToggleItems(event) {
		event.preventDefault();
		this.setState({display_items: !this.state.display_items});
	}

   DirectToRegister() {
              //navigate('/Register');
        this.props.history.push({ pathname: "/Register", state: { loggedIn: true } });

    }

    DirectToMail() {
    	this.props.history.push({ pathname: "/DirectMessage", state: { loggedIn: true } });

    }

	logoutCallBack(result) {
					clearInterval(this.state.messageInterval);

		if (result) {
			this.props.history.push({ pathname: '/', state: { loggedIn: false } });
		} else {
			this.setState({ server_failure: true });
			this.props.history.push("/");
		}
	}

	Logout() {
		axios.post(APIHost + '/api/logout', {}, {
			withCredentials: true
		}).then(res => {
			this.setState({ isLogged: false, loading: false });
			this.logoutCallBack(true);
		}).catch(error => {
			this.logoutCallBack(false);
		});
	}

	onLogout(event) {
		event.preventDefault();
		this.setState({ loading: true });
		this.Logout(this.logoutCallBack);
	}

	ConnectionCallback(requestor, response) {
		var cnt = 0;
		var tmp_connections = this.state.connection_requests;
		document.getElementById(requestor+"_accept").disabled = true
		document.getElementById(requestor+"_ignore").disabled = true
		tmp_connections.find((conn) => {
			if (conn.userid == requestor && conn.recipient == this.state.user_details.userID) {
				tmp_connections[cnt].status = "Ignored";
			} else {
				cnt++;
			}
		});
		this.setState({connection_requests: tmp_connections});
	}

	Connect(event, response) {
		axios.post(APIHost + '/api/connectionResponse', {
			withCredentials: true,
			response: response,
			requestor: event.target.name
		}).then(res => {
			this.ConnectionCallback(event.target.name, response);
		}).catch(error => {
			console.log("Updating connections failed");
		});
	}

	DisplayHeader() {
		var display = [];
		var display_notifications = [];
		var pending_flag = false;
		var mail_icon = "";
		 if(this.state.unread_messages) {
			mail_icon = <MailTwoTone />;


		} else {

			mail_icon = <MailOutlined />;
		}
		if (this.state.connection_requests != undefined && this.state.all_users != undefined) {
		this.state.connection_requests.forEach((request) => {
			if (request.status == "Pending" && request.recipient == this.state.user_details.userID) {
				pending_flag = true;
				this.state.all_users.find((user) => {
					if(user.userid == request.userid) {
					display_notifications.push(<div className="flexbox-container"><div className="pr-5">{user.picture_url ? <Avatar
                          alt="Profile picture"
                          src={user.picture_url}
                          sx={{ width: 34, height: 34 }}
                        /> : <Avatar
                          alt="Profile picture"
                          src={placeholder}
                          sx={{ width: 34, height: 34 }}
                        />}</div>
                        <div className="pr-10">{user.name}</div>
                        <div className="pr-10"><button name={user.userid} className="simple-button" id={[`${user.userid}_accept`]} onClick={e => this.Connect(e, "Connected", user.userid)}>Accept</button></div>
                        <div><button name={user.userid} className="simple-button" id={[`${user.userid}_ignore`]} onClick={e => this.Connect(e, "Ignored", user.userid)}>Ignore</button></div></div>);

					}
				});
			}
		});
	}

	/*display_notifications.push(<tr><td className="tr-spacing1">{user.picture_url ? <Avatar
                          alt="Profile picture"
                          src={user.picture_url}
                          sx={{ width: 34, height: 34 }}
                        /> : <Avatar
                          alt="Profile picture"
                          src={placeholder}
                          sx={{ width: 34, height: 34 }}
                        />}
                        </td><td className="tr-spacing2">{user.name}</td><td className="tr-spacing4"><button id={user.userid} className="simple-button" onClick={() => this.Connect("connect")}>Connect</button></td><td><button className="simple-button" onClick={() => this.Connect("ignore")}>Ignore</button></td></tr>);
                        }
                        });*/
		if (!pending_flag) {
			display_notifications.push(<div className="p-5"><h3>No notifications</h3></div>);
		}
		if(this.state.isLogged) {
			if (window.innerWidth > 768) {
    			display.push(
					<header className="header-flex header">
					<div className="flexbox-container">
						<div className="logo">
							<a className="" href="/">
								<img src={logo} alt="Our Mindset" width="100" height="80" />
							</a>
						</div>
						<div className="flexbox-container pr-20">
						<div>
						<Popup trigger={<BellOutlined style={{ fontSize: '24px', color: '#08c' }}/>} position="bottom center">
								<div className="gray-background">{display_notifications}</div>
							</Popup>
							</div>
							<div className="pl-5" onClick={this.DirectToMail}>{mail_icon}</div>
						</div>

						<div className="login-nav">

							<div className="link-padding">
								<Link className="link font-bold" to={{ pathname: "/" }}>Profile</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Contact" }}>Contact</Link>
							</div>
							<div className="px-5">
								<Link onClick={this.onLogout} className="link font-bold" to={{ pathname: "/" }}>Logout</Link>
							</div>

						</div>
						</div>
						<div onClick={this.ToggleItems} className="md:hidden pr-20">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>
				</header>
				);
			} else {
				if (this.state.display_items) {
					display.push(
					<header className="header-flex2 header">
							<div className="flexbox-container">
						<div className="logo">
							<a className="" href="/">
								<img src={logo} alt="Our Mindset"width="100" height="80" />
							</a>
						</div>
						<div className="pl-10">
												<Popup trigger={<BellOutlined style={{ fontSize: '24px', color: '#08c' }}/>} position="bottom center">
								<div className="gray-background">{display_notifications}</div>
							</Popup>
						<div className="" onClick={this.DirectToMail}>{mail_icon}</div>
						</div>
						<div className="login-nav">
							<div className="link-padding">
								<Link className="link font-bold" to={{ pathname: "/" }}>Profile</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Contact" }}>Contact</Link>
							</div>
							<div className="px-5">
								<Link onClick={this.onLogout} className="link font-bold" to={{ pathname: "/" }}>Logout</Link>
							</div>

						</div>
						</div>
						<div onClick={this.ToggleItems} className="md:hidden pr-20">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>
				</header>
	
				);
				} else {
					display.push(
					<header className="header-flex header">
						<div className="logo py-5 px-20">
							<a className="header-img" href="/">
								<img src={logo} alt="Our Mindset" width="80" height="60" />
							</a>
						</div>
						
						<div onClick={this.ToggleItems} className="md:hidden pr-20">
					
						<div className="flexbox-container-messaging">
						<div>

																		<Popup trigger={<BellOutlined style={{ fontSize: '24px', color: '#08c' }}/>} position="bottom center">
								<div className="gray-background">{display_notifications}</div>
							</Popup>
							</div>
													<div className="pl-5 pr-5" onClick={this.DirectToMail}>{mail_icon}</div>

							
							<div>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
							</div>
							</div>
						</div>
					</header>
			);	
				}
			}
		} else {
			if (window.innerWidth > 768) {
    					display.push(<header className="header-flex2 header">
					<div className="flexbox-container">
						<div className="logo">
							<a className="" href="/">
								<img src={logo} alt="Our Mindset" width="80" height="60" />
							</a>
						</div>
						<div className="main-nav">
							<div className="link-padding">
								<Link className="link font-bold" to={{ pathname: "/" }}>Home</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
							</div>

							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Contact" }}>Contact</Link>
							</div>

							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Donate" }}>Donate</Link>
							</div>
						</div>
						<div className="sub-nav">
							<div className="link-padding">
							<Link to={{ pathname: "/Login" }} className="link font-bold">Login</Link>
							</div>
							<button onClick={this.DirectToRegister}  className="custom-btn tracking-wider px-5">Register</button>

						</div>
						</div>
						<div onClick={this.ToggleItems} className="md:hidden pr-20">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>
				</header>);
			} else {
				if (this.state.display_items) {
					display.push(
				<header className="header-flex2 header">
					<div className="flexbox-container">
						<div className="logo">
							<a className="" href="/">
								<img src={logo} alt="Our Mindset" width="80" height="60" />
							</a>
						</div>
						<div className="main-nav">
							<div className="link-padding">
								<Link className="link font-bold" to={{ pathname: "/" }}>Home</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
							</div>
							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
							</div>

							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Contact" }}>Contact</Link>
							</div>

							<div className="px-5">
								<Link className="link font-bold" to={{ pathname: "/Donate" }}>Donate</Link>
							</div>
						</div>
						<div className="sub-nav">
							<div className="link-padding">
							<Link to={{ pathname: "/Login" }} className="link font-bold">Login</Link>
							</div>
							<button onClick={this.DirectToRegister}  className="custom-btn tracking-wider px-5">Register</button>

						</div>
						</div>
						<div onClick={this.ToggleItems} className="md:hidden pr-20">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>
				</header>
	
				);
				} else {
					display.push(
				<header className="header-flex header">
						<div className="logo py-5 px-20">
							<a className="" href="/">
								<img src={logo} alt="Our Mindset" width="80" height="60" />
							</a>
						</div>
						<div onClick={this.ToggleItems} className="md:hidden pr-20">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>
				</header>
			);
		}
	}
}

		return display;
	
	}

	render() {
		/*return (
			<div className="Header">
				<div className="header flex justify-between">
					<div className="flex justify-center justify-items-center items-center py-4">
						<a className="px-20" href="/">
							<img src={logo} alt="Our Mindset className=" width="80" height="600" />
						</a>
						<nav className="hidden md:flex">
							<div className="px-5">
							<Link className="link font-bold" to={{ pathname: "/" }}>Home</Link>
							</div>
							<div className="px-5">
							<Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
							</div>
							<div className="px-5">
							<Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
							</div>
						</nav>
					</div>
					<div className="hidden md:flex justify-between justify-items-center items-center py-4">
						<button className="custom-btn px-4 tracking-wider">Register</button>
						<div className="pr-20 pl-4">
						<Link to={{ pathname: "/Login" }} className="link font-bold">Login</Link>
						
						</div>

					</div>

				</div>
			</div>
		);
				return (
			<div className="Header">
				<header className="light-gray header flex justify-between">
					<div className="flex justify-center justify-items-center items-center py-4">
						<a className="px-20 py-4"href="/">
							<img src={logo} alt="Our Mindset className=" width="80" height="60" />
						</a>
					
					<nav className="hidden md:flex">
					<div>
						<Link className="link font-bold" to={{ pathname: "/" }}>Home</Link>
													</div>
							<div className="px-5">
						<Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
											</div>
							<div className="px-5">
						<Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
													</div>
					</nav>
					</div>
					<div className="hidden md:flex justify-center justify-items-center items-center">
					<button className="custom-btn tracking-wider px-5">Register</button>
					<Link to={{ pathname: "/Login" }} className="link font-bold pr-20 pl-4">Login</Link>
					</div>
						<div className="flex md:hidden flex justify-center justify-items-center items-center pr-20">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>


				</header>
			</div>
			);
				return (
			<div className="Header">
				<header className="header">
				<div className="flexbox-container">
					<div className="flex justify-center justify-items-center items-center">
						<a className="pr-10 py-4" href="/">
							<img src={logo} alt="Our Mindset className=" width="80" height="60" />
						</a>
					
					<nav className="hidden md:flex">
					<div>
						<Link className="link font-bold" to={{ pathname: "/" }}>Home</Link>
													</div>
							<div className="px-5">
						<Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
											</div>
							<div className="px-5">
						<Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
													</div>

					</nav>
					</div>
					<div className="hidden md:flex justify-center justify-items-center items-center">
					<button onClick={this.DirectToRegister}  className="custom-btn tracking-wider px-5">Register</button>
					<div className="pr-10 pl-4">
					<Link to={{ pathname: "/Login" }} className="link font-bold">Login</Link>
					</div>
					</div>
						<div className="flex md:hidden flex justify-center justify-items-center items-center pr-20">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	  							<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>

</div>
				</header>
			</div>
			);*/
			return (
			<div className="Header">
				{!this.state.loading && this.DisplayHeader()}

				
			</div>
			);
	}
}

export default Header;