import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, withRouter } from 'react-router-dom';
import { APIHost } from './GlobalVariables';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import placeholder from './images/placeholder-profile-image.jpeg';


const ServerFailure = () => (
    <div className="text-center">
    <h4><font color="red">Oops, there was an issue. Please refresh the page and check your internet connection. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h4>
    </div>
    );


class ViewConnections extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			connections: [],
			server_failure: false,
			all_users: []
		}
		this.GetConnections = this.GetConnections.bind(this);
		this.DisplayConnections = this.DisplayConnections.bind(this);
		this.RecordEvent = this.RecordEvent.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		var tmp_connections = [];
		var tmp_users = [];
		var conn_flag = false;
		var promises = [];
		var connections_promise = this.GetConnections().then(conns => {
			if(conns.data != "no connections") {
				tmp_connections = conns.data.connections;
				conn_flag = true;
			}
		}).catch((error) => {
			console.log("GetConnections failed " + error);
			this.setState({server_failure: true});
		});
		var users_promise = this.GetAllUsers().then(users => {
				tmp_users = users.data.user_details;
		}).catch((error) => {
			console.log("GetConnections failed " + error);
			this.setState({server_failure: true});
		});
		promises.push(connections_promise);
		promises.push(users_promise);
		Promise.all(promises).then(() => {
			this.setState({connections: tmp_connections, all_users: tmp_users}, () => { this.setState({loading: false})});
		}).catch((error) => {
			console.log("Promises failed " + error);
			this.setState({server_failure: true});
		});
	}

	async GetConnections() {
        const res = await axios.get(APIHost + '/api/getConnections', { withCredentials: true });
        return await res;
    }

    async GetAllUsers() {
        const res = await axios.get(APIHost + '/api/getAllUsers', { withCredentials: true });
        return await res;
    }

    RecordEvent(event, profile_id) {
        event.preventDefault();
        var profile_link = "/ViewProfile/" + profile_id;
        axios.post(APIHost + '/api/clickEvent', {
            withCredentials: true,
            type: 1,
            target: profile_id
        }).then(() => {
            this.props.history.push({ pathname: profile_link, state: { loggedIn: true } });
        }).catch((error) => {
            console.log("Failed to send the event " + error);
            this.props.history.push({ pathname: profile_link, state: { loggedIn: true } });
        });
    }

    DisplayConnections() {
    	var display_users = [];
    	var display_final = [];
    	var flag = false;
    	this.state.connections.forEach((connection) => {
    		this.state.all_users.find(user => {
    			var profile_link = "/ViewProfile/" + user.userid;
    			if (user.userid == connection.connection) {
    			display_users.push(<tr><td className="contact-spacing"><Link onClick={(e) => this.RecordEvent(e, user.userid)} to={profile_link} className="link">{user.picture_url ? <Avatar
                                      alt="Profile picture"
                                      src={user.picture_url}
                                      sx={{ width: 34, height: 34 }}
                                    /> : <Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                      sx={{ width: 34, height: 34 }}
                                    />}</Link></td><td className="contact-spacing">{user.name}</td></tr>);
                    flag = true;
                }
    		});
    	});
    	if (flag) {
    		display_final.push(<table className="contacts-table">{display_users}</table>);
    	} else {
    		display_final.push(<div className="contacts-table"><h4>You don't have any contacts. Head over to the <Link className="link" to="/Discover">Discover</Link> page to make some connections in the community!</h4></div>);
    	}
    	return display_final;
    }

    render() {
		if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else {
        	return (
        					<div className="ViewConnections page-container">
			<div className="inner-container">
			<Header history={this.props.history} loggedIn={this.state.isLogged} />
			{this.state.server_failure && <ServerFailure />}
			<h2 className="text-center pt-20">Your Connections</h2>
			<hr />
			{!this.state.loading && this.DisplayConnections()}
			</div>
						<Footer history={this.props.history} loggedIn={this.state.isLogged} />
						</div>

        		)
        }
    }
}

export default withRouter(ViewConnections);