import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import manny from './images/manny_thumbnail.png';
import elliot from './images/elliot.jpg';
import manny_coverage from './images/manny_coverage.png';
import ricardo from './images/ricardo.jpeg';
import mark from './images/mark_chavez.jpeg';
import norman from './images/norman.jpeg';
import steve from './images/steve_edward.jpeg';
import howard from './images/howard_berg.jpeg';
import tyrone from './images/tyrone_poole.jpeg';
import john from './images/john_shin.jpeg';
import { Link, withRouter } from 'react-router-dom';
import NYEventCheckout from './NYEventCheckout';
import axios from 'axios';
import { APIHost } from './GlobalVariables.js';

class NYEventInfo extends Component {

	constructor(props) {
		super(props);
        this.state = {
        	isLogged: true
        }
        this.DisplayPaymentInfo = this.DisplayPaymentInfo.bind(this);
        this.GetUserDetails = this.GetUserDetails.bind(this);
        this.CheckLogin = this.CheckLogin.bind(this);
        this.DirectToRegister = this.DirectToRegister.bind(this);

	}

	componentDidMount() {
				window.scroll(0, 0);
        var promises = [];
        this.CheckLogin().then(res => {
            if (res.data.loggedIn) {
                this.GetUserDetails().then(user_details => {
                this.setState({user_details: user_details.data.user_details, isLogged: true, loading: false});
                }).catch(error => {
                     console.log("GetUserDetails failed " + error);
                     /*server failure*/
                });
            } else {
                console.log("not res.data.loggedIn");
                this.setState({ isLogged: false, loading: false});
            }
        }).catch(error => {
            console.log("CheckLogin failed " + error);
            this.setState({ isLogged: false});
        });
	}

	async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    DirectToRegister() {
    	   this.props.history.push({ pathname: "/NYEventRegistration", state: { loggedIn: true } });
    }

	DisplayPaymentInfo() {
		var display = [];

		if (this.state.isLogged) {

		} else {

		}

		return display;
	}

	render() {

		return (
			<div className="NYEventInfo page-container">
			<div className="inner-container">
			<Header history={this.props.history} />
			<div className="flexbox-headline">
			<h1>Our Mindset's NY in-person event!</h1>
			<h2 className="sub-headline">Change Your Life!</h2>
			</div>
			        <section id="welcome" className="flexbox-container">
                            <div className="flex-item">
                                    <h3 className="text-center">Are you ready to live the life you want?</h3>
                                    
                                    <h4 className="text-center">
                                    This is an event you don't want to miss!
                                    </h4>
                                    <h6 className="text-center pt-5">Meet the founder of Our Mindset and many others from the community</h6>
                                    <div>

                                    </div>


                                </div>
                            <div className="p-10 flex-item image-min">
<iframe width="360" height="215" src="https://www.youtube.com/embed/oKZ1TuoL-q8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                                

                            </div>    

                                   
                </section>
                            <div className="features-area">
            <div className="flexbox-headline">
            <div className="text-center">
            <h3>Saturday November 12th in NY</h3>
            </div>
              <div className="text-center">
            			
                                       <h3>Get registered for the event <button onClick={this.DirectToRegister} className="custom-btn">HERE</button>
                                   </h3>
                                   </div>
                                    </div>
                                    </div>

                			<div className="single-event">
				<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Manny Fernandez</h3>
				<h6>Our Mindset Founder</h6>
<p>Manny Fernandez, the founder of Our Mindset, is a Silicon Valley angel investor, angel group founder, serial entrepreneur and keynote speaker. He has been successful leading his own ventures as well as advising other startups on their paths to success. He had previously founded SF Angels Group in San Francisco, and he has been an investor with TiE Angels since 2012. Fernandez was named in Inc. Magazine’s list of the top 33 entrepreneurs to watch in 2016 and was named 2014 SF Angel Investor of the Year. </p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={manny} alt="Manny Fernandez" width="200px" heighht="200px" />

				</div>

				</div>

				<div className="flexbox-headline">
								<img width="460" height="360" src={manny_coverage} />
</div>

				<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Elliot Barnett</h3>
				<h6>Our Mindset CEO</h6>
				<p>Elliot Barnett is an inspirational speaker, a cognitive science researcher, an entrepreneur, an author, and a 
				technology innovator. With over 20 years of technology leadership experience and over 20 years of cognitive science research 
				and development, Elliot has been uniquely positioned to positively disrupt individual and corporate mental wellness approaches. 
				His passion is to provide easily accessible, affordable, and effective solutions to empower everyone to live their Optimal Life. 
				Elliot has run successful 
				businesses in e-commerce, business and sales consulting, engineering consulting, and the point-of-sale industry.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={elliot} alt="Elliot Barnett" width="200px" heighht="200px" />

				</div>
				</div>

				<div className="text-center p-5">	
			<h3>Additional keynote speakers will be announced soon!</h3>
	</div>


	



			</div>
	
			
			            <div className="flexbox-container">
            
              <div className="text-center">
            
                                       <h3>Get registered for the event <button onClick={this.DirectToRegister} className="custom-btn">HERE</button>
                                   </h3>
                                   </div>
                                    </div>
                                    </div>

			<Footer />
			</div>
			);
	}

}

export default withRouter(NYEventInfo);