import React, { useState, useEffect } from "react";
import Header from './components/header';
import Footer from './components/footer';
import './css/stripe.css';
import axios from 'axios';
import {APIHost} from './GlobalVariables';
import { Link, Redirect } from 'react-router-dom';

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const ServerFailure = () => (
<h5><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h5>
);

const CreditUpdateFailure = () => (
<h5><font color="red">We apologize. An error occurred while making the purchase. Support has been automatically contacted and will rectify as soon as possible.<br />
If you have any questions or concerns, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h5>
);

const WrongCode = () => (
<h5><font color="red">Invalid code. Please try again</font></h5>
);

const TicketSuccess = () => (
  <h5>Success! You'll receive an email with details! See you there!</h5>
);

export default function NYEventCheckout(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [email, setEmail] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [discountCode, setDiscountCode] = useState(null);
  const [paymentID, setPaymentID] = useState(null);
  const [server_failure, setServerFailure] = useState(false);
  const [name, setName] = useState('');
  const [credit_update_failure, setCreditUpdateFailure] = useState(false);
  const [total, setTotal] = useState(parseFloat(props.price));
  const [displayMessage, setDisplayMessage] = useState(false);
  const [attendanceType, setAttendanceType] = useState('person');
  const [wrongCode, setWrongCode] = useState(false);
  const [ticketSuccess, setTicketSuccess] = useState(false);
  const [eventID, setEventID] = useState(3);
  const [charge, setCharge] = useState(props.charge);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
        axios.post(APIHost + '/api/createPaymentIntent', {
          withCredentials: true, amount: parseInt(props.charge)
        }).then((res) => {
            setClientSecret(res.data.clientSecret);
            setPaymentID(res.data.paymentID);
            setTotal(parseFloat(props.price));
        }).catch((error) => {
          console.log("Error is " + error);
          setServerFailure(true);
        });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    axios.post(APIHost + '/api/updatePaymentIntent', {
        charge: charge,
        id: paymentID,
        withCredentials: true
      }).then( async () => {
        const payload = await stripe.confirmCardPayment(clientSecret, {
        receipt_email: email,
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
           name: name
          }
        }
      });
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          setProcessing(false);
        } else {
        axios.post(APIHost + '/api/purchaseEvent', {
         event_id: eventID, 
         email: email,
         name: name,
         attendanceType: attendanceType,
          withCredentials: true
        }).then(() => {
         setError(null);
          setProcessing(false);
          setSucceeded(true);
        }).catch((error) => {
          console.log("Error is " + error);
          setCreditUpdateFailure(true);
        });
 
    }
      });
  };

  const GetTickets = async event => {
    if (discountCode == "OurMindsetNYEvent") {
    event.preventDefault();
        axios.post(APIHost + '/api/purchaseEvent', {
         event_id: eventID, 
         email: email,
         attendanceType: "general",
          withCredentials: true
        }).then(() => {
         setError(null);
          setTicketSuccess(true);
          setWrongCode(false);
        }).catch((error) => {
          console.log("Error is " + error);
          setCreditUpdateFailure(true);
        });
      } else {
        setWrongCode(true);
      }
  };

const UpdateDiscountCode = event => {
  setDiscountCode(event.target.value);
};

const ChangePrice = event => {
  if(event.target.name == "general") {
    setTotal(47.00);
    setAttendanceType("general");
    setEventID(3);
    setCharge(4700);
  } else if (event.target.name == "front") {
    setTotal(57.00);
    setAttendanceType("front");
    setCharge(5700);
    setEventID(3);
  } else if (event.target.name == "VIP") {
    setTotal(497.00);
    setAttendanceType("VIP");
    setCharge(49700);
    setEventID(3);
  } else {
    setTotal(24.95);
    setAttendanceType("zoom");
    setCharge(2495);
    setEventID(3);
  }

};
   /*<p>Have a discount code? Enter it here. <input value={discountCode} onChange={UpdateDiscountCode}/></p>
   <div className="radio-container">
<div className="flex-item">
<div className="radio-container2">
  <input type="radio" checked="checked" name="radio" />Attend in-person
  </div>
  </div>
  <div className="flex-item">
  <input type="radio" checked="checked" name="radio" />Attend on Zoom
  </div>
</div>
*/
  return (
    <div>
    <div className="flexbox-container form-input">
        <div className="input-items default"><p>Have a code? <input value={discountCode} onChange={UpdateDiscountCode} /></p></div>
        <div><button className="simple-button" onClick={GetTickets}>Get Tickets</button></div>
                        {wrongCode && <WrongCode />}
                        {ticketSuccess && <TicketSuccess />}

        </div>
                

          <div className="CheckoutForm">
    <form id="payment-form" onSubmit={handleSubmit}>
    <h4>Excited for you!</h4>
<label class="form-control">
  <input onClick={ChangePrice} type="radio" name="general" checked={attendanceType == "general"} />
  General admission
</label>

<label class="form-control">
  <input onClick={ChangePrice} type="radio" name="front" checked={attendanceType == "front"} />
  Front-row advantage
</label>
<label class="form-control">
  <input onClick={ChangePrice} type="radio" name="VIP" checked={attendanceType == "VIP"} />
  VIP access
</label>

<label class="form-control">
  <input onClick={ChangePrice} type="radio" name="zoom" checked={attendanceType == "zoom"} />
  Attend on Zoom
</label>


        <h6>Event: ${total}</h6>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />
      <input
        type="text"
        value={name}
        onChange={(n) => setName(n.target.value)}
        placeholder="Name on card"
      />
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Get Tickets"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded. You will receive an email to the address you provided. Please check your spam folder as well. The email is your ticket to the event</p>
      {server_failure && <ServerFailure />}
      {credit_update_failure && <CreditUpdateFailure />}
    </form>

      </div>
      </div>
  );
}
