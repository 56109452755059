import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import LAEventCheckout from './LAEventCheckout';
import { Link, withRouter } from 'react-router-dom';
import {APIHost} from './GlobalVariables.js';
import axios from 'axios';
import Popup from "reactjs-popup";
import { Player } from 'video-react';
import { CheckCircleTwoTone, UpSquareOutlined } from '@ant-design/icons';
import Avatar from '@mui/material/Avatar';
import "../node_modules/video-react/dist/video-react.css";
import placeholder from './images/placeholder-profile-image.jpeg';
import ShowMoreText from "react-show-more-text";
import { Storage } from 'aws-amplify';
import "../node_modules/video-react/dist/video-react.css";

const ImageLoading = () => (
    <h5><font color="blue">Please wait while your media loads</font></h5>
    )

const ServerFailure = () => (
    <div className="text-center">
    <h4><font color="red">Oops, there was an issue. Please refresh the page. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h4>
    </div>
    );

const PostFailure = () => (
    <h5><font color="red">Oops, there was an issue submitting your post. Please save it and refresh the page. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h5>
    );

const BucketPath = "https://ommobileb60e6796b93b46e694852720faf795a4142905-dev.s3.amazonaws.com/public/";


class PremiumContent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLogged: false,
			posts: [], 
			playing: false,
			threads: []
		}
    this.GetUserDetails = this.GetUserDetails.bind(this);
    this.CheckLogin = this.CheckLogin.bind(this);
    this.GetAdmins = this.GetAdmins.bind(this);
    this.GetUserDetails = this.GetUserDetails.bind(this);
    this.UpvotePost = this.UpvotePost.bind(this);
    this.UpvoteThread = this.UpvoteThread.bind(this);
    this.ShowThread = this.ShowThread.bind(this);
    this.NewComment = this.NewComment.bind(this);
    this.CheckIfHasComments = this.CheckIfHasComments.bind(this);
    this.FormatDate = this.FormatDate.bind(this);
    this.GetContributors = this.GetContributors.bind(this);
    this.ToggleAudio = this.ToggleAudio.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.AddComment = this.AddComment.bind(this);
    this.SubmitPost = this.SubmitPost.bind(this);
    this.onChange = this.onChange.bind(this);

	}

	componentDidMount() {
		window.scroll(0, 0);
		var tmp_posts = [];
		var tmp_threads = [];
		var tmp_admins = []
		var promises = [];
		var tmp_contributors = [];
		var audio_files = [];
		var cnt = 0;
		var tmp_user_details = null;
    this.CheckLogin().then(res => {
		    if (res.data.loggedIn) {
                var post_promise = this.GetPosts().then(posts => {
                    tmp_posts = posts.data.posts;
                    tmp_threads = posts.data.replies;
                    tmp_posts.forEach((post) => {
                    	if (post.content_type == 'audio') {
                    		audio_files.push({});
                    		audio_files[cnt].post_id = post.id;
                    		audio_files[cnt].audio_file = new Audio(post.url);
                    		cnt++;
                    	}
                    });
                }).catch((error) => {
                    console.log("Failed to get the posts " + error);
                });
				      var user_promise = this.GetUserDetails().then(user_details => {
				          tmp_user_details = user_details.data.user_details;
				          return user_details;
				      }).catch(error => {
				           console.log("GetUserDetails failed " + error);
				      });

				      var admin_promise = this.GetAdmins().then(admins => {
				          tmp_admins = admins.data.admins;
				          return admins;
				      }).catch((error) => {
				          console.log("Failed to get admins " + error);
				      });

				        var contributor_promise = this.GetContributors().then(contributors => {
            tmp_contributors = contributors.data.contributors;
            return contributors;
        }).catch(error => {
             console.log("GetContributors failed " + error);
            this.setState({server_failure: true, global_error: 'GetContributors failed ' + error}, () => {this.setState({loading: false})});
        });
				      promises.push(post_promise);
				      promises.push(user_promise);
				      promises.push(admin_promise);
				      promises.push(contributor_promise);
				      Promise.all(promises).then(() => {
				      	          var contributor = false;
            tmp_contributors.find((user) => {
                if (user.userid == tmp_user_details.userID) {
                    contributor = true;
                }
            });
            		if (contributor) {
				      	this.setState({audio_files: audio_files, contributor: true, isLogged: true, threads: tmp_threads, posts: tmp_posts, user_details: tmp_user_details, admins: tmp_admins}, () => {this.setState({loading: false})});
				      } else {
				      	this.setState({audio_files, audio_files, isLogged: true, threads: tmp_threads, posts: tmp_posts, user_details: tmp_user_details, admins: tmp_admins}, () => {this.setState({loading: false})});
				      }
				      }).catch((error) => {

				      });
			    } else {
			    	this.setState({isLogged: false}, () => {this.setState({loading: false})});
			    }
			                            const newIntervalId = setInterval(() => {
                                this.GetPosts().then((posts) => {
                                    if(posts.data.posts.length > this.state.posts.length || posts.data.replies.length > this.state.threads.length) {
                                        cnt = 0;
                                        audio_files = [];
                                        tmp_posts.forEach((post) => {
                                            if (post.content_type == 'audio') {
                                                audio_files.push({});
                                                audio_files[cnt].post_id = post.id;
                                                audio_files[cnt].audio_file = new Audio(post.url);
                                                cnt++;
                                            }
                                        });
                                    this.setState({audio_files: audio_files, posts: posts.data.posts, threads: posts.data.replies});
                                }
                            });
                                }, 10000);
                          this.setState(prevState => {
                            return {
                              ...prevState,
                              postInterval: newIntervalId,
                            };
                          });
			  }).catch((error) => {

			  });


	}

	componentWillUnmount(){
    clearInterval(this.state.postInterval);
  }

	FormatDate(date) {
  // formats a JS date to 'yyyy-mm-dd'
  var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate()
    var year = d.getFullYear();
    var month = 12;
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("-");
}

	    async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    async GetAdmins() {
        const res = await axios.get(APIHost + '/api/getAdmins', { withCredentials: true });
        return await res;
    }

    async GetPosts() {
        const res = await axios.get(APIHost + '/api/getPremiumPosts', { withCredentials: true });
        return await res;
    }

    async GetContributors() {
        const res = await axios.get(APIHost + '/api/getContributors', {withCredentials: true});
        return await res;
    }

    UpvotePost(event, post_id, userid) {
    event.preventDefault();
    if (userid != this.state.user_details.userID) {
        if (!this.state[`${post_id}_voted_post`]) {
            axios.post(APIHost + '/api/postVote', 
            {
                withCredentials: true,
                post_id: post_id
            }).then((res) => {
                axios.post(APIHost + '/api/clickEvent', {
                    withCredentials: true,
                    type: 2,
                    target: post_id
                }).catch((error) => {
                    console.log("Failed to send the event " + error);
                });
                var tmp_posts = this.state.posts;
                tmp_posts.find((post) => {
                    if (post.id == post_id) {
                        post.votes = res.data.votes;
                    }
                });
                this.setState({posts: tmp_posts, [post_id+"_voted_post"]: true});
            }).catch((error) => {
                console.log("Failed to update the post vote " + error);
                this.setState({server_failure: true});
            });
        }
    }
}

    handleFileChange(event, type, id) {
        console.log("Type and id " + type + " and " + id + " and " + event.target.files[0].type);
        this.setState({file_large: false});
        var filetype = event.target.files[0].type.split('/');
        if (event.target.files[0]) {
            if (type == 'post') {
                if (event.target.files[0].size > 2147483648) {
                    this.setState({file_large: true});
                } else {
                    const objectUrl = URL.createObjectURL(event.target.files[0])
                    this.setState({post_file_type: filetype[0], post_image_preview: objectUrl, post_image: event.target.files[0], image_chosen: true, post_image_name: event.target.files[0].name});
                }
            } else {
                if (event.target.files[0].size > 262144000) {
                    this.setState({["file_large"+id]: true});
                } else {
                    const objectUrl = URL.createObjectURL(event.target.files[0])
                    this.setState({["comment_file_type"+id]: filetype[0], comment_image_name: event.target.files[0].name, ["comment_image_preview"+id]: objectUrl, ["comment_image"+id]: event.target.files[0], ["image_chosen"+id]: true});
                }           
            }
        }
    }

    ToggleAudio(post_id) {
        var audio = null;
        this.state.audio_files.find((file) => {
            if (file.post_id == post_id) {
                audio = file.audio_file;
            }
        });
        if (this.state[`post_audio${post_id}`]) {
            audio.pause();
        } else {
            audio.play();
        }
        this.setState({["post_audio"+post_id]: !this.state[`post_audio${post_id}`]});
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

UpvoteThread(event, thread_id, userid) {
    event.preventDefault();
    if (userid != this.state.user_details.userID) {
        if (!this.state[`${thread_id}_voted_thread`]) {
            axios.post(APIHost + '/api/commentVote', 
            {
                withCredentials: true,
                thread_id: thread_id
            }).then((res) => {
                axios.post(APIHost + '/api/clickEvent', {
                    withCredentials: true,
                    type: 3,
                    target: thread_id
                }).catch((error) => {
                    console.log("Failed to send the event " + error);
                });
                var tmp_threads = this.state.threads;
                tmp_threads.find((thread) => {
                    if (thread.id == thread_id) {
                        thread.votes = res.data.votes;
                    }
                });
                this.setState({threads: tmp_threads, [thread_id+"_voted_thread"]: true});
            }).catch((error) => {
                console.log("Failed to update the thread vote " + error);
                this.setState({server_failure: true});
            });
        }
    }
}

SubmitPost(event) {
    event.preventDefault();
    this.setState({wait_post: true});
    if (this.state.image_chosen) {
        this.setState({image_loading: true});
Storage.put(this.state.post_image_name, this.state.post_image).then(() => {
    const imageURL = BucketPath + this.state.post_image_name;
                        axios.post(APIHost + '/api/addPost', 
                        {
                            withCredentials: true,
                            post: this.state.newpost,
                            name: this.state.user_details.name,
                            picture_url: this.state.user_details.picture_url,
                            fileType: this.state.post_file_type,
                            post_level: 'premium',
                            url: imageURL
                        }).then((res) => {
                            var tmp_posts = this.state.posts;
                            var tmp_audio_files = this.state.audio_files;
                            var tmp_post = {};
                            var tmp_audio = {};
                            if(this.state.post_file_type == 'audio') {
	                            tmp_audio.post_id = res.data.id[0].id;
	                            tmp_audio.audio = new Audio(imageURL);
	                            tmp_audio_files.unshift(tmp_audio);
	                            this.setState({audio_files: tmp_audio_files});
                        	}
                            tmp_post.id = res.data.id[0].id;
                            tmp_post.userid = this.state.user_details.userID;
                            tmp_post.post = this.state.newpost;
                            tmp_post.picture_url = this.state.user_details.picture_url;
                            tmp_post.url = imageURL;
                            tmp_post.name = this.state.user_details.name;
                            tmp_post.content_type = this.state.post_file_type;
                            tmp_post.post_level = 'premium';
                            tmp_post.date = new Date();
                            tmp_posts.unshift(tmp_post);
                            
                            this.setState({posts: tmp_posts, submitted_post: true, wait_post: false, image_loading: false, post_image_preview: null, image_chosen: false});
                            document.getElementById("newpost").value = "";
                        }).catch((error) => {
                            console.log("Sumitting a new post failed " + error);
                            this.setState({post_failure: true});
                        });

                }).catch((error) => {
                    console.log("posting the image URL failed " + error);
                    this.setState({post_failure: true});
                });
    } else {
    axios.post(APIHost + '/api/addPost', 
    {
        withCredentials: true,
        post: this.state.newpost,
        name: this.state.user_details.name,
        post_level: 'premium',
        picture_url: this.state.user_details.picture_url
    }).then((res) => {
        console.log("res.data.id " + res.data.id[0].id);
        var tmp_posts = this.state.posts;
        var tmp_post = {};
        tmp_post.id = res.data.id[0].id;
        tmp_post.userid = this.state.user_details.userID;
        tmp_post.post = this.state.newpost;
        tmp_post.picture_url = this.state.user_details.picture_url;
        tmp_post.name = this.state.user_details.name;
        tmp_post.post_level = 'premium';
        tmp_post.date = new Date();
        tmp_posts.unshift(tmp_post);
        /*tmp_posts.push({});
        tmp_posts[tmp_posts.length-1].id = res.data.id;
        tmp_posts[tmp_posts.length-1].userid = this.state.user_details.userID;
        tmp_posts[tmp_posts.length-1].post = this.state.newpost;
        tmp_posts[tmp_posts.length-1].picture_url = this.state.user_details.picture_url;
        tmp_posts[tmp_posts.length-1].date = new Date();
        tmp_posts.sort((a, b) => a.id - b.id);*/
        this.setState({posts: tmp_posts, submitted_post: true, wait_post: false});
        document.getElementById("newpost").value = "";
    }).catch((error) => {
        console.log("Sumitting a new post failed " + error);
        this.setState({post_failure: true});
    });
}




}


AddComment(event, post_id) {
    event.preventDefault();
    this.setState({wait_post: true});
    if (this.state[`image_chosen${post_id}`]) {
        this.setState({["image_loading"+post_id]: true});
Storage.put(this.state.comment_image_name, this.state[`comment_image${post_id}`]).then(() => {
    const imageURL = BucketPath + this.state.comment_image_name;
                    axios.post(APIHost + '/api/addPostComment',
                    {
                        withCredentials: true,
                        comment: this.state.newcomment,
                        userid: this.state.user_details.userID,
                        name: this.state.user_details.name,
                        picture_url: this.state.user_details.picture_url,
                        fileType: this.state.comment_file_type,
                        post_id: post_id,
                        url: imageURL
                    }).then(() => {
                        var tmp_threads = this.state.threads;
                        tmp_threads.push({});
                        tmp_threads[tmp_threads.length-1].userid = this.state.user_details.userID;
                        tmp_threads[tmp_threads.length-1].picture_url = this.state.user_details.picture_url;
                        tmp_threads[tmp_threads.length-1].name = this.state.user_details.name;
                        tmp_threads[tmp_threads.length-1].post_id = post_id;
                        tmp_threads[tmp_threads.length-1].post = this.state.newcomment;
                        tmp_threads[tmp_threads.length-1].url = imageURL;
                        tmp_threads[tmp_threads.length-1].content_type = this.state[`comment_file_type${post_id}`];
                        this.setState({[post_id+"_comment"]: false, [post_id+"_show"]: true, threads: tmp_threads, ["image_loading"+post_id]: false, wait_post: false, ["image_chosen"+post_id]: false, ["comment_image_preview"+post_id]: null});
                        document.getElementById([`${post_id}_newcomment`]).value = "";
                    }).catch((error) => {
                        console.log("Failed to submit comment " + error);
                        this.setState({post_failure: true});
                    });

                }).catch((error) => {
                    console.log("posting the image URL failed " + error);
                    this.setState({post_failure: true});
                });
    } else {
    axios.post(APIHost + '/api/addPostComment',
    {
        withCredentials: true,
        comment: this.state.newcomment,
        userid: this.state.user_details.userID,
        name: this.state.user_details.name,
        picture_url: this.state.user_details.picture_url,
        post_id: post_id,
    }).then(() => {
        var tmp_threads = this.state.threads;
        tmp_threads.push({});
        tmp_threads[tmp_threads.length-1].userid = this.state.user_details.userID;
        tmp_threads[tmp_threads.length-1].picture_url = this.state.user_details.picture_url;
        tmp_threads[tmp_threads.length-1].name = this.state.user_details.name;
        tmp_threads[tmp_threads.length-1].post_id = post_id;
        tmp_threads[tmp_threads.length-1].post = this.state.newcomment;
        this.setState({[post_id+"_comment"]: false, [post_id+"_show"]: true, threads: tmp_threads});
        document.getElementById([`${post_id}_newcomment`]).value = "";
    }).catch((error) => {
        console.log("Failed to submit comment " + error);
        this.setState({post_failure: true});
    });
}
}

    CheckIfHasComments(post) {
      var flag = false;
      this.state.threads.find((thread) => {
        if (post == thread.post_id) {
          flag = true;
        }
      });
      return flag;
    }

    ShowThread(event, post_id) {
        event.preventDefault();
        this.setState({[post_id+"_show"]: !this.state[`${post_id}_show`]});
    }

    NewComment(event, post_id) {
        event.preventDefault();
        this.setState({[post_id+"_comment"]: !this.state[`${post_id}_comment`]});
    }


	render() {

        

	    if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else {

		 var display = [];
           var display_posts = [];
        this.state.posts.forEach(post => {

        	var display_ext_link = [];
            if (post.ext_link != null) {
                display_ext_link.push(<iframe src={post.ext_link} width="300" height="250" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>);
            }
            var post_media = [];
            if (post.url != null) {
                if (post.content_type == 'image') {
                    post_media.push(<img src={post.url} alt="" />)
                } else if (post.content_type == 'video') {
                    post_media.push(<Player 
                            width={300}
                            height={250}
                            fluid={false}
                            src={post.url}
                     />);
                } else if (post.content_type == 'audio') {
                    post_media.push(<button className="simple-button" onClick={() => this.ToggleAudio(post.id)}>Play audio/Pause audio</button>);
                }
            }

            var flag = this.CheckIfHasComments(post.id);
                var profile_link_post= "/ViewProfile/" + post.userid;

            var display_threads = [];     
            this.state.threads.find(thread => {

                if (thread.post_id == post.id) {
                    var profile_link_thread = "/ViewProfile/" + thread.userid;
                    var comment_media = [];
                    if (thread.url != null) {
                        if (thread.content_type == 'image') {
                            comment_media.push(<img src={thread.url} alt="" />)
                        } else {
                            comment_media.push(<Player 
                                    width={300}
                                    height={250}
                                    fluid={false}
                                    src={thread.url}
                             />);
                        }
                    }
                    var thread_date = this.FormatDate(thread.date);
                    display_threads.push(
        <div className={this.state[`${post.id}_show`] ? "thread" : "thread hide"}>
           <div className="flexbox-container-plain">
                                       <div className="flexbox-container-column">
                            <div>
                            <p>Upvote</p>
                            </div>
                            <div>
                               <p>{thread.votes}</p>
                               
                               </div>
                               <div>
                                <div onClick={(e) => this.UpvoteThread(e, thread.id, thread.userid)}>
                                <UpSquareOutlined style={{ fontSize: '50px', color: '#08c' }} />
                                </div>
                                </div>
                                </div>
                    <div className="pr-5">
                          {thread.picture_url ?  <Link to={profile_link_thread} className="link"><Avatar
                                      alt="Profile picture"
                                      src={thread.picture_url}
                                      sx={{ width: 34, height: 34 }}
                                    /></Link>: <Link to={profile_link_thread} className="link"><Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                      sx={{ width: 34, height: 34 }}
                                    /></Link>}
                    </div>
                    <div className="pr-10">
                        <p>{thread.name}</p>
                    </div>
                    <div>
                        <p>{thread_date}</p>
                    </div>
                </div>
                <div className="flexbox-container-column">
                        <div className="flexbox-container-plain">

                                    { !thread.post ?
                                                                               <div className="flex-item-80 pl-5">
                        				<div className="pb-5">
                                          <p>{thread.post}</p>
                                          </div>
                                          {comment_media}
                                        </div>
                                        : 


                                        thread.post.length<61 ?
                                        <div className="pl-5">
                        <div className="pb-5">
                                          <p>{thread.post}</p>
                                          </div>
                                          {comment_media}
                                        </div>
                                    : 
                                        <div className="flex-item-80">
                                        <div className="pb-5">
                                            <ShowMoreText
                                            lines={3}
                                            more="Show more"
                                            less="Show less"
                                            className="content-css"
                                            anchorClass="show-more-less-clickable"
                                            onClick={this.executeOnClick}
                                            expanded={false}
                                            width={280}
                                            truncatedEndingComponent={"... "}>
                                        
                                            <p>{thread.post}</p>
                                            </ShowMoreText>
                                            </div>
                                            {comment_media}
                                        </div>}
                        </div>
                 </div>
 
            </div>
                    );
                }
            });
            var post_date = this.FormatDate(post.date);
/*                                            {post.url != null && post.content_type == 'image' ? <img src={post.url} alt="" /> :
                                            <iframe src={post.url} width="240" height="160" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>}*/

            var display_comment_preview = [];
            if(this.state[`comment_file_type${post.id}`] == 'image') {
                            console.log("file type for comment " + this.state[`comment_image_preview${post.id}`]);

                display_comment_preview.push(<img src={this.state[`comment_image_preview${post.id}`]} />);
            } else if (this.state[`comment_file_type${post.id}`] == 'video') {
                display_comment_preview.push(<Player 
                            width={300}
                            height={250}
                            fluid={false}
                            src={this.state[`comment_image_preview${post.id}`]} />)
            }

                        var display_comment_preview = [];
            if(this.state[`comment_file_type${post.id}`] == 'image') {
                            console.log("file type for comment " + this.state[`comment_image_preview${post.id}`]);

                display_comment_preview.push(<img src={this.state[`comment_image_preview${post.id}`]} />);
            } else if (this.state[`comment_file_type${post.id}`] == 'video') {
                display_comment_preview.push(<Player 
                            width={300}
                            height={250}
                            fluid={false}
                            src={this.state[`comment_image_preview${post.id}`]} />)
            }
        display_posts.push(
        <div>
        <div className="posts">
                <div className="flexbox-container-plain">
                                            <div className="flexbox-container-column align-self">
                            <div>
                            <p>Upvote</p>
                            </div>
                            <div>
                               <p>{post.votes}</p>
                               </div>
                               <div>
                                <div onClick={(e) => this.UpvotePost(e, post.id, post.userid)}>
                                <UpSquareOutlined style={{ fontSize: '50px', color: '#08c' }} />
                                </div>
                                </div>
                                </div>
                    <div className="pr-5">
                           {post.picture_url ? <Link to={profile_link_post} className="link"><Avatar
                                      alt="Profile picture"
                                      src={post.picture_url}
                                      sx={{ width: 34, height: 34 }}
                                    /> </Link>: <Link to={profile_link_post} className="link"><Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                      sx={{ width: 34, height: 34 }}
                                    /></Link>}
                    </div>
                    <div className="pr-10">
                        <p>{post.name}</p>
                    </div>
                    <div className="pr-10">
                        <p>{post_date}</p>
                    </div>
                </div>
                    <div className="flexbox-container-column">
                        <div className="flexbox-container-plain">

                                    {!post.post ? 

                                  <div className="pl-5">
                                           
                                <div className="pb-5">
                                            <p>{post.post}</p>
                                            </div>
                                            {post_media}
                                            {display_ext_link}
                                        </div>
                                        :

                                        post.post.length<61 && post.post.length>10
                                     ?
                                        <div className="pl-5">
                                           
                                		<div className="pb-5">
                                            <p>{post.post}</p>
                                            </div>
                                                {post_media}
                                                {display_ext_link}
                                            
                                        </div>
                                    : post.post.length<10 ?
                                        <div className="pl-10">
                                        <div className="pb-5">
                                        <p>{post.post}</p>
                                        </div>
                                                {post_media}
                                                {display_ext_link}
                                        </div>
                                    :
                                        <div>
                                        <div className="pb-5">
                                            <ShowMoreText
                                            lines={3}
                                            more="Show more"
                                            less="Show less"
                                            className="content-css"
                                            anchorClass="show-more-less-clickable"
                                            onClick={this.executeOnClick}
                                            expanded={false}
                                            width={280}
                                            truncatedEndingComponent={"... "}>
                                        
                                            <p>{post.post}</p>
                                            </ShowMoreText>
                                            </div>
                                                {post_media}
                                                {display_ext_link}
                                        </div>}
                        </div>
                    </div>
                            
                                 <div className="flexbox-container-end">
                                    <div className="pr-10 pl-10">
                                        <button onClick={(e) => this.NewComment(e, post.id)} className="post-link">comment</button>
                                    </div>
                                   {flag && <div className="pr-10 pl-10">
                                        <button onClick={(e) => this.ShowThread(e, post.id)} className="post-link">{!this.state[`${post.id}_show`] ? "show comments" : "hide comments"}</button>
                                    </div>}
                                </div>
            
        </div>
                <div className="comment-padding">

            <div className={this.state[`${post.id}_comment`] ? "new-post" : "new-post hide"}>
                <div>
                    <div className="form-input">
                        <div className="input-items default">
                            <textarea onChange={this.onChange} rows="5" cols="50" id={[`${post.id}_newcomment`]} name="newcomment"></textarea>
                        </div>

                                                                        {this.state[`image_chosen${post.id}`] && display_comment_preview}
              
                    </div>


                </div>       


<div className="flexbox-container-end">
                               
                                            <div className="pr-5">
                                            {this.state[`image_loading${post.id}`] && <ImageLoading />}
                                            </div>
                                            <div className="pr-5 pt-2">
                                                  <label class="custom-file-upload">
                                                  <input type="file" accept="image/*,video/*" onChange={(e) => this.handleFileChange(e, 'comment', post.id)} />
                                                        Choose Media
                                                    </label>
                                                  </div>
                                                  <div className="pr-5">
                        <button className="simple-button" onClick={(e) => this.AddComment(e, post.id)}>Comment</button>
                                                 
                                                 </div>
                                                 </div>
{this.state.post_failure && <PostFailure />}


            </div>
        </div>
        {display_threads}
        </div>
                
                );
             });

               var display_preview = [];
  if (this.state.post_file_type == 'image') {
    display_preview.push(<div className="flexbox-container-plain"><img src={this.state.post_image_preview} /></div>)
  } else if (this.state.post_file_type == 'video') {
    display_preview.push(<div className="flexbox-container-plain"><Player 
                            width={300}
                            height={250}
                            fluid={false}
                            src={this.state.post_image_preview} /></div>);
  } else if (this.state.post_file_type == 'audio') {
    display_preview.push(<h5>Audio file loaded and ready to submit</h5>);
  }
		var display_add_post = [];
             if (this.state.contributor) {
				display_add_post.push(            <div className="new-post">

            <h5>Contribute to the community!</h5>
            <div>
<div className="form-input">
                                            <div className="input-items default">
                                                <textarea onChange={this.onChange} rows="5" cols="50" id="newpost" name="newpost"></textarea>
                                            </div>
                                            {this.state.image_chosen && display_preview}
                                          
                                            </div>


                                                  </div>       
                                                   <div className="flexbox-container-end">
                               
                                            <div className="pr-5">
                                            {this.state.image_loading && <ImageLoading />}
                                            </div>
                                            <div className="pr-5 pt-2">
                                                  <label class="custom-file-upload">
                                                  <input type="file" accept="image/*,video/*,audio/*" onChange={(e) => this.handleFileChange(e, 'post')} />
                                                        Choose Media
                                                    </label>
                                                  </div>
                                                  <div className="pr-5">
                                                 <button className="simple-button" disabled={this.state.wait_post} onClick={this.SubmitPost}>Post</button>
                                                 </div>
                                                 </div>
                                                    {this.state.post_failure && <PostFailure />}

                                                 </div>);
             }

        display.push(
            <div>
   <div className="text-center">
<h2>Our Mindset Premium Content</h2> <hr />
</div>
            
								{display_add_post}
                                                 {display_posts}

        
    </div>  
    );



		return (
			<div className="PremiumContent page-container">
			<div className="inner-container">
				<Header history={this.props.history} />
				<div>
<div><div className="flexbox-container-column">
	{display}
            </div>
            </div>
				</div>


</div>
				<Footer />
			</div>
			);
		}
	}
}

export default withRouter(PremiumContent);