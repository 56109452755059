import React, { Component } from 'react';


import { BrowserRouter, Route, Redirect, useNavigate } from 'react-router-dom';


import Home from './Home';
import About from './About';
import Register from './Register';
import Events from './Events';
import Login from './Login';
import Contact from './Contact';
import LAEventInfo from './LAEventInfo';
import LAEventRegistration from './LAEventRegistration';
import NYEventInfo from './NYEventInfo';
import NYEventRegistration from './NYEventRegistration';
import Calendar from './Calendar';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import Donate from './Donate';
import ForgotPassword from './ForgotPassword';
import EditProfile from './EditProfile';
import Discover from './Discover';
import ViewProfile from './ViewProfile';
import Admin from './Admin';
import ViewConnections from './ViewConnections';
import DirectMessage from './DirectMessage';
import PremiumContent from './PremiumContent';
import Survey from './Survey';
import Start from './Start';
import axios from 'axios';
import './css/main.css';
import './css/font-awesome/css/font-awesome.min.css';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';


Amplify.configure(awsconfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  //<Route exact path='/preview' render={(props) => <Home {...props} preview={true} />} />
  render() {

return (
      <div className="App">
        <BrowserRouter>
          <div>
            <Route exact path='/' component={Home} />
            <Route exact path='/Survey' component={Survey} />
            <Route exact path='/About' component={About} />
            <Route exact path='/Register' component={Register} />
            <Route exact path='/Events' component={Events} />
            <Route exact path='/Login' component={Login} />
            <Route exact path='/Contact' component={Contact} />
            <Route exact path='/LAEventInfo' component={LAEventInfo} />
            <Route exact path='/LAEventRegistration' component={LAEventRegistration} />
            <Route exact path='/NYEventInfo' component={NYEventInfo} />
            <Route exact path='/NYEventRegistration' component={NYEventRegistration} />
            <Route exact path='/Calendar' component={Calendar} />
            <Route exact path='/TermsOfUse' component={TermsOfUse} />
            <Route exact path='/PrivacyPolicy' component={PrivacyPolicy} />
            <Route exact path='/Donate' component={Donate} />
            <Route exact path='/ForgotPassword' component={ForgotPassword} />
            <Route exact path='/EditProfile' component={EditProfile} />
            <Route exact path='/Discover' component={Discover} />
            <Route exact path='/ViewProfile/:ID' component={ViewProfile} />
            <Route exact path='/Admin/' component={Admin} />
            <Route exact path='/ViewConnections/' component={ViewConnections} />
            <Route exact path='/DirectMessage/' component={DirectMessage} />
            <Route exact path='/PremiumContent/' component={PremiumContent} />
            <Route exact path='/Start/' component={Start} />

          </div>
        </BrowserRouter>
      </div>
    );
   /* return (
      <div className="App">
        <BrowserRouter>
          <div>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/About' element={<About />}></Route>
            <Route exact path='/Register' element={<Register />}></Route>

          </div>
        </BrowserRouter>
      </div>
    );*/

  }

}

export default App;
