import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import manny from './images/manny_thumbnail.png';
import elliot from './images/elliot.jpg';
import manny_coverage from './images/manny_coverage.png';
import ricardo from './images/ricardo.jpeg';
import mark from './images/mark_chavez.jpeg';
import norman from './images/norman.jpeg';
import steve from './images/steve_edward.jpeg';
import howard from './images/howard_berg.jpeg';
import tyrone from './images/tyrone_poole.jpeg';
import john from './images/john_shin.jpeg';
import { Link, withRouter } from 'react-router-dom';
import LAEventCheckout from './LAEventCheckout';
import axios from 'axios';
import { APIHost } from './GlobalVariables.js';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
//const promise = loadStripe("pk_test_51HHMFBLb5KZXfDYgLFQAetYb7cGGuiegoT1Sjm9RE8FSyLA5QgYmlvJ2lkBlPVhQAkm3kVQHsbziLS18WztEybFy00SiEfx3Mm");
const promise = loadStripe("pk_live_51HHMFBLb5KZXfDYguK930v3O6ydugI72ThwFDVzIaKdqIVVOsmUDEHJCBm9E6eJAeLGSjvTNmdXNYLXfD9Q9G8R0005gh9V8zN");

const LoginError = () => (
  <p><font color="red">Your email or password were not correct.</font></p>
);
const CreateAccountError = () => (
  <p><font color="red">An account with that email exists. If you need further assistance, please use the <Link to="/contact">contact page</Link> to let us know</font></p>
);

const CreateAccountFailed = () => (
  <p><font color="red">We aplogize. An error occurred while creating your account. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const PasswordMatch = () => (
  <p><font color="red">Your passwords didn't match. Please try again.</font></p>
);

const PhoneWrong = () => (
  <p><font color="red">Please enter a valid number and don't include -'s.</font></p>
);

const NameEmpty = () => (
  <p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
  <p><font color="red">Please make sure to provide a valid email account.</font></p>
);

const TermsError = () => (
  <p><font color="red">Please acknowledge the Terms of Use.</font></p>
);

const PasswordEmpty = () => (
  <p><font color="red">Please make sure to provide a strong password for your account.</font></p>
);

const StrongPassword = () => (
  <p><font color="red">Your password must be at least 6 characters, contain a mix of upper and lower case characters, and contain
at least one number</font></p>
);

const ServerFailure = () => (
  <h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class LAEventRegistration extends Component {

  constructor(props) {
    super(props);
        this.state = {
              email: '',
      password: '',
      confirmPassword: '',
      name: '',
      createFailed: false,
      become_ambassador: false,
      accountCreated: true,
      passwordMatch: true,
      nameEmpty: false,
      emailEmpty: false,
      passwordEmpty: false,
      logged_in: true,
      strongPassword: true,
      server_failure: false,
      terms_of_use: false,
      phoneWrong: false,
      success: false,
      spd: false,
      loading: true
        }
        //this.DisplayPaymentInfo = this.DisplayPaymentInfo.bind(this);
        this.GetUserDetails = this.GetUserDetails.bind(this);
        this.DirectToLogin = this.DirectToLogin.bind(this);
            this.CreateAccount = this.CreateAccount.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.CheckLogin = this.CheckLogin.bind(this);
    this.TermsOfUse = this.TermsOfUse.bind(this);
    this.DisplaySuccess = this.DisplaySuccess.bind(this);
    this.CreateAccountCallback = this.CreateAccountCallback.bind(this);

  }

  componentDidMount() {
        window.scroll(0, 0);
        var promises = [];
        this.CheckLogin().then(res => {
            if (res.data.loggedIn) {
                this.GetUserDetails().then(user_details => {
                this.setState({user_details: user_details.data.user_details, isLogged: true, loading: false});
                }).catch(error => {
                     console.log("GetUserDetails failed " + error);
                     /*server failure*/
                });
            } else {
                console.log("not res.data.loggedIn");
                this.setState({ isLogged: false, loading: false});
            }
        }).catch(error => {
            console.log("CheckLogin failed " + error);
            this.setState({ isLogged: false});
        });
  }

  async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  CreateAccountCallback() {
    this.setState({success: true, isLogged: true});
  }

  CreateAccount() {
    var passed = true;
    //let re = /[A-Z]\W/g;
    //var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{6,}/;
    var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    var phoneRegExp = /^[0-9]*$/;
    var emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regExp.test(this.state.password)) {
      passed = false;
      this.setState({ strongPassword: false });
    }
    if (!phoneRegExp.test(this.state.phonenumber) || this.state.phonenumber.length < 10) {
      passed = false;
      this.setState({phoneWrong: true});
    }
    if (this.state.password !== this.state.confirmPassword) {
      passed = false;
      this.setState({ passwordMatch: false });
    }
    if (this.state.name === '') {
      passed = false;
      this.setState({ nameEmpty: true });
    }
    if (this.state.email === '' || !emailRegExp.test(this.state.email)) {     passed = false;
      this.setState({ emailEmpty: true });
    }
    if (this.state.password === '') {
      passed = false;
      this.setState({ passwordEmpty: true });
    }
    if (passed) {
      var temp_email = this.state.email.toLowerCase();
      this.setState({email: temp_email});
      axios.post(APIHost + "/api/register", {
        email: temp_email,
        password: this.state.password,
        name: this.state.name,
        register: true,
        phonenumber: this.state.phonenumber,
        withCredentials: true
      }).then(res => {
        //this.props.history.push({ pathname: '/LAEventCheckout', state: { isLogged: true } });
        this.CreateAccountCallback();
      }).catch(error => {
        console.log("error is " + error);
        if (error.response.data.errorMessage === "Account exists with that email") {
          this.setState({ accountCreated: false });
        } else {
          console.log('Account creation failed');
          this.setState({ createFailed: true });
        }
      });
    }
  }

  onSubmit(event) {
    event.preventDefault();
    if (!this.state.terms_of_use) {
      this.setState({termsError: true});
    } else {
    this.setState({
      nameEmpty: false,
      passwordMatch: true,
      emailEmpty: false,
      passwordEmpty: false,
      strongPassword: true,
      termsError: false,
      phoneWrong: false
    });
    this.CreateAccount();
    }
  }

  TermsOfUse(event) {
    this.setState({ terms_of_use: !this.state.terms_of_use, termsError: false });
  }

  DisplaySuccess() {
    var display = [];
    display.push(<h5>Thank you so much for registering. Here's to your Optimal Life my friend!</h5>);
    return display;
  }



       DirectToLogin() {
              //navigate('/Register');
              console.log("Setting from_registration");
        this.props.history.push({ pathname: "/Login", state: { from_registration: true } });

    }

    render() {
                      let props = {
            webinar_id: "3",
            charge: "19900",
            price: "199.00"
        }

        if (this.state.isLogged) {
      return(
                <div className="LAEventRegistration">

        <Header />
        <div className="payment-box">
        <div className="flexbox-container">
<div className="flex-item">
                                      <Elements stripe={promise}>
                            <LAEventCheckout {...props} />
                          </Elements>


            </div>
            </div>
            </div>
        <Footer />
                    </div>

        );
      } else {
        return (
<div className="LAEventRegistration">

        <Header history={this.props.history} />
        <div className="register">
            <div className="flexbox-container">


                <div className="flex-item">
                  <h2>Join the Community!</h2>
                  <h5>Already a member? <a href="" className="link" onClick={this.DirectToLogin}>login here</a></h5>
                  <form>
                  <div className="form-input mt-25">
                    <label>Name</label>
                    <div className="input-items default">
                      <input type="name" id="name" name="name" onChange={this.onChange} placeholder="Name" />
                      <i className="lni lni-user"></i>
                    </div>
                  </div>
                  <div className="form-input mt-25">
                    <label>Email</label>
                    <div className="input-items default">
                      <input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
                      <i className="lni lni-envelope"></i>
                    </div>
                  </div>
                  <div className="form-input mt-25">
                    <label>Phone number - only used for opt-in reminders of events and never shared</label>
                    <div className="input-items default">
                      <input type="phonenumber" id="phonenumber" name="phonenumber" onChange={this.onChange} placeholder="Phone number" />
                      <i className="lni lni-phone"></i>
                    </div>
                  </div>
                  <div className="form-input mt-25">
                    <label>Password</label>
                    <div className="input-items default">
                      <input type="password" id="password" name="password" onChange={this.onChange} placeholder="Password" />
                      <i className="lni lni-key"></i>
                    </div>
                  </div>
                  <div className="form-input mt-25">
                    <label>Confirm Password</label>
                    <div className="input-items default">
                      <input type="password" id="confirmpassword" name="confirmPassword" onChange={this.onChange} placeholder="confirm password" />
                      <i className="lni lni-key"></i>
                    </div>
                  </div>
                    <p className="pull-right my-4"><input onChange={this.TermsOfUse} type="checkbox" /> Please acknowledge you have
                  read the My Core Insights <Link to="/TermsOfUse">Terms and Conditions of Use</Link> and <Link to="/PrivacyPolicy">Privacy Policy</Link>.
                  By clicking Register, you agree and are bound by these terms. </p>
                  <div className="form-input light-rounded-buttons mt-30">
                    <button type="submit" onClick={this.onSubmit} disabled={this.state.success} className="custom-btn" >Register</button>
                  </div>
                      {!this.state.accountCreated && <CreateAccountError />}
                      {this.state.createFailed && <CreateAccountFailed />}
                      {!this.state.passwordMatch && <PasswordMatch />}
                      {this.state.nameEmpty && <NameEmpty />}
                      {this.state.emailEmpty && <EmailEmpty />}
                      {this.state.passwordEmpty && <PasswordEmpty />}
                      {!this.state.strongPassword && <StrongPassword />}
                      {this.state.phoneWrong && <PhoneWrong />}
                      {this.state.termsError && <TermsError />}
                      {this.state.success && this.DisplaySuccess()}
                  </form>
                  </div>
              </div>
              </div>
        <Footer />
        </div>
        );
      }
    }

}

export default withRouter(LAEventRegistration);