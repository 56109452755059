import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import placeholder from './images/placeholder-profile-image.jpeg';
import { Redirect, Link } from 'react-router-dom';
import {APIHost} from './GlobalVariables.js';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import manny from './images/manny_thumbnail.png';


const ChooseFileError = () => (
<p><font color="red">You must choose a file first</font></p>
);

const LoadingImage = () => (
<p><font color="blue">Please wait while your image loads</font></p>
	);

const UpdatedBio = () => (
<p><font color="blue">Your profile bio has been updated</font></p>
	);

const PhoneWrong = () => (
	<p><font color="red">Please enter a valid number and don't include -'s.</font></p>
);

const EmailWrong = () => (
	<p><font color="red">Please make sure to provide a valid email account.</font></p>
);

const EmailUsed = () => (
	<p><font color="red">An account with that email exists. If you need further assistance, please use the <Link to="/contact">contact page</Link> to let us know</font></p>
);

const WrongPassword = () => (
	<p><font color="red">The password you entered didn't match our records. Please try again.</font></p>
);

const PasswordPrompt = () => (
	<p><font color="blue">Please enter a new password.</font></p>
);

const StrongPassword = () => (
	<p><font color="red">Your password must be at least 6 characters, contain a mix of upper and lower case characters, and contain
at least one number</font></p>
);

const PasswordSuccess = () => (
	<p><font color="blue">You successfully reset your password.</font></p>
);

const NotifyToSubmit = () => (
	<p><font color="blue">Please press the Update Pic button to make this change effective.</font></p>
);

const ServerFailure = () => (
    <div className="text-center">
    <h4><font color="red">Oops, there was an issue. Please refresh the page and check your internet connection. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h4>
    </div>
    );

class EditProfile extends Component {
	constructor(props) {
	super(props);
	this.state = {
		isLogged: true,
		loading: true,
		bio: "",
		bio_submitted: true,
		profile_image: null,
		profile_url: null,
		choose_file_error: false,
		updated_phonenumber: false,
		phone_wrong: false,
		email_wrong: false,
		updated_email: false,
		email_used: false,
		image_loading: false,
		wrong_password: false,
		server_failure: false,
		checked_password: false,
		strongPassword: true,
		bio_updated: false
			}
	this.GetUserDetails = this.GetUserDetails.bind(this);
	this.CheckLogin = this.CheckLogin.bind(this);
	this.onBioChange = this.onBioChange.bind(this);
	this.SubmitBio = this.SubmitBio.bind(this);
	this.handleFileSubmit = this.handleFileSubmit.bind(this);
	this.handleFileChange = this.handleFileChange.bind(this);
	this.UpdatePhonenumber = this.UpdatePhonenumber.bind(this);
	this.UpdateEmail = this.UpdateEmail.bind(this);
	this.onChange = this.onChange.bind(this);
	this.CheckPassword = this.CheckPassword.bind(this);
	this.UpdatePassword = this.UpdatePassword.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.CheckLogin().then((res) => {
		if (res.data.loggedIn) {
	     this.GetUserDetails().then(user_details => {
	        this.setState({user_details: user_details.data.user_details, bio: user_details.data.user_details.bio, loading: false, isLogged: true});
	    }).catch(error => {
	         console.log("GetUserDetails failed " + error);
	         /*server failure*/
	    });
		} else {
			this.setState({isLogged: false, loading: false});
		}
	}).catch((error) => {
		console.log("Failed to check the login status " + error);
	});
	}

	async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    onBioChange(event) {
  	event.persist();
	this.setState({ [event.target.name]: event.target.value, bio_submitted: false, bio_updated: false});
	}

onChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    UpdateEmail(event) {
        event.preventDefault();
		var emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (this.state.email === '' || !emailRegExp.test(this.state.email)) {			
			this.setState({ email_wrong: true });
		} else {
	        axios.post(APIHost + '/api/updateEmail',
	        {
	            withCredentials: true,
	            email: this.state.email
	        }).then(() => {
	        	var tmp_user_details = this.state.user_details;
	        	tmp_user_details.email = this.state.email;
	            this.setState({updated_email: true, user_details: tmp_user_details});
	           	document.getElementById("email").value = "";

	        }).catch((error) => {
	        	if (error.response.data.errorMessage === "Account exists with that email") {
	        		this.setState({email_used: true});
	        	} else {
	            	console.log("UpdateEmail failed " + error);
	            	this.setState({server_failure: true});
	        	}
	        });
	    }
   }

    UpdatePhonenumber(event) {
        event.preventDefault();
        var phoneRegExp = /^[+][0-9]*$/;
		if (!phoneRegExp.test(this.state.phonenumber) || this.state.phonenumber.length < 10) {
			this.setState({phone_wrong: true});
		} else {
	        axios.post(APIHost + '/api/updatePhonenumber',
	        {
	            withCredentials: true,
	            phonenumber: this.state.phonenumber
	        }).then(() => {
	        	var tmp_user_details = this.state.user_details;
	        	tmp_user_details.phonenumber = this.state.phonenumber;
	            this.setState({updated_phonenumber: true, user_details: tmp_user_details});
	        }).catch((error) => {
	            console.log("updatePhonenumber failed " + error);
	            this.setState({server_failure: true});
	        });
	    }
    }

	SubmitBio(event) {
		event.preventDefault();
		this.setState({bio_submitted: true});
		axios.post(APIHost + '/api/updateBio', {
			withCredentials: true,
			bio: this.state.bio
		}).then(() => {
			this.setState({bio_updated: true});
		}).catch((error) => {
			console.log("Updating the bio failed " + error);
			this.setState({server_failure: true});
		});
	}

	UpdatePassword(event) {
		event.preventDefault();
		console.log("In UpdatePassword " + this.state.new_password);
		var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
		if (!regExp.test(this.state.new_password)) {
			this.setState({ strongPassword: false });
		} else {
			axios.post(APIHost + '/api/updatePassword', {
				withCredentials: true,
				password: this.state.new_password
			}).then(() => {
				this.setState({password_success: true, wrong_password: false, strongPassword: true});
			}).catch((error) => {
				console.log("Failed to update password " + error);
				this.setState({server_failure: true});
			});
		}
	}

	CheckPassword(event) {
		event.preventDefault();
		axios.post(APIHost + '/api/checkPassword', {
			withCredentials: true,
			current_password: this.state.current_password
		}).then(() => {
			this.setState({checked_password: true});
		}).catch((error) => {
			if(error.response.status == 403) {
				this.setState({wrong_password: true});
			} else {
				this.setState({server_failure: true});
			}
		});
	}

	    handleFileChange(event) {
        if (event.target.files[0]) {
            this.setState({profile_image: event.target.files[0], choose_file_error: false, notify_to_submit: true});
        }
    }

    handleFileSubmit() {
        /*const imageRef = ref(storage, "profileImage");
        uploadBytes(imageRef, this.state.profile_image).then(() => {
            getDownloadURL(imageRef).then((url) => {
                console.log("profile url " + url);
                this.setState({profile_url: url});
            }).catch((error) => {
                console.log("getDownloadURL failed " + error);
            });
        }).catch((error) => {
            console.log("uploadBytes failed " + error);
        });*/
        if (this.state.profile_image == null) {
        	this.setState({choose_file_error: true});
        } else {
        	this.setState({image_loading: true, notify_to_submit: false});
	        axios.get(APIHost + '/api/getSecureURL',
	        {
	            withCredentials: true
	        }).then((url) => {
	            axios.put(url.data.URL, this.state.profile_image, {withCredentials: false} ).then(() => {
	                const imageURL = url.data.URL.split('?')[0];
	                axios.post(APIHost + '/api/storeProfileURL',
	                {
	                    withCredentials: true,
	                    url: imageURL
	                }).then(() => {
	                	var new_user_details = {};
	                		new_user_details.name = this.state.user_details.name;
							new_user_details.phonenumber = this.state.user_details.phonenumber;
							new_user_details.num_of_logins = this.state.user_details.num_of_logins;
							new_user_details.email = this.state.user_details.email;
							new_user_details.last_activity = this.state.user_details.last_activity;
							new_user_details.bio = this.state.user_details.bio;
							new_user_details.picture_url = imageURL;
	                    this.setState({user_details: new_user_details, image_loading: false});
	                }).catch((error) => {   
	                    console.log("Storing the profile picture URL failed " + error);
	                });
	            }).catch((error) => {  
	                console.log("Putting the image failed " + error);
	            });
	        }).catch((error) => {
	            console.log("Getting the secure URL failed " + error);
	        });
    	}
    }

	render() {
		
		if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else if (!this.state.isLogged) {
 			return ( <Redirect to='/' />);

        } else {
        	var display_button = [];
        	var display_picture = [];
        	if(this.state.bio_submitted) {
				display_button.push(<button type="Update Bio" disabled={this.state.bio_submitted} onClick={this.SubmitBio} >Submit</button>);

        	} else {
        	display_button.push(<button type="Update Bio" onClick={this.SubmitBio} className="custom-btn">Submit</button>);

        	}

        	if (this.state.user_details.picture_url) {
        		display_picture.push(<Avatar
  					alt="Profile picture"
  					src={this.state.user_details.picture_url}
  					sx={{ width: 114, height: 114 }}
					/>);
        	} else {
        		display_picture.push(<Avatar
				  alt="Profile picture"
				  src={placeholder}
				  sx={{ width: 114, height: 114 }}
				/>);
        	}
        return (<div className="EditProfile page-container">
        			<div className="inner-container">

            <Header history={this.props.history} loggedIn={this.state.isLogged} />
            {this.state.server_failure && <ServerFailure />}
            <div className="flexbox-container-column">
            	<div className="pt-10">
            	{display_picture}
            		</div>
            		<div>
                  <label class="custom-file-upload">
                  <input type="file" accept="image/*" onChange={this.handleFileChange} />
                        Choose Image
                    </label>
                    <button onClick={this.handleFileSubmit} className="simple-button">Update Pic</button>
                    {this.state.notify_to_submit && <NotifyToSubmit />}
                {this.state.choose_file_error && <ChooseFileError />}
                {this.state.image_loading && <LoadingImage />}
            	</div>
            <div>
            <h3>Your bio - tell the community a bit about yourself!</h3>
			<form>
										<div className="form-input mt-25">
											<div className="input-items default">
												<textarea onChange={this.onBioChange} rows="10" cols="80" name="bio" value={this.state.bio}></textarea>
											</div>
										</div>
										{display_button}
										{this.state.bio_updated && <UpdatedBio />}
				</form> 
				<h3>Your settings and personal information</h3><hr /> 
				<div className="settings">
				<p>Your email: {this.state.user_details.email}</p>  
				<div className="form-input mt-25">

                <div className="input-items default">
                <input type="email" onChange={this.onChange} id="email" name="email" placeholder="Updated email" />
                <div className="button">
                                <button className="simple-button" onClick={this.UpdateEmail}>Update my email</button>
                                </div>
                                {this.state.email_wrong && <EmailWrong />}
                                {this.state.email_used && <EmailUsed />}
                </div>
                </div>
                </div>

                				<div className="settings">

                <p>Your phonenumber: {this.state.user_details.phonenumber}</p>
									<div className="form-input mt-25">
										<div className="input-items default">									
									<PhoneInput
									  international
									  defaultCountry="US"
									  value={this.state.phonenumber}
									  onChange={(value) => this.setState({phonenumber: value})}/>
									  
                         <div className="button">

                                <button className="simple-button" onClick={this.UpdatePhonenumber}>Update my phonenumber</button>
                                </div>
                                {this.state.phone_wrong && <PhoneWrong />}
                </div>
                </div>
                </div>
                <div className="settings">
                <p>Change password:</p>
                             				<div className="form-input mt-25">

                <div className="input-items default">
                <input type="password" onChange={this.onChange} id="current_password" name="current_password" placeholder="Re-enter current password" />
                         <div className="button">

                                <button className="simple-button" disabled={this.state.checked_password} onClick={this.CheckPassword}>Submit</button>
                                </div>
                                {this.state.wrong_password && <WrongPassword />}
                </div>
                </div>
                <div className="settings">
                {this.state.checked_password && <PasswordPrompt />}
                             				<div className="form-input mt-25">

                <div className="input-items default">
                <input type="password" onChange={this.onChange} disabled={!this.state.checked_password || this.state.password_success} id="new_password" name="new_password" placeholder="New password" />
                         <div className="button">

                                <button className="simple-button" disabled={!this.state.checked_password || this.state.password_success} onClick={this.UpdatePassword}>Submit</button>
                                </div>
                                {!this.state.strongPassword && <StrongPassword />}
                                {this.state.password_success && <PasswordSuccess />}
                </div>
                </div>
                </div>
                </div>
			</div>       
            </div>
            </div>
            <Footer history={this.props.history} />
        </div>
			);
		}
	}

}

export default EditProfile;