import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './images/OMTransparentLogo.png';
import social from './images/diverse-social.jpg';
import manny from './images/manny_thumbnail.png';
import Header from './components/header';
import Footer from './components/footer';
import axios from 'axios';
import { APIHost } from './GlobalVariables';
import { CheckCircleTwoTone, UpSquareOutlined } from '@ant-design/icons';
import conflict from './images/TheScienceofConflict.png';

const NameEmpty = () => (
	<p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
	<p><font color="red">Please make sure to provide a valid email account.</font></p>
);

class Start extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogged: false,
		}
		this.Preregister = this.Preregister.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	Preregister(event) {
        event.preventDefault();
        var passed = true;
        var emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.name === '') {
			passed = false;
			this.setState({ nameEmpty: true });
		}
		if (this.state.email === '' || !emailRegExp.test(this.state.email)) {			
			passed = false;
			this.setState({ emailEmpty: true });
		}
		if (passed) {
	        axios.post(APIHost + '/api/preRegister',
	            { 
	                withCredentials: true,
	                name: this.state.name,
	                email: this.state.email
	            }).then(() => {
	              this.props.history.push({ pathname: "/Register", state: { name: this.state.name, email: this.state.email } });

	            }).catch((error) => {
	                console.log("Failed to pregister the user");
	                this.setState({server_failure: true});
	            });
        }
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	render() {
		var display_header = [];
		display_header.push(<header className="header-flex2 header">
					<div className="flexbox-container">
						<div className="logo">
							<Link className="" to={{ pathname: "/" }} >
								<img src={logo} alt="Our Mindset" width="100" height="80" />
							</Link>
						</div>
						<div className="main-nav">

						</div>

						</div>

				</header>);
		return(<div>{display_header}
			                               <div className="Home page-container">
                               <div className="inner-container">
			<div>
        <section id="welcome" className="flexbox-container">
                            <div className="flex-item">
                                    <h2 className="text-center">Welcome to Our Mindset</h2>
                                    <h5 className="text-center">A social network with a purpose!</h5>

                                    <div>
                                    <div className="flexbox-container-column">
                                    <div><h5>What are you waiting for!?</h5>
							<div className="form-input mt-25">
											<label>Name</label>
											<div className="input-items default">
												<input type="name" id="name" name="name" onChange={this.onChange} placeholder="Name" />
												<i className="lni lni-envelope"></i>
											</div>
										</div>
										<div className="form-input mt-25">
											<label>Email</label>
											<div className="input-items default">
												<input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
												<i className="lni lni-key"></i>
											</div>
										</div>                                    </div>
										<p>You'll receive an email from team@ourmindset.io - check spam!</p>
                                    <div><button onClick={this.Preregister} className="custom-btn">Count Me In!</button></div>
                                    </div>
             						{this.state.nameEmpty && <NameEmpty />}
             						{this.state.emailEmpty && <EmailEmpty />}
                                    </div>


                                </div>
                            <div className="p-10 flex-item image-min">
                                                        <img src={conflict} alt="The Science of Conflict" className="img-fluid" />

                            </div>    

                                   
                </section>
            
            </div>
                        </div>
                        <div className="Footer">
                <footer className="footer">
                <div className="flexbox-container">
                    <div className="py-10">
                        <img src={logo} alt="Our Mindset logo" width="200" height="160" />
                        <p className="pt-5 copy">
                            © 2023 Our Mindset Network Inc. <br />All Rights Reserved
                        </p>
                    </div>
                    <div className="flex">
                        <div className="px-3">
                            <Link className="link" to="/PrivacyPolicy">Privacy Policy</Link>
                        </div>
                        <div className="px-3">
                            <Link className="link" to="/TermsOfUse">Terms of Use</Link>
                        </div>
                    </div>

                    </div>
                </footer>
            </div>
            </div>
            </div>
			);
			/*<h4>The next Our Mindset event you don't want to miss!</h4>
                            <h5>The Science of Conflict</h5>
                            <h5>Date: April 8th, 2023</h5>
                            <h5>Time: 11AM PST</h5>
                            <h5>Speaker: Elliot Barnett</h5>
                            <p>Come learn the science behind what drives conflict and how to leverage that science to have successful conflict. This powerful talk by Elliot will enable you to successfully navigate the inevitable conflict you face in your life and help you improve your relationships and therefore your life.</p>
*/
	}
}

export default Start;