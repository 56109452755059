import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import idea from './images/idea.jpg';
import { APIHost } from './GlobalVariables';

axios.defaults.withCredentials = true;

const NameEmpty = () => (
	<p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
	<p><font color="red">Please make sure to provide a valid email address.</font></p>
);

const MessageEmpty = () => (
	<p><font color="red">Please make sure to provide some message content.</font></p>
);

const MessageFailed = () => (
	<p><font color="red">Your message failed to send. Please try again.</font></p>
);

const MessageSent = () => (
	<p><font color="blue">Thanks so much for your message! We will get back to you soon.</font></p>
);

class Contact extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			message: '',
			name: '',
			nameEmpty: false,
			emailEmpty: false,
			messageEmpty: false,
			messageSent: false,
			messageFailed: false
		}
		this.SendMessage = this.SendMessage.bind(this);
		this.sendMessageCallBack = this.sendMessageCallBack.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	sendMessageCallBack(response, errorMessage) {
		if (response) {
			this.setState({
				messageFailed: false,
				nameEmpty: false,
				emailEmpty: false,
				messageEmpty: false,
				messageSent: true
			});
		} else {
			if (errorMessage === "name empty") {
				this.setState({ nameEmpty: true });
			} else if (errorMessage === "email empty") {
				this.setState({ emailEmpty: true });
			} else if (errorMessage === "message empty") {
				this.setState({ messageEmpty: true });
			} else {
				this.setState({ messageFailed: true });
			}
		}

	}

	SendMessage(sendMessageCallBack) {
		var passed = true;
		if (this.state.name === '') {
			passed = false;
			this.sendMessageCallBack(false, "name empty");
		}
		if (this.state.email === '') {
			passed = false;
			this.sendMessageCallBack(false, "email empty");
		}
		if (this.state.message === '') {
			passed = false;
			this.sendMessageCallBack(false, "message empty");
		}
		if (passed) {
			axios.post(APIHost + '/api/sendMessage', {
				email: this.state.email,
				message: this.state.message,
				name: this.state.name,
				withCredentials: true
			}).then(res => {
				this.sendMessageCallBack(true);
			}).catch(error => {
				console.log("error is " + error.response.data.errorMessage);
				this.sendMessageCallBack(false, error.response.data.errorMessage);
			});
		}
	}

	onSubmit(event) {
		event.preventDefault();
		this.setState({
			messageFailed: false,
			nameEmpty: false,
			emailEmpty: false,
			messageEmpty: false,
			messageFailed: false
		});
		this.SendMessage(this.sendMessageCallBack);
	}


	render() {
		return (
			<div className="Contact">
				<Header history={this.props.history} />
				<section className="flexbox-container">
				<div className="container pt-65">


							<h2 className="text-center pb-10 pt-15"><i className="lni lni-envelope"></i> Get in <span>Touch</span></h2>
							<form onSubmit={this.onSubmit}>

									
										<div className="form-input mt-25">
											<label>Name</label>
											<div className="input-items default">
												<input id="name" name="name" type="text" onChange={this.onChange} placeholder="Name" />
												<i className="lni lni-user"></i>
											</div>
										</div>
									
									
										<div className="form-input mt-25">
											<label>Email</label>
											<div className="input-items default">
												<input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
												<i className="lni lni-envelope"></i>
											</div>
										</div>
									
										<div className="form-input mt-25">
											<label>Message</label>
											<div className="input-items default">
												<textarea name="message" id="message" onChange={this.onChange} placeholder="Message"></textarea>
												<i className="lni lni-pencil-alt"></i>
											</div>
									</div>
									<p className="form-message"></p>
									<div className="col-md-12">
										<div className="form-input light-rounded-buttons mt-30">
											<button className="custom-btn" disable={this.state.messageSent ? true : false}>Send Message</button>
										</div>
								</div>

							</form>
							{this.state.nameEmpty && <NameEmpty />}
							{this.state.emailEmpty && <EmailEmpty />}
							{this.state.messageFailed && <MessageFailed />}
							{this.state.messageEmpty && <MessageEmpty />}
							{this.state.messageSent && <MessageSent />}

				</div>
				</section>
				<Footer history={this.props.history} />
			</div>
		);

	}
}

export default Contact;