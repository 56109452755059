import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, withRouter } from 'react-router-dom';
import { APIHost } from './GlobalVariables';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import placeholder from './images/placeholder-profile-image.jpeg';


const ServerFailure = () => (
    <div className="text-center">
    <h4><font color="red">Oops, there was an issue. Please refresh the page and check your internet connection. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h4>
    </div>
    );


class ViewProfile extends Component {

	constructor(props) {
		super(props);
        this.state = {
        	laoding: true,
        	is_pending: false,
        	is_connected: false,
        	requested: false,
        	connection_requests: [],
        	user_profile: [],
            user_details: [],
        	connection_responses: [],
        	user: this.props.match.params.ID
        };

        this.GetConnectionRequests = this.GetConnectionRequests.bind(this);
        this.GetUserProfile = this.GetUserProfile.bind(this);
        this.Connect = this.Connect.bind(this);
        this.GetUserDetails = this.GetUserDetails.bind(this);
        this.GetMyRecipients = this.GetMyRecipients.bind(this);
        this.GetConnections = this.GetConnections.bind(this);
        this.DirectToMessaging = this.DirectToMessaging.bind(this);
    }

    componentDidMount() {
		window.scroll(0, 0);
		var promises = [];
		var tmp_connection_responses = [];
		var tmp_user_details = [];
        var tmp_connection_requests = [];
        var tmp_profile = [];
        var tmp_personal_connections = [];
      var connections_promise = this.GetConnectionRequests().then(requests => {
           if (requests.data != "no requests") {
                tmp_connection_requests = requests.data.connection_requests;
        	}
            return requests;
        }).catch((error) => {
            console.log("GetConnectionRequests failed " + error);
            this.setState({server_failure: true});
        });
      	var profile_promise = this.GetUserProfile().then((profile) => {
				tmp_profile = profile.data.user_details;
                return profile;
			}).catch(error => {
				console.log("Failed to get the user profile " + error);
				this.setState({server_failure: true});
			});

		var response_promise = this.GetMyRecipients().then(con_responses => {
			if (con_responses.data != "no requests") {
				tmp_connection_responses = con_responses.data.recipient_requests;  
			} 
            return con_responses;
		}).catch((error) => {
			console.log("GetMyRecipients failed " + error);
			this.setState({server_failure: true});
		});

       var user_promise = this.GetUserDetails().then(user_details => {
            tmp_user_details = user_details.data.user_details;
            return user_details;
        }).catch(error => {
             console.log("GetUserDetails failed " + error);
            this.setState({server_failure: true});
        });	
        var connections_promise2 = this.GetConnections().then(connections => {
            if (connections.data != "no connections") {
                tmp_personal_connections = connections.data.connections;                  
            }
            return connections;
        }).catch(error => {
             console.log("GetUserDetails failed " + error);
             this.setState({server_failure: true});
        });	
        promises.push(connections_promise);
        promises.push(user_promise);
        promises.push(profile_promise);
        promises.push(connections_promise2);
        promises.push(response_promise);
        Promise.all(promises).then(() => {
            var connected_flag = false;
            var pending_flag = false;
            var requested_flag = false;
            tmp_connection_requests.find((conn) => {
                if(conn.recipient == this.props.match.params.ID) {
                    if (conn.status == "Pending") {
                        pending_flag = true;
                    } 

                }

            });
            tmp_personal_connections.find((conn) => {
                if (conn.connection == this.props.match.params.ID) {
                    connected_flag = true;
                }
            });
                tmp_connection_responses.forEach((request) => {
                    if (request.status == "Pending" && request.recipient == tmp_user_details.userID && request.userid == this.props.match.params.ID) {
                        requested_flag = true;
                    }
                });
                if (requested_flag) {
                    this.setState({user_details: tmp_user_details, connection_requests: tmp_connection_requests, user_profile: tmp_profile, requested: true, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});
                } else if (pending_flag) {
                    this.setState({user_details: tmp_user_details, connection_requests: tmp_connection_requests, user_profile: tmp_profile, is_pending: true, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});

                } else if (connected_flag) {
                    this.setState({user_details: tmp_user_details, connection_requests: tmp_connection_requests, user_profile: tmp_profile, is_connected: true, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});

                } else {
                    this.setState({user_details: tmp_user_details, connection_requests: tmp_connection_requests, user_profile: tmp_profile, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});

                }



                    const newConnectionsId = setInterval(() => {
                               var promises = [];
                                var tmp_connections = [];
                                var tmp_connection_responses = [];
                                var tmp_personal_connections = [];
                                var connections_promise = this.GetConnections().then(connections => {
                                    if (connections.data != "no connections") {
                                        tmp_personal_connections = connections.data.connections;                  
                                    }
                                }).catch((error) => {
                                    console.log("GeConnections failed " + error);
                                    this.setState({server_failure: true});
                                });

                                var connections_request_promise = this.GetConnectionRequests().then(requests => {
                                    if(requests.data.connection_requests != undefined) {
                                        tmp_connections = requests.data.connection_requests;
                                    }
    
                                }).catch((error) => {
                                    console.log("GetConnectionRequests failed " + error);
                                    this.setState({server_failure: true});
                                });

                                var recipient_promise = this.GetMyRecipients().then((recipients) => {
                                    if (recipients.data != "no requests") {
                                        tmp_connection_responses = recipients.data.recipient_requests;
                                    }
                                }).catch((error) => {
                                    console.log("GetMyRecipients failed " + error);
                                    this.setState({server_failure: true});
                                }); 


                                promises.push(connections_request_promise);
                                promises.push(connections_promise);
                                promises.push(recipient_promise);
                                Promise.all(promises).then( () => {
                                    connected_flag = false;
                                    pending_flag = false;
                                    requested_flag = false;
                                    tmp_connections.find((conn) => {
                                        if(conn.recipient == this.props.match.params.ID) {
                                            if (conn.status == "Pending") {
                                                pending_flag = true;
                                            } 

                                        }

                                    });
                                    tmp_personal_connections.find((conn) => {
                                        if (conn.connection == this.props.match.params.ID) {
                                            connected_flag = true;
                                        }
                                    });
                                    tmp_connection_responses.forEach((request) => {
                                        if (request.status == "Pending" && request.recipient == tmp_user_details.userID && request.userid == this.props.match.params.ID) {
                                            requested_flag = true;
                                        }
                                    });
                                    if (requested_flag) {
                                        this.setState({ connection_requests: tmp_connection_requests, requested: true, is_pending: false, is_connected: false, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});
                                    } else if (pending_flag) {
                                        this.setState({ connection_requests: tmp_connection_requests, is_pending: true, requested: false, is_connected: false, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});

                                    } else if (connected_flag) {
                                        this.setState({ connection_requests: tmp_connection_requests, is_connected: true, is_pending: false, requested: false, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});

                                    } else {
                                       // console.log("In the else");
                                        this.setState({ connection_requests: tmp_connection_requests, is_connected: false, requested: false, is_pending: false, connection_responses: tmp_connection_responses}, () => {this.setState({loading: false})});

                                    }                                      
                                  }).catch((error) => {
                                    clearInterval(newConnectionsId);
                                  });
                                }, 5000);
                              this.setState(prevState => {
                                return {
                                  ...prevState,
                                  connectionInterval:  newConnectionsId,
                                };
                              });



        }).catch(error => {
        	console.log("Promises failed " + error);
        	this.setState({server_failure: true});
        });
	}

    componentWillUnmount(){
        clearInterval(this.state.connectionInterval);
    }

	async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

	async GetConnectionRequests() {
        const res = await axios.get(APIHost + '/api/getConnectionRequests', { withCredentials: true, userID: this.state.user });
        return await res;
    }

	async GetConnections() {
        const res = await axios.get(APIHost + '/api/getConnections', { withCredentials: true });
        return await res;
    }

	async GetMyRecipients() {
        const res = await axios.get(APIHost + '/api/getRecipientRequests', { withCredentials: true });
        return await res;
    }

    async GetUserProfile() {
	const res = await axios.get(APIHost + '/api/getUserProfile', 
	{params: {withCredentials: true,
	userID: this.state.user}});
    return await res;
    }

 

    Respond(event, response) {
        axios.post(APIHost + '/api/connectionResponse', {
            withCredentials: true,
            response: response,
            requestor: this.props.match.params.ID
        }).then(res => {
            if (response == "Accept") {
               this.setState({requested: false, is_connected: true});
            } else {
                this.setState({requested: false, is_connected: false});
            }
        }).catch(error => {
            console.log("Updating connections failed");
            this.setState({server_failure: true})
        });
    }

    Connect(event) {
        event.preventDefault();
        var tmp_connections = this.state.connection_requests;
        tmp_connections.push({});
        tmp_connections[tmp_connections.length-1].userid = this.state.user_details.userid;
        tmp_connections[tmp_connections.length-1].recipient = this.state.user;
        tmp_connections[tmp_connections.length-1].status = "Pending";


       axios.post(APIHost + '/api/connectionRequest',
        { 
            withCredentials: true,
            requested: this.state.user
        }).then(() => {
            this.setState({connection_requests: tmp_connections});
            document.getElementById(event.target.id).disabled = true;
        }).catch((error) => {
            console.log("Sending connection request failed with error " + error);
            this.setState({server_failure: true});
        });
    }

    DirectToMessaging(event) {
        event.preventDefault();
        this.props.history.push({ pathname: "/DirectMessage", state: { loggedIn: true, profile_userid: this.state.user_profile.userid } });
    }

	render() {
		if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else {
		var display_button = [];
        var display_bio = [];
        if (this.state.user_details.bio) {
            display_bio.push(<div className="flex-item view-profile">
                <h5>{this.state.user_profile.name}'s bio</h5>
                <p>{this.state.user_profile.bio} </p>
                </div>);
        } else {
            display_bio.push(<div className="flex-item view-profile">
                <h5>{this.state.user_profile.name} doesn't have a bio at this time</h5>
                </div>);
        }
		if(this.state.requested) {
			display_button.push(<div className="pt-5 flexbox-container"><div className="profile-button-padding"><button id="connect" className="simple-button" disabled={this.state.responded} onClick={e => this.Respond(e, "Connected")}>Accept</button></div><button id="connect" className="simple-button" disabled={this.state.responded} onClick={e => this.Respond(e, "Ignored")}>Ignore</button></div>);

		} else if (this.state.is_pending) {
			display_button.push(<div className="pt-5"><button id="pending" className="simple-button" disabled={true}>Pending</button></div>);
		} else if (this.state.is_connected) {
            display_button.push(<div className="pt-5 flexbox-container"><div className="profile-button-padding"><button id="connected" disabled={true} className="simple-button">Connected</button></div><button id="connect" className="simple-button" onClick={this.DirectToMessaging}>Message</button></div>);
		} else {
			display_button.push(<div className="pt-5"><button id="connect" className="simple-button" onClick={this.Connect}>Connect</button></div>);
		}
		return(
			<div className="ViewProfile page-container">
			<div className="inner-container">
			<Header history={this.props.history} loggedIn={this.state.isLogged} />
            {this.state.server_failure && <ServerFailure />}
     
                <div className="flexbox-container">
                <div className="flex-item pt-10">
<div className="flexbox-container-column">
<div>
<h3>{this.state.user_profile.name}</h3>
</div>
<div>
                <Avatar
  alt="Profile picture"
  src={this.state.user_profile.picture_url}
  sx={{ width: 114, height: 114 }}
/>
</div>
<div>
{display_button}
</div>

</div>
                </div>
              
                {this.state.user_details.bio ? <div className="flex-item view-profile"><h5>{this.state.user_profile.name}'s bio</h5>
                <p>{this.state.user_profile.bio} </p> </div> : <div className="flex-item view-profile"><h5>{this.state.user_profile.name} doesn't have a bio at this time</h5></div>}
                
                
                </div>
                
                </div>
			<Footer history={this.props.history} loggedIn={this.state.isLogged} />
			</div>
			);
		}
	}
}

export default withRouter(ViewProfile);