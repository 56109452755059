import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, withRouter } from 'react-router-dom';


class Calendar extends Component {
	constructor(props) {
	super(props);
    this.state = {
    }
	}

	componentDidMount() {
		window.scroll(0, 0);

	}

render() {




	return (
		<div className="Calendar">
		<Header />
		<div className="text-center">
		<h2>Our Mindset upcoming events and programming</h2>
		</div>
		<div className="calendar-large">
		<div className="flexbox-container">
<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showTitle=0&src=MzQxcmFpYjJiYWwyNGo1bTcwb3Bwa2E4cXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237986CB" width="100%" height="600" frameborder="0" scrolling="no"></iframe>		
		</div>
		</div>
		<div className="calendar-small">
		<div className="flexbox-container">
		<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&mode=AGENDA&showTabs=0&showTitle=0&src=MzQxcmFpYjJiYWwyNGo1bTcwb3Bwa2E4cXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237986CB"  width="100%" height="600" frameborder="0" scrolling="no"></iframe>
		</div>
		</div>
		<Footer />
		</div>
		);
}

}

export default withRouter(Calendar);
