import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Link, withRouter, Redirect } from 'react-router-dom';
import social from './images/diverse-social.jpg';
import manny from './images/manny_thumbnail.png';
import placeholder from './images/placeholder-profile-image.jpeg';
import {APIHost} from './GlobalVariables.js';
import axios from 'axios';
import Date from 'dropdown-date';
import { Carousel } from 'react-responsive-carousel';
import './css/carousel.css';
import Avatar from '@mui/material/Avatar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const TitleError = () => (
    <p><font color="red">You must enter a room title</font></p>
    );

const DateError = () => (
    <p><font color="red">Please pick a valid date</font></p>
    );

const ServerFailure = () => (
    <div className="text-center">
    <h4><font color="red">Oops, there was an issue. Please refresh the page and check your internet connection. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h4>
    </div>
    );

class Discover extends Component {

	constructor(props) {
		super(props);
        this.state = {
            loading: true,
            isLogged: false,
            display_search: false,
            server_failure: false,
            display_users: [],
            connection_requests: [],
            connections: [],
            my_recipients: [],
            user_details: []
        }
        this.GetUserDetails = this.GetUserDetails.bind(this);
        this.CheckLogin = this.CheckLogin.bind(this);
       this.GetAllUsers = this.GetAllUsers.bind(this);
       this.onChange = this.onChange.bind(this);
       this.SearchName = this.SearchName.bind(this);
        this.ClearSearch = this.ClearSearch.bind(this);
        this.Connect = this.Connect.bind(this);
        this.DisplayUsers = this.DisplayUsers.bind(this);
        this.GetConnectionRequests = this.GetConnectionRequests.bind(this);
        this.GetMyRecipients = this.GetMyRecipients.bind(this);
        this.Respond = this.Respond.bind(this);
        this.GetConnections = this.GetConnections.bind(this);
        this.ReturnDisplayUser = this.ReturnDisplayUser.bind(this);
        this.GetAvailableConnections = this.GetAvailableConnections.bind(this);
        this.RecordEvent = this.RecordEvent.bind(this);
	}


	componentDidMount() {
		window.scroll(0, 0);
        var promises = [];
        var tmp_connections = [];
        var tmp_all_users = [];
        var tmp_recipients = [];
        var tmp_personal_connections = [];
        var tmp_user_details = [];
        var tmp_available = [];
        this.CheckLogin().then(res => {
            if (res.data.loggedIn) {
               var user_promise = this.GetUserDetails().then(user_details => {
                    tmp_user_details = user_details.data.user_details;
                    return user_details;
                }).catch(error => {
                     console.log("GetUserDetails failed " + error);
                    this.setState({server_failure: true});
                });

                var all_promise = this.GetAllUsers().then(all_users => {
                    tmp_all_users = all_users.data.user_details;
                    return all_users;
                }).catch((error) => {
                    console.log("GetAllUsers failed " + error);
                    this.setState({server_failure: true});
                });

                var available_promise = this.GetAvailableConnections().then(conns => {
                    tmp_available = conns.data.available_connections;
                    return conns;
                }).catch((error) => {
                    console.log("GetAvailableConnections failed " + error);
                    this.setState({server_failure: true});
                });

                var connections_promise = this.GetConnections().then(connections => {
                    if (connections.data != "no connections") {
                        tmp_personal_connections = connections.data.connections;                  
                    }
                    return connections;
                }).catch((error) => {
                    console.log("GeConnections failed " + error);
                    this.setState({server_failure: true});
                });


                var request_promise = this.GetConnectionRequests().then(requests => {
                    if(requests.data.connection_requests != undefined && requests.data != "no requests") {
                        tmp_connections = requests.data.connection_requests;
                    }
                }).catch((error) => {
                    console.log("GetConnectionRequests failed " + error);
                    this.setState({server_failure: true});
                });

                var recipient_promise = this.GetMyRecipients().then((recipients) => {
                    if (recipients.data != "no requests") {
                        tmp_recipients = recipients.data.recipient_requests;
                    }
                    return recipients;
                }).catch((error) => {
                    console.log("GetMyRecipients failed " + error);
                    this.setState({server_failure: true});
                }); 


                promises.push(user_promise);
                promises.push(all_promise);
                promises.push(connections_promise);
                promises.push(request_promise);
                promises.push(recipient_promise);
                promises.push(available_promise);
                Promise.all(promises).then( () => {
                    this.setState({isLogged: true, user_details: tmp_user_details, all_users: tmp_all_users, available_connections: tmp_available, connections: tmp_connections,
                    connection_requests: tmp_connections, my_recipients: tmp_recipients}, () => { this.setState({loading: false})});


                    const newConnectionsId = setInterval(() => {
                               var promises = [];
                                var tmp_connections = [];
                                var tmp_recipients = [];
                                var tmp_personal_connections = [];
                                var connections_promise = this.GetConnections().then(connections => {
                                    if (connections.data != "no connections") {
                                        tmp_personal_connections = connections.data.connections;                  
                                    }
                                }).catch((error) => {
                                    console.log("GeConnections failed " + error);
                                    this.setState({server_failure: true});
                                });

                                var connections_request_promise = this.GetConnectionRequests().then(requests => {
                                    if(requests.data.connection_requests != undefined) {
                                        tmp_connections = requests.data.connection_requests;
                                    }
    
                                }).catch((error) => {
                                    console.log("GetConnectionRequests failed " + error);
                                    this.setState({server_failure: true});
                                });

                                var recipient_promise = this.GetMyRecipients().then((recipients) => {
                                    if (recipients.data != "no requests") {
                                        tmp_recipients = recipients.data.recipient_requests;
                                    }
                                }).catch((error) => {
                                    console.log("GetMyRecipients failed " + error);
                                    this.setState({server_failure: true});
                                }); 


                                promises.push(connections_request_promise);
                                promises.push(connections_promise);
                                promises.push(recipient_promise);
                                Promise.all(promises).then( () => {
                                      /*var connection_flag, request_flag, recipient_flag = false;
                                      if (this.state.connections.length < tmp_personal_connections.length) {
                                        connection_flag = true;
                                      }
                                      if (this.state.connection_requests.length < tmp_connections.length) {
                                        request_flag = true;
                                      }
                                      if (this.state.my_recipients.length < tmp_recipients.length) {
                                        recipient_flag = true;
                                      }

                                      if (connection_flag || request_flag || recipient_flag) {*/
                                        this.setState({connections: tmp_personal_connections, connection_requests: tmp_connections, my_recipients: tmp_recipients});
                                      //}
                                      }).catch((error) => {
                                        clearInterval(newConnectionsId);
                                      });
                            }, 5000);
                          this.setState(prevState => {
                            return {
                              ...prevState,
                              connectionInterval:  newConnectionsId,
                            };
                          });



                }).catch((error) => {
                    console.log("promises failed " + error);
                    this.setState({server_failure: true});
                });

            } else {
                console.log("not logged in");
                this.props.history.push({ pathname: "/", state: { loggedIn: false } });
            }
                
        }).catch(error => {
            console.log("CheckLogin failed " + error);
                this.props.history.push({ pathname: "/", state: { loggedIn: false } });
        });

	}

    componentWillUnmount(){
    clearInterval(this.state.connectionInterval);
  }

    async GetProfileURL() {
        const res = await axios.get(APIHost + '/api/getProfileURL', {withCredentials: true});
        return await res;
    }

    async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async GetMyRecipients() {
        const res = await axios.get(APIHost + '/api/getRecipientRequests', {withCredentials: true});
        return await res;
    }

    async GetConnectionRequests() {
        const res = await axios.get(APIHost + '/api/getConnectionRequests', { withCredentials: true });
        return await res;
    }

    async GetConnections() {
        const res = await axios.get(APIHost + '/api/getConnections', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    async GetAvailableConnections() {
        const res = await axios.get(APIHost + '/api/getAvailableConnections', { withCredentials: true });
        return await res;
    }

    async GetAllUsers() {
        const res = await axios.get(APIHost + '/api/getAllUsers', { withCredentials: true });
        return await res;
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    ConnectionCallback(id, requestor, response) {
        var cnt = 0;
        var tmp_connections = this.state.connection_requests;

                if (response == "Ignored") {
                            document.getElementById(id).disabled = true
                document.getElementById(requestor+"_accept").disabled = true

                    this.setState({ignored: true});
                } else {
                     document.getElementById(id).disabled = true
                document.getElementById(requestor+"_ignore").disabled = true
                    this.setState({accepted: true});
                }
        tmp_connections.find((conn) => {
            if (conn.userid == requestor && conn.recipient == this.state.user_details.userID) {
                if (response == "Ignored") {
                    //this.setState({ignored: true});
                 tmp_connections[cnt].status = "Ignored";
                } else {
                   // this.setState({accepted: true});
                    tmp_connections[cnt].status = "Connected";
                }

            } else {
                cnt++;
            }
        });
        this.setState({connection_requests: tmp_connections});
    }

    RecordEvent(event, profile_id) {
        event.preventDefault();
        var profile_link = "/ViewProfile/" + profile_id;
        axios.post(APIHost + '/api/clickEvent', {
            withCredentials: true,
            type: 1,
            target: profile_id
        }).then(() => {
            this.props.history.push({ pathname: profile_link, state: { loggedIn: true } });
        }).catch((error) => {
            console.log("Failed to send the event " + error);
            this.props.history.push({ pathname: profile_link, state: { loggedIn: true } });
        });
    }

    Respond(event, response) {
        axios.post(APIHost + '/api/connectionResponse', {
            withCredentials: true,
            response: response,
            requestor: event.target.name
        }).then(res => {
            this.ConnectionCallback(event.target.id, event.target.name, response);
        }).catch(error => {
            console.log("Updating connections failed");
        });
    }

    ReturnDisplayUser(user, type) {
        var display_users = [];
                                var profile_link = "/ViewProfile/" + user.userid;

                                    var pending_flag = false;
                            var connected_flag = false;
                            var requested_flag = false;
                            if (type == "recipient") {
                            this.state.my_recipients.find(recipient => {
;                                if (recipient.userid == user.userid && recipient.status == "Pending") {
                                    display_users.push(<tr><td className="tr-spacing1"><Link onClick={(e) => this.RecordEvent(e, user.userid)} to={profile_link} className="link">{user.picture_url ? <Avatar
                                     alt="Profile picture"
                                     src={user.picture_url}
                                     sx={{ width: 34, height: 34 }}
                                    /> : <Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                    sx={{ width: 34, height: 34 }}
                                    />}</Link>
                                    </td><td className="tr-spacing2">{user.name}</td><td className="tr-spacing4"><button id={[`${user.userid}_accept`]} name={user.userid} className="simple-button" onClick={e => this.Respond(e, "Connected")}>Accept</button></td><td className="tr-spacing4"><button id={[`${user.userid}_ignore`]} name={user.userid} className="simple-button" onClick={e => this.Respond(e, "Ignored")}>Ignore</button></td></tr>)
                                    
                                }
                            });
                            } else if (type == "not recipient") {

                            
                            this.state.connection_requests.forEach((conn) => {
                                if (conn.status == "Pending" && conn.recipient == user.userid) {
                                    pending_flag = true;
                                } else if (conn.status == "Connected" && conn.recipient == user.userid) {
                                    connected_flag = true;
                                }
                            });

                            this.state.my_recipients.find(recipient => {
;                                if (recipient.userid == user.userid && recipient.status == "Pending") {
                                    requested_flag = true;
                                }
                            });
                            if (!connected_flag) {
                                if (pending_flag) {

                                    display_users.push(<tr><td className="tr-spacing1"><Link onClick={(e) => this.RecordEvent(e, user.userid)} to={profile_link} className="link">{user.picture_url ? <Avatar
                                      alt="Profile picture"
                                      src={user.picture_url}
                                      sx={{ width: 34, height: 34 }}
                                    /> : <Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                      sx={{ width: 34, height: 34 }}
                                    />}</Link>
                                    </td><td className="tr-spacing2">{user.name}</td><td className="tr-spacing4"><button id={user.userid} className="simple-button" disabled={true} onClick={this.Connect}>Pending</button></td></tr>);
                                    
                                } else if (!requested_flag) {
                                 display_users.push(<tr><td className="tr-spacing1"><Link onClick={(e) => this.RecordEvent(e, user.userid)} to={profile_link} className="link">{user.picture_url ? <Avatar
                                 alt="Profile picture"
                                 src={user.picture_url}
                                 sx={{ width: 34, height: 34 }}
                                /> : <Avatar
                                  alt="Profile picture"
                                  src={placeholder}
                                sx={{ width: 34, height: 34 }}
                                />}</Link>
                                </td><td className="tr-spacing2">{user.name}</td><td className="tr-spacing4"><button id={user.userid} className="simple-button" disabled={false} onClick={this.Connect}>Connect</button></td></tr>)
                                } 
                            }
                        } else if (type == "search") {
                            this.state.connection_requests.forEach((conn) => {
                                if (conn.status == "Pending" && conn.recipient == user.userid) {
                                    pending_flag = true;
                                } else if (conn.status == "Connected" && conn.recipient == user.userid) {
                                    connected_flag = true;
                                }
                            });

                            this.state.my_recipients.find(recipient => {
;                                if (recipient.userid == user.userid && recipient.status == "Pending") {
                                   requested_flag = true;
                                }
                            });

                            if (!connected_flag) {
                                if (pending_flag) {

                                    display_users.push(<tr><td className="tr-spacing1"><Link onClick={(e) => this.RecordEvent(e, user.userid)} to={profile_link} className="link">{user.picture_url ? <Avatar
                                      alt="Profile picture"
                                      src={user.picture_url}
                                      sx={{ width: 34, height: 34 }}
                                    /> : <Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                      sx={{ width: 34, height: 34 }}
                                    />}</Link>
                                    </td><td className="tr-spacing2">{user.name}</td><td className="tr-spacing4"><button id={user.userid} className="simple-button" disabled={true} onClick={this.Connect}>Pending</button></td></tr>);
                                    
                                } else if (requested_flag) {
                              display_users.push(<tr><td className="tr-spacing1"><Link onClick={(e) => this.RecordEvent(e, user.userid)} to={profile_link} className="link">{user.picture_url ? <Avatar
                                     alt="Profile picture"
                                     src={user.picture_url}
                                     sx={{ width: 34, height: 34 }}
                                    /> : <Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                    sx={{ width: 34, height: 34 }}
                                    />}</Link>
                                    </td><td className="tr-spacing2">{user.name}</td><td className="tr-spacing4"><button id={[`${user.userid}_accept`]} name={user.userid} className="simple-button" onClick={e => this.Respond(e, "Connected")}>Accept</button></td><td className="tr-spacing4"><button id={[`${user.userid}_ignore`]} name={user.userid} className="simple-button" onClick={e => this.Respond(e, "Ignored")}>Ignore</button></td></tr>)
                                    
                                } else {
                                display_users.push(<tr><td className="tr-spacing1"><Link onClick={(e) => this.RecordEvent(e, user.userid)} to={profile_link} className="link">{user.picture_url ? <Avatar
                                 alt="Profile picture"
                                 src={user.picture_url}
                                 sx={{ width: 34, height: 34 }}
                                /> : <Avatar
                                  alt="Profile picture"
                                  src={placeholder}
                                sx={{ width: 34, height: 34 }}
                                />}</Link>
                                </td><td className="tr-spacing2">{user.name}</td><td className="tr-spacing4"><button id={user.userid} className="simple-button" disabled={false} onClick={this.Connect}>Connect</button></td></tr>)
                                }
                            }
                           
                        }
                return display_users;
    }

    DisplayUsers(searched_user, requested) {
        var display_users = [];
        if (searched_user != null) {
            display_users.push(this.ReturnDisplayUser(searched_user, requested));
            this.setState({display_users: display_users});
        } else {
            if (this.state.available_connections.length == 1) {
                display_users.push(<div><h5>It looks like you're connected to all available community members!</h5></div>);
            } else {
            this.state.available_connections.forEach((user) => {
                if (user.userid != this.state.user_details.userID) {
                display_users.push(this.ReturnDisplayUser(user, requested));
            }
            });
        }
        }
        return display_users;
    }

    ClearSearch(event) {
        event.preventDefault();
                document.getElementById("name").value = "";

this.setState({display_search: false});    
}

    SearchName(event) {
        event.preventDefault();
        this.setState({display_search: true});
        var display_users = [];
        var flag = false;
        this.state.available_connections.forEach((user) => {
            if (user.name.toLowerCase() == this.state.name.toLowerCase() && user.userid != this.state.user_details.userID) {
                flag = true;
                this.DisplayUsers(user, "search");
            }
        });
        if (!flag) {
            display_users.push(<tr><td><h5>No users found</h5></td></tr>)
                        this.setState({display_users: display_users});

        }
    }

    Connect(event) {
        event.preventDefault();
        var tmp_connections = this.state.connection_requests;
        tmp_connections.push({});
        tmp_connections[tmp_connections.length-1].userid = this.state.user_details.userid;
        tmp_connections[tmp_connections.length-1].recipient = event.target.id;
        tmp_connections[tmp_connections.length-1].status = "Pending";

       axios.post(APIHost + '/api/connectionRequest',
        { 
            withCredentials: true,
            requested: event.target.id
        }).then(() => {
            this.setState({connection_requests: tmp_connections});
            document.getElementById(event.target.id).disabled = true;
        }).catch((error) => {
            console.log("Sending connection request failed with error " + error);
        });
    }

	render() {
        if(this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);
        } else if (!this.state.isLogged) {
            return ( <Redirect to='/' />);

        } else {



        return(
            <div className="Discover page-container">
            <div className="inner-container">
            <Header history={this.props.history} loggedIn={false} />
            {this.state.server_failure && <ServerFailure />}
            <div className="flexbox-container-narrow">
                                    <div className="form-input center">
                                        <div className="input-items default">
                                            <input type="name" id="name" name="name" onChange={this.onChange} placeholder="Type someone's name" />
                                            </div>
                                            </div>
                                            <div className="pr-5">
                                            <button onClick={this.SearchName} className="simple-button">Search</button>
                                        </div>  
                                                                                    <div>
                                            <button onClick={this.ClearSearch} className="simple-button">Clear</button>
                                        </div>      
                                        </div>
            <div className="flexbox-container-column-tall">

            <table className="center">
            {this.state.display_search && this.state.display_users}

            {!this.state.display_search && this.DisplayUsers(null, "recipient")}
            {!this.state.display_search && this.DisplayUsers(null, "not recipient")}
            </table>
            </div>
            </div>
            <Footer history={this.props.history} />
            </div>
            );   
        }

      

	}
}

export default withRouter(Discover);