import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import { APIHost } from './GlobalVariables';

axios.defaults.withCredentials = true;

const MessageFailed = () => (
	<p><font color="red">We apologize but something went wrong. Please save your survey answers and refresh the page to try again.</font></p>
);

const MessageSent = () => (
	<p><font color="blue">Thanks so much for your input! We really appreciate you taking the time!</font></p>
);

class Survey extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			name: '',
			lessons: '',
			comments: '',
			different: '',
			messageSent: false,
			messageFailed: false
		}
		this.onChange = this.onChange.bind(this);
		this.QuestionState = this.QuestionState.bind(this);
		this.SubmitSurvey = this.SubmitSurvey.bind(this);
		this.SubmitSurveyCallback = this.SubmitSurveyCallback.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	QuestionState(event) {
	this.setState({question1_result: parseFloat(event.target.value)});
	}

	SubmitSurveyCallback(response) {
		if (response) {
			this.setState({
				messageSent: true
			});
		} else {
			this.setState({messageFailed: true});
		}

	}

	SubmitSurvey(event) {
		event.preventDefault();
		axios.post(APIHost + '/api/submitSurvey', {
			question1: this.state.question1,
			question2: this.state.question2,
			question3: this.state.question3,
			question4: this.state.question4,
			question5: this.state.question5,
			question6: this.state.question6,
			email: this.state.email,
			name: this.state.name,
			withCredentials: true
		}).then(res => {
			this.SubmitSurveyCallback(true);
		}).catch(error => {
			console.log("error is " + error);
			this.SubmitSurveyCallback(false);
		});
		
	}

	render() {
		return (
			<div className="Survey page-container">
            <div className="inner-container">
				<Header history={this.props.history} />
				<div className="container pt-65">
					<div className="row">
						{/*  <div className="col-md-5">
                            <img src={idea} alt="Idea" className="img-fluid" />
                        </div> */}
						<div className="col-md-12">
							<h3 className="contact-title pb-10">We want to hear from you!</h3>
							<h5>Everyone that responds fully to the below questions will get entered to win one of 4 $50 gift cards from Our Mindset! Make sure to provide your name and email!</h5><br /><hr />
								<div className="col-md-12">
								
										<div className="form-input mt-25">
						
		</div></div>
		<div className="col-md-12">
			<div className="form-input mt-25">
					<p>What brought you to the Our Mindset mobile app?</p>
				<div className="input-items default">
					<textarea name="question1" id="question1" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>
				<div className="col-md-12">
			<div className="form-input mt-25">
					<p>If you were to tell a friend about the Our Mindset app, how would you describe it to them?</p>
				<div className="input-items default">
					<textarea name="question2" id="question2" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>

						<div className="col-md-12">
			<div className="form-input mt-25">
					<p>What additions or changes could we do to bring you back to the app more often?</p>
				<div className="input-items default">
					<textarea name="question3" id="question3" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>
						<div className="col-md-12">
			<div className="form-input mt-25">
					<p>If you haven't been attending the live events, why not? What would inspire you to attend?</p>
				<div className="input-items default">
					<textarea name="question4" id="question4" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>
						<div className="col-md-12">
			<div className="form-input mt-25">
					<p>What additional content could we provide at the Academy, for Events, or under Premium Content that would inspire you to upgrade to a Premium Membership?</p>
				<div className="input-items default">
					<textarea name="question5" id="question5" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>

				<div className="col-md-12">
			<div className="form-input mt-25">
					<p>Do you have any other suggestions or comments?</p>
				<div className="input-items default">
					<textarea name="question6" id="question6" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>

								<div className="row">
									<div className="col-md-6">
										<div className="form-input mt-25">
											<label>Name (optional but appreciated)</label>
											<div className="input-items default">
												<input id="name" name="name" type="text" onChange={this.onChange} placeholder="Name" />
												<i className="lni lni-user"></i>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-input mt-25">
											<label>Email used for app (to enter to win the $50 gift card!)</label>
											<div className="input-items default">
												<input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
												<i className="lni lni-envelope"></i>
											</div>
										</div>
									</div>

			
								</div>

						<p className="form-message"></p>
									<div className="col-md-12 mt-10 mb-10">
										<div className="form-input light-rounded-buttons mt-30">
											<button onClick={this.SubmitSurvey} className="simple-button" disabled={this.state.messageSent}>Submit</button>
										</div>
									</div>
							{this.state.messageSent && <MessageSent />}
							{this.state.messageFailed && <MessageFailed />}

						</div>
					</div>
				</div>
				</div>
				<Footer history={this.props.history} />
			</div>
		);

	}
}

export default Survey;