import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, withRouter } from 'react-router-dom';
import { APIHost } from './GlobalVariables';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import placeholder from './images/placeholder-profile-image.jpeg';
import { MailTwoTone, MailOutlined } from '@ant-design/icons';


const NoSelection = () => (
    <p><font color="red">Please select or search for a user to send a message.</font></p>
    );

const ServerFailure = () => (
    <div className="text-center">
    <h4><font color="red">Oops, there was an issue. Please refresh the page and check your internet connection. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h4>
    </div>
    );

const MessageFailure = () => (
    <h5><font color="red">Oops, there was an issue sending your message. Please your message and refresh the page. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h5>
    );

class DirectMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			server_failure: false,
			messages: [],
			message_users: [],
			no_messages: false,
			display_messages: [],
			interactions: [],
			saved_interactions: [],
			user_messages: [],
			selected_user: null,
			first_message: false,
			show_thread: false, 
			profile_name: '',
			from_profile: false,
			new_interaction: false,
			no_selection: false,
			name_panel: true,
			message_panel: false,
			submitted_message: false,
			global_found_user: true,
			submit_message_failure: false,
			intervalId: 0,
			all_users: []
		}
		this.GetDirectMessages = this.GetDirectMessages.bind(this);
		this.GetAllUsers = this.GetAllUsers.bind(this);
		this.CheckLogin = this.CheckLogin.bind(this);
		this.DisplayMessage = this.DisplayMessage.bind(this);
		this.GetUserDetails = this.GetUserDetails.bind(this);
		this.onChange = this.onChange.bind(this);
		this.SearchName = this.SearchName.bind(this);
		this.ClearSearch = this.ClearSearch.bind(this);
		this.SubmitMessage = this.SubmitMessage.bind(this);
		this.CancelMessage = this.CancelMessage.bind(this);
		this.MarkAsRead = this.MarkAsRead.bind(this);
		this.MessageClick = this.MessageClick.bind(this);
		this.SearchResults = this.SearchResults.bind(this);
		this.CheckMobile = this.CheckMobile.bind(this);
		this.BackToNames = this.BackToNames.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		var promises = [];
		var tmp_msgs = [];
		var tmp_users = [];
		var tmp_message_users = [];
		var tmp_interactions = [];
		var tmp_user_details = [];
		var no_messages_flag = false;
		var promises = [];
		var tmp_user_msgs = [];
		window.addEventListener("resize", this.CheckMobile());

		this.CheckLogin().then((res) => {
			if (res.data.loggedIn) {
				var message_promise = this.GetDirectMessages().then((messages) => {
					if (messages.data != "no messages") {
						tmp_msgs = messages.data.threads;
						tmp_interactions = messages.data.interactions;
						tmp_user_msgs = messages.data.direct_messages;
						/*tmp_user_msgs.forEach((foo) => {
        				console.log("unread_messages " + foo.unread_messages + " and " + foo.message_id + " and " + foo.name + " and " + foo.id);
						});*/

					} else {
						no_messages_flag = true;
					}
				}).catch((error) => {
					console.log("GetDirectMessages failed " + error);
				});
				var user_promise = this.GetUserDetails().then(user_details => {
                    tmp_user_details = user_details.data.user_details;
                    return user_details;
                }).catch(error => {
                     console.log("GetUserDetails failed " + error);
                });
				var users_promise = this.GetAllUsers().then((users) => {
					tmp_users = users.data.user_details;
				}).catch((error) => {
					console.log("GetAllUsers failed " + error);
				});
				promises.push(message_promise);
				promises.push(user_promise);
				promises.push(users_promise);
				Promise.all(promises).then(() => {
					if (this.props.location.state.profile_userid) {
						var tmp_name = null;
						tmp_users.find((user) => {
							if (user.userid == this.props.location.state.profile_userid) {
								tmp_name = user.name;
							}
						});
						this.setState({from_profile: true, profile_name: tmp_name});
					}
					if (no_messages_flag) {
						this.setState({no_messages: true, isLogged: true, all_users: tmp_users, user_details: tmp_user_details}, () => { this.setState({loading: false})});
					} else {
						if(this.state.global_found_user){
							this.setState({user_messages: tmp_user_msgs, all_users: tmp_users, user_details: tmp_user_details, messages: tmp_msgs, interactions: tmp_interactions, saved_interactions: tmp_interactions, isLogged: true}, () => { this.setState({loading: false})});
						}
					}
							const newIntervalId = setInterval(() => {
								this.GetDirectMessages().then((messages) => {
								if (messages.data != "no messages") {
									if(this.state.messages.length < messages.data.threads.length) {
										this.setState({messages: messages.data.threads, interactions: messages.data.interactions, user_messages: messages.data.direct_messages});
									}
								} 
							});
							 	}, 5000);
					      this.setState(prevState => {
							return {
						      ...prevState,
						      interactionInterval: newIntervalId,
						    };
						  });
				}).catch((error) => {
					console.log("promises failed " + error);
					this.setState({server_failure: true});
				});
			} else {
			this.props.history.push({ pathname: "/", state: { loggedIn: false } });

			}
		}).catch((error) => {
			console.log("CheckLogin failed " + error);
			this.props.history.push({ pathname: "/", state: { loggedIn: false } });
		});

	}

componentWillUnmount(){
	window.removeEventListener("resize", this.CheckMobile)
    clearInterval(this.state.messageInterval);
    clearInterval(this.state.interactionInterval);
  }

	async GetAllUsers() {
        const res = await axios.get(APIHost + '/api/getAllUsers', { withCredentials: true });
        return await res;
    }
    async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }
    async GetDirectMessages() {
        const res = await axios.get(APIHost + '/api/getDirectMessages', { withCredentials: true });
        return await res;
    }
    async CheckLogin() {
	const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
    return await res;
}

	CheckMobile() {
		this.setState({ mobile: window.innerWidth <= 760 });
	}

	BackToNames(event) {
		event.preventDefault();
		this.setState({message_panel: false, name_panel: true});
	}

	MarkAsRead(message_id) {
		if (!this.state.first_message) {
		var tmp_interactions = this.state.user_messages;
		tmp_interactions.forEach(interaction => {
			if (interaction.message_id == message_id && interaction.userid == this.state.user_details.userID) {
				interaction.unread_messages = false;
			}
		});
		this.setState({user_messages: tmp_interactions});
		axios.post(APIHost + '/api/markAsRead', {
			withCredentials: true,
			message_id: message_id,
			userid: this.state.user_details.userID
		}).catch((error) => {
			console.log("Failed to mark message as read " + error);
		});
	}
	}

	MessageClick(event, userid) {
		clearInterval(this.state.messageInterval);
		this.setState({message_panel: true, name_panel: false});
		this.DisplayMessage(userid, null);
		if (!this.state.mobile) {
    		document.getElementById("message").value = "";
    	}
		var no_messages_flag = false;
		const newIntervalId = setInterval(() => {
			this.GetDirectMessages().then((messages) => {
			if (messages.data != "no messages") {
				if(this.state.messages.length < messages.data.threads.length) {
					this.setState({messages: messages.data.threads, interactions: messages.data.interactions, user_messages: messages.data.direct_messages});
					this.DisplayMessage(userid, messages.data.threads);
				}
			} else {
					no_messages_flag = true;
			}
		});
		 	}, 3000);
      this.setState(prevState => {
		return {
	      ...prevState,
	      messageInterval: newIntervalId,
	    };
	  });
	}

	DisplayMessage(userid, message_threads, new_interaction, local_message_id) {
		var display_messages = [];
		var cnt = 0;
		var msg_id;
		var threads_to_use = [];
		var display_name = [];
		this.setState({selected_user: userid, no_selection: false});
		if (!this.state.searched_name && !new_interaction) {
			this.setState({to_id: userid});
		}

		if (userid != "no user") {
			if (!local_message_id) {
			this.state.interactions.find((msg) => {
				if(msg.userid == userid) {
					msg_id = msg.message_id;
					this.setState({message_id: msg_id, to_name: msg.name});
					this.MarkAsRead(msg_id)

				}
				// Maybe a break here?
			});
		} else {
			msg_id = local_message_id;
		}
			if (message_threads == null) {
				threads_to_use = this.state.messages;
			} else {
				threads_to_use = message_threads;
			}
		threads_to_use.forEach((message) => {
		//console.log("message id " + msg_id + " and " + userid + " and " + message.from_id + " and " + message.message_id + " and " + this.state.user_details.userID + " and " + message.message);

								if (message.from_id == this.state.user_details.userID && message.message_id == msg_id) {
									display_messages.push(<div className="from-message-box"><h5>{message.message}</h5></div>);
								} else if (message.from_id == userid && message.message_id == msg_id){
									display_messages.push(<div className="to-message-box"><h5 className="text-color-white">{message.message}</h5></div>);

								}

				
				/*message[cnt].forEach((thread) => {
				display_messages.push(<h3>{thread.message}</h3>);
			});*/
			//} 

		});
		if (this.state.mobile) {
        		var current_userid = null;
        		var name = null;
        		var avatar = null;
        		this.state.all_users.find(pic => {
        			if (pic.userid == userid && pic.userid != this.state.user_details.userID) {
        				avatar = pic.picture_url;
        				name = pic.name;
        			}
        		});
 
        		display_name.push(<div className="flexbox-container-messaging message-padding solid-bottom">
        			<button className="simple-button" onClick={this.BackToNames}>Back</button><div className="pr-10">{avatar ? <Avatar
                                     alt="Profile picture"
                                     src={avatar}
                                     sx={{ width: 34, height: 34 }}
                                    /> : <Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                    sx={{ width: 34, height: 34 }}
                                    />}</div><div><h4>{name}</h4></div></div>);
        }
        	
		display_name.push(display_messages);

		this.setState({display_messages: display_name, message: ""});
	}
		return display_messages;
	}

	onChange(event) {
		 if(!this.state.message_id && !this.state.new_interaction) {
    		this.setState({no_selection: true});
    	} 
        this.setState({ [event.target.name]: event.target.value, submitted_message: false});
    }

    ClearSearch(event) {
    	event.preventDefault();
    	this.setState({searched_name: "", interactions: this.state.saved_interactions, display_messages: null, message_id: null});
    }

    CancelMessage(event) {
    	event.preventDefault();
    	document.getElementById("message").value = "";
    	this.ClearSearch(event);
    }

    SubmitMessage(event) {
    	event.preventDefault();
    		this.setState({submitted_message: true});
    	if (this.state.message) {

    	

    	axios.post(APIHost + '/api/directMessage', 
    	{
    		withCredentials: true,
    		name: this.state.user_details.name,
    		message: this.state.message,
    		message_id: this.state.message_id,
    		to_id: this.state.to_id,
    		to_name: this.state.to_name
    	}).then((msg_id) => {
    		var local_message_id = null;
    		if(msg_id.data.message_id) {
    			local_message_id = msg_id.data.message_id.msg_id;
    			this.setState({message_id: msg_id.data.message_id.msg_id});
    		} else {
    			local_message_id = this.state.message_id;
    		}
    		var tmp_msgs = this.state.messages;
    		tmp_msgs.push({});
    		tmp_msgs[tmp_msgs.length-1].message = this.state.message;
    		tmp_msgs[tmp_msgs.length-1].from_id = this.state.user_details.userID;
    		tmp_msgs[tmp_msgs.length-1].date = new Date();
    		tmp_msgs[tmp_msgs.length-1].message_id = local_message_id;
    		this.setState({messages: tmp_msgs});
    		this.DisplayMessage(this.state.to_id, null, false, local_message_id);

    		//var tmp_msgs = {message_id: this.state.message_id, from_id: this.state.user_details.userID, date: new Date(), message: this.state.message};
    		//this.setState({...this.state.messages, tmp_msgs})

    		document.getElementById("message").value = "";
    		/*if (this.state.searched_name) {
    		this.ClearSearch(event);
    	}*/
    		this.setState({message: null});
    		var tmp_interactions = this.state.saved_interactions;
    		if (this.state.new_interaction) {
	    		tmp_interactions.push({});
	    		tmp_interactions[tmp_interactions.length-1].message_id = local_message_id;
	    		tmp_interactions[tmp_interactions.length-1].last_interaction = new Date();
	    		tmp_interactions[tmp_interactions.length-1].userid = this.state.to_id;
	    		tmp_interactions[tmp_interactions.length-1].name = this.state.to_name;
	    		tmp_interactions[tmp_interactions.length-1].unread_messages = true;
    		} else {
    			tmp_interactions.find((interact) => {
    				if (interact.message_id == this.state.message_id) {
    					interact.last_interaction = new Date();
    				}
    			});
    		}
    			tmp_interactions.sort((a,b) => b.last_interaction - a.last_interaction);

    			this.setState({interactions: tmp_interactions, new_interaction: false});
    		
    		//this.setState({message: null, interactions: tmp_interactions, show_thread: true});

    	}).catch((error) => {
    		console.log("Failed to submit the message " + error);
    		this.setState({submit_message_failure: true});
    	});
    }

    }

    SearchResults(name) {
    	var tmp_msgs = [];
    	var found_user = false;
    	var user_exists = false;
    	var name_to_search = null;
    	this.setState({no_selection: false});
    	if (name) {
    		name_to_search = name;
    		// to prevent the name passed in to stick for all further searches
    		this.setState({profile_name: null});
    	} else {
    		name_to_search = this.state.searched_name;
    	}
    	var cnt = 0;
    	this.state.interactions.find((name) => {

    		if (this.state.from_profile) {
    			if (name.userid == this.props.location.state.profile_userid) {
    			tmp_msgs.push({});
    			tmp_msgs[cnt].userid = name.userid;
    			tmp_msgs[cnt].name = name.name;
    			tmp_msgs[cnt].last_interaction = name.last_interaction;
    			tmp_msgs[cnt].message_id = name.message_id;
    			// this is so the messages stay displayed after a search and the user sends a message to the searched user
    			this.setState({to_id: name.userid})
			found_user = true;
			user_exists = true;
			cnt++;
    			}
    		} else {
    		if (name.name == name_to_search) {

    			tmp_msgs.push({});
    			tmp_msgs[cnt].userid = name.userid;
    			tmp_msgs[cnt].name = name.name;
    			tmp_msgs[cnt].last_interaction = name.last_interaction;
    			tmp_msgs[cnt].message_id = name.message_id;
    			// this is so the messages stay displayed after a search and the user sends a message to the searched user
    			this.setState({to_id: name.userid})
			found_user = true;
			user_exists = true;
			cnt++;
    		}
    	}
 
    	});
    	cnt = 0;
    	if (!found_user) {
    		this.state.all_users.find((user) => {
    			if (this.state.from_profile) {
    				if (user.userid == this.props.location.state.profile_userid) {
    		 				var now = new Date();

    				 tmp_msgs.push({});
    			tmp_msgs[cnt].userid = user.userid;
    			tmp_msgs[cnt].name = user.name;
    			tmp_msgs[cnt].last_interaction = now;
    			this.setState({to_id: user.userid, to_name: user.name, first_message: true, new_interaction: true, message_id: null});

    			user_exists = true;
    			cnt++;			
    				}
    			} else {
    			if (user.name == name_to_search && user.name != this.state.user_details.name) {
    				var now = new Date();

    				 tmp_msgs.push({});
    			tmp_msgs[cnt].userid = user.userid;
    			tmp_msgs[cnt].name = user.name;
    			tmp_msgs[cnt].last_interaction = now;
    			this.setState({to_id: user.userid, to_name: user.name, first_message: true, new_interaction: true, message_id: null});

    			user_exists = true;
    			cnt++;
    			}
    		}
    		});
    	}
this.setState({interactions: tmp_msgs, from_profile: false});
    	if (!user_exists) {
    		this.DisplayMessage("no user", null);
    		tmp_msgs.push({});
    		tmp_msgs[0].no_user = true;

    	} else if (!found_user) {

    		this.DisplayMessage(tmp_msgs[0].userid, null, true);
    		this.setState({global_found_user: false});

   		 } else {

      		this.DisplayMessage(tmp_msgs[0].userid, null, false); 		 	
   		 }
   		  

    }

    SearchName(event) {
    	event.preventDefault();
    	this.SearchResults(null);

    }

	render() {
		if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else {
        	var display_names = [];
        	var avatar = null;
        	var interacted_with = null;
        	var mail_notification = '';
        	if(this.state.interactions[0] && this.state.interactions[0].no_user) {
        		display_names.push(<div><h3>No user found</h3></div>);
        	} else {
        		this.state.interactions.forEach((user) => {
        		var current_userid = null;
        		this.state.all_users.find(pic => {
        			//if ((pic.userid == user.from_id || pic.userid == user.to_id) && pic.userid != this.state.user_details.userID) {
        			if (pic.userid == user.userid) {
        				avatar = pic.picture_url;
        			}
        		});
        		this.state.user_messages.find((msg) => {

        			if (msg.message_id == user.message_id) {
        				 if(msg.unread_messages) {
        					mail_notification = <MailTwoTone />;


        				} else {

        					mail_notification = <MailOutlined />;
        				}
        			}
        		});
        	
  

        		display_names.push(<div className="message-aside" onClick={(e) => this.MessageClick(e, user.userid)}><div className="flexbox-container-messaging message-padding">
        			<div className="pr-10">{avatar ? <Avatar
                                     alt="Profile picture"
                                     src={avatar}
                                     sx={{ width: 34, height: 34 }}
                                    /> : <Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                    sx={{ width: 34, height: 34 }}
                                    />}</div><div><h4>{user.name}</h4></div><div className="pl-5">{mail_notification}</div></div></div>);
        		});
       		 }
       		 if (this.state.mobile) {
       		 	var display_name_panel = [];
       		 	var display_message_panel = [];
       		 	display_name_panel.push(<div className="flex-item-messaging">
			<div className="message-aside"><div className="form-input mt-25"><div className="input-items default">
                <input type="text" id="search" value={this.state.searched_name} onChange={this.onChange} name="searched_name" placeholder="Who do you want to message?" />
                               <div className="pt-1 pl-2"> <button className="simple-button" onClick={this.SearchName}>Search</button><div className="pr-2 float-right"><button className="simple-button" onClick={this.ClearSearch}>Clear</button></div></div>
                </div></div>
                </div>
			{!this.state.loading && this.state.user_details && this.state.user_details.userID!=undefined && display_names}
			
			</div>); 

       		 	display_message_panel.push(<div className="flex-item-messaging">
						{!this.state.loading && (this.state.from_profile ? this.SearchResults(this.state.profile_name) : <div className="no-padding">{this.state.display_messages}</div>)}
																<div className="form-input">
											<div className="input-items default">
												<textarea onChange={this.onChange} values={this.state.message} rows="5" cols="10" id="message" name="message"></textarea>
											</div>
											<div className="flexbox-container">
											<div className="flex-item">
											<div className="flexbox-container">
											<div><button className="simple-button" disabled={this.state.submitted_message} onClick={this.SubmitMessage}>Send</button></div><div><button className="simple-button" onClick={this.CancelMessage}>Cancel</button></div>
											</div>
											{this.state.submit_message_failure && <MessageFailure />}
											{this.state.no_selection && <NoSelection />}
											</div></div>
										</div>

			</div>
			);

       	return(
        	<div className="page-container">
			<div className="inner-container">
			<Header history={this.props.history} loggedIn={this.state.isLogged} />
			<div className="flexbox-container">
			{this.state.name_panel && display_name_panel}
			{this.state.message_panel && display_message_panel}
			</div>
			</div>
						<Footer history={this.props.history} loggedIn={this.state.isLogged} />
						</div>
        		);
       		 } else {


        	return(
        	<div className="page-container">
			<div className="inner-container">
			<Header history={this.props.history} loggedIn={this.state.isLogged} />
			{this.state.server_failure && <ServerFailure />}
			<div className="flexbox-container">

			<div className="flex-item-30-messaging">
			<div className="message-aside"><div className="form-input mt-25"><div className="input-items default">
                <input type="text" id="search" value={this.state.searched_name} onChange={this.onChange} name="searched_name" placeholder="Who do you want to message?" />
                               <div className="pt-1 pl-2"> <button className="simple-button" onClick={this.SearchName}>Search</button><div className="pr-2 float-right"><button className="simple-button" onClick={this.ClearSearch}>Clear</button></div></div>

                </div></div>
                </div>
			{!this.state.loading && this.state.user_details && this.state.user_details.userID!=undefined && display_names}
			</div>
			<div className="flex-item-70-messaging">
						{!this.state.loading && (this.state.from_profile ? this.SearchResults(this.state.profile_name) : <div className="no-padding">{this.state.display_messages}</div>)}
																<div className="form-input">
											<div className="input-items default">
												<textarea onChange={this.onChange} values={this.state.message} rows="5" cols="10" id="message" name="message"></textarea>
											</div>
											<div className="flexbox-container">
											<div className="flex-item">
											<div className="flexbox-container">
											<div><button className="simple-button" onClick={this.SubmitMessage}>Send</button></div><div><button className="simple-button" onClick={this.CancelMessage}>Cancel</button></div>
											</div>
											{this.state.no_selection && <NoSelection />}
											{this.state.submit_message_failure && <MessageFailure />}
											</div></div>
										</div>

			</div>
			</div>
			</div>
						<Footer history={this.props.history} loggedIn={this.state.isLogged} />
						</div>
        		);
        	}
        		/*return (
        			<div className="flexbox-container">

        			<div className="flex-item-30-messaging">
        			<h3>Some text</h3>
        						</div>
			<div className="flex-item-70-messaging">
						<h3>Some text</h3>

			</div>
        			</div>
        			)*/
        }
	}
}

export default DirectMessage;