import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import idea from './images/idea.jpg';
import { Link, Redirect } from 'react-router-dom';
import { APIHost } from './GlobalVariables';

const PasswordMatch = () => (
	<p><font color="red">Your passwords didn't match. Please try again.</font></p>
);

const CodeGenerateFailed = () => (
	<p><font color="red">The code failed to generate and send. Please try again.</font></p>
);

const EmptyEmail = () => (
	<p><font color="red">Please provide the email associated with your account.</font></p>
);

const NotEmailAddress = () => (
	<p><font color="red">Please provide a valid email address.</font></p>
);

const NotUser = () => (
	<p><font color="red">There doesn't appear to be an account with that email.</font></p>
);

const NoCode = () => (
	<p><font color="red">There doesn't appear to be a reset code for that user.</font></p>
);

const CodeExpired = () => (
	<p><font color="red">That code expired. Codes are only good for 5 days.</font></p>
);

const EmptyPhone = () => (
	<p><font color="red">Please provide a valid cell number.</font></p>
);

const CodeGenerateSuccess = () => (
	<p><font color="blue">The reset code was successfully sent. Please enter it to the right once you
receive it and you will be able to reset your password.</font></p>
);

const StrongPassword = () => (
	<p><font color="red">Your password must be at least 6 characters, contain a mix of upper and lower case characters, and contain
at least one symbol</font></p>
);

const ServerFailure = () => (
	<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and check your internet connection.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class ForgotPassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			phone: '',
			code: '',
			password: '',
			confirmPassword: '',
			codeemail: '',
			goodcode: false,
			badcode: false,
			failedLogin: false,
			accountCreated: true,
			passwordMatch: true,
			codegenfailed: false,
			emailempty: false,
			notEmailAddress: false,
			phoneempty: false,
			codegensuccess: false,
			loading: true,
			logged_in: true,
			server_failure: false,
			notUser: false,
			noCode: false,
			codeExpired: false,
			strongPassword: true
		}
		this.SubmitToPhone = this.SubmitToPhone.bind(this);
		this.phoneCallBack = this.phoneCallBack.bind(this);
		this.onSubmitPhone = this.onSubmitPhone.bind(this);
		this.SubmitToEmail = this.SubmitToEmail.bind(this);
		this.emailCallBack = this.emailCallBack.bind(this);
		this.onSubmitEmail = this.onSubmitEmail.bind(this);
		this.SubmitToPassword = this.SubmitToEmail.bind(this);
		this.passwordCallBack = this.passwordCallBack.bind(this);
		this.onSubmitPassword = this.onSubmitPassword.bind(this);
		this.SubmitCode = this.SubmitCode.bind(this);
		this.codeCallBack = this.codeCallBack.bind(this);
		this.onSubmitCode = this.onSubmitCode.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.setState({ loading: true });
		this.CheckLogin().then((result) => {
			if (result) {
				this.setState({ logged_in: true, loading: false });
			} else {
				this.setState({ logged_in: false, loading: false });
			}
		}).catch((error) => {
			console.log("CheckLogin failed");
			this.setState({ server_failure: true });
		});
	}

	async CheckLogin() {
		const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
		return await res.data.loggedIn;
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	passwordCallBack(response, errorMessage) {

	}

	SubmitPassword(passwordCallBack) {
		var passed = true;
		var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
		if (!regExp.test(this.state.password)) {
			this.setState({ strongPassword: false });
			passed = false;
		}
		if (this.state.password !== this.state.confirmPassword) {
			this.setState({ passwordMatch: false });
			passed = false;
		}
		if (passed) {
			axios.post(APIHost + '/api/resetpassword', {
				email: this.state.codeemail,
				password: this.state.password,
				withCredentials: true
			}).then(res => {
				this.props.history.push({ pathname: '/', state: { foo: 'bar' } });
			}).catch(error => {
				console.log("error is " + error.response.data.errorMessage);
				if (error.response.data.errorMessage === "Account exists with that email") {
					this.setState({ accountCreated: false });
				} else {
					console.log('Account creation failed');
					this.setState({ failedLogin: true });
				}
			});
		}
	}

	onSubmitPassword(event) {
		event.preventDefault();
		this.setState({ strongPassword: true, passwordMatch: true });
		this.SubmitPassword(this.passwordCallBack);
	}

	codeCallBack(response, code) {
		if (response) {
			this.setState({ goodcode: true, badcode: false });
		} else if (code == 403) {
			this.setState({ codeExpired: false, noCode: false, notUser: false, badcode: true });
		} else if (code == 404) {
			this.setState({ codeExpired: false, noCode: false, notUser: true, badcode: false })
		} else if (code == 400) {
			this.setState({ codeExpired: false, noCode: true, notUser: false, badcode: false })
		} else if (code == 406) {
			this.setState({ codeExpired: true, noCode: false, notUser: false, badcode: false })
		}
	}

	SubmitCode(codeCallBack) {
		var temp_email = this.state.codeemail.toLowerCase();
		this.setState({email: temp_email});
		axios.post(APIHost + '/api/validatecode', {
			email: this.state.codeemail.toLowerCase(),
			code: this.state.code
		}).then(res => {
			codeCallBack(true);

		}).catch(error => {
			codeCallBack(false, error.response.status);
			console.log('error in codeCallBack ' + error);
		});
	}

	onSubmitCode(event) {
		event.preventDefault();
		this.SubmitCode(this.codeCallBack);
	}

	emailCallBack(response, code) {
		if (!response && code == 404) {
			this.setState({ notEmailAddress: false, emailempty: false, notUser: true });
		} else if (response) {
			this.setState({ codegensuccess: true, notEmailAddress: false, notUser: false, emailempty: false });
		} else {
			this.setState({ codegenfailed: true, notEmailAddress: false, emailempty: false });
		}
	}

	SubmitToEmail(emailCallBack) {
		var flag = true;
		var temp_email = this.state.email.toLowerCase();
		this.setState({email: temp_email});
		var regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (!regExp.test(this.state.email)) {
			this.setState({ notEmailAddress: true })
			flag = false;
		}
		if (this.state.email === '') {
			this.setState({ emailempty: true });
			flag = false;
		}
		if (flag) {
			this.setState({ emailempty: false });
			this.setState({ phoneempty: false });
			axios.post(APIHost + '/api/getresetcode', {
				email: this.state.email.toLowerCase(),
				phone: this.state.phone,
				toemail: true
			}).then(res => {
				emailCallBack(true);
			}).catch(error => {
				emailCallBack(false, error.response.status);
			});
		}
	}

	onSubmitEmail(event) {
		event.preventDefault();
		this.SubmitToEmail(this.emailCallBack);
	}

	phoneCallBack(response) {
		if (response) {
			this.setState({ codegensuccess: true });
		} else {
			this.setState({ codegenfailed: true });

		}
	}

	SubmitToPhone(phoneCallBack) {
		// when I do this - I'll need to make sure they give me an email so I can validate they have an account
		if (this.state.phone === '') {
			this.setState({ phoneempty: true });
		} else {
			this.setState({ emailempty: false });
			this.setState({ phoneempty: false });
			axios.post(APIHost + '/api/getresetcode', {
				email: this.state.email,
				phone: this.state.phone,
				tophone: true
			}).then(res => {
				console.log('result in checkLogin is ' + res.data.code);
				phoneCallBack(true);
			}).catch(error => {
				phoneCallBack(false);
			});

		}
	}

	onSubmitPhone(event) {
		event.preventDefault();
		this.SubmitToPhone(this.phoneCallBack);
	}

	render() {
		if (this.state.loading) {
			return (<h1>Loading...</h1>);
		} else if (this.state.logged_in) {
			return (<Redirect to='/' />);
		} else {
			/*<div className="form-group">
	<input type="tel" className="form-control" id="phone" name="phone" 
		placeholder="Phone number" onChange={this.onChange} />
	</div>
									<button type="submit" className="custom-btn" onClick={this.onSubmitPhone}>Send code to phone</button>*/
									console.log("Good code and bad coe " + this.state.goodcode + " and " + this.state.badcode);
			return (
				<div className="ForgotPassword">
					<Header history={this.props.history} />
					<div className="flexbox-container">
													<h2>Reset your password</h2><hr />
													</div>
					<div className="about">
					<div className="flexbox-container">

							{this.state.server_failure && <ServerFailure />}
							<div className="flex-item">
								<div className="flexbox-container-column">
									
										<form>
											<div className="form-group">
												<p>Enter email associated with your account: </p>
												<div className="form-input mt-25">
													<div className="input-items default">
														<input type="email" className="form-control" id="email" name="email"
															placeholder="Email" onChange={this.onChange} />
														<i className="lni lni-envelope"></i>
													</div>
												</div>
											</div>
											<div className="form-input light-rounded-buttons mt-30">
												<button type="submit" className="custom-btn" onClick={this.onSubmitEmail}>Send code to email</button>
											</div>
										</form>
										{this.state.emailempty && <EmptyEmail />}
										{this.state.notEmailAddress && <NotEmailAddress />}
										{this.state.phoneempty && <EmptyPhone />}
										{this.state.codegenfailed && <CodeGenerateFailed />}
										{this.state.codegensuccess && <CodeGenerateSuccess />}
										{this.state.notUser && <NotUser />}
									</div>
									</div>
									<div className="flex-item">
									<div className="flexbox-container-column">
										<form onSubmit={this.onSubmitCode}>
											<div className="form-input mt-25">
												<div className="input-items default">
													<input id="codeemail" name="codeemail"
														placeholder="Re-enter Email" onChange={this.onChange} />
													<i className="lni lni-envelope"></i>
												</div>
											</div>
											<div className="form-input mt-25">
												<div className="input-items default">
													<input type="test" id="code" name="code"
														placeholder="Enter code" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-input light-rounded-buttons mt-30">
												<button type="submit" className="custom-btn">Submit Code</button>
											</div>
										</form>
										{this.state.noCode && <NoCode />}
										{this.state.codeExpired && <CodeExpired />}
										<div className={!this.state.badcode ? 'hide' : ''}>
											<p><font color="red">The code you entered was not correct.
  									Please try again or submit for a new code</font></p>
										</div>
										<br /> <br />
										<div className={!this.state.goodcode ? 'hide' : ''}>
											<form onSubmit={this.onSubmitPassword}>
											<div className="form-input mt-25">
												<div className="input-items default">
													<input type="password" className="form-control" id="password" name="password"
														placeholder="New password" onChange={this.onChange} />
												</div>
												</div>
																							<div className="form-input mt-25">
												<div className="input-items default">
													<input type="password" className="form-control" id="confirmPassword" name="confirmPassword"
														placeholder="Confirm password" onChange={this.onChange} />
												</div>
												</div>
												<button type="submit" className="custom-btn">Update password</button>
											</form>
											{!this.state.passwordMatch && <PasswordMatch />}
											{!this.state.strongPassword && <StrongPassword />}

										</div>
									</div>
								</div>
							</div>
					</div>
					<Footer />
				</div>
			);
		}
	}
}

export default ForgotPassword;