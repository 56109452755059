import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import manny from './images/manny_thumbnail.png';
import elliot from './images/elliot.jpg';
import manny_coverage from './images/manny_coverage.png';
import ricardo from './images/ricardo.jpeg';
import mark from './images/mark_chavez.jpeg';
import norman from './images/norman.jpeg';
import steve from './images/steve_edward.jpeg';
import howard from './images/howard_berg.jpeg';
import tyrone from './images/tyrone_poole.jpeg';
import john from './images/john_shin.jpeg';
import { Link, withRouter } from 'react-router-dom';
import LAEventCheckout from './LAEventCheckout';
import axios from 'axios';
import { APIHost } from './GlobalVariables.js';

class LAEventInfo extends Component {

	constructor(props) {
		super(props);
        this.state = {
        	isLogged: true
        }
        this.DisplayPaymentInfo = this.DisplayPaymentInfo.bind(this);
        this.GetUserDetails = this.GetUserDetails.bind(this);
        this.CheckLogin = this.CheckLogin.bind(this);
        this.DirectToRegister = this.DirectToRegister.bind(this);

	}

	componentDidMount() {
				window.scroll(0, 0);
        var promises = [];
        this.CheckLogin().then(res => {
            if (res.data.loggedIn) {
                this.GetUserDetails().then(user_details => {
                this.setState({user_details: user_details.data.user_details, isLogged: true, loading: false});
                }).catch(error => {
                     console.log("GetUserDetails failed " + error);
                     /*server failure*/
                });
            } else {
                console.log("not res.data.loggedIn");
                this.setState({ isLogged: false, loading: false});
            }
        }).catch(error => {
            console.log("CheckLogin failed " + error);
            this.setState({ isLogged: false});
        });
	}

	async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    DirectToRegister() {
    	   this.props.history.push({ pathname: "/LAEventRegistration", state: { loggedIn: true } });
    }

	DisplayPaymentInfo() {
		var display = [];

		if (this.state.isLogged) {

		} else {

		}

		return display;
	}

	render() {

		return (
			<div className="LAEventInfo">
			<Header history={this.props.history} />
			<div className="flexbox-headline">
			<h1>Our Mindset's First In-Person Event!</h1>
			<h2 className="sub-headline">Change Your Life!</h2>
			</div>
			        <section id="welcome" className="flexbox-container">
                            <div className="flex-item">
                                    <h3 className="text-center">Are you ready to live the life you want?</h3>
                                    
                                    <h4 className="text-center">
                                    This is an event you don't want to miss!
                                    </h4>
                                    <h6 className="text-center pt-5">Meet the founder of Our Mindset and many others from the community</h6>
                                    <div>

                                    </div>


                                </div>
                            <div className="p-10 flex-item image-min">
<iframe width="360" height="215" src="https://www.youtube.com/embed/oKZ1TuoL-q8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                                

                            </div>    

                                   
                </section>
                            <div className="features-area">
            <div className="flexbox-headline">
            <div className="text-center">
            <h3>Thursday September 1st in L.A.</h3>
            </div>
              <div className="text-center">
            			
                                       <h3>Get registered for the event <button onClick={this.DirectToRegister} className="custom-btn">HERE</button>
                                   </h3>
                                   </div>
                                    </div>
                                    </div>

                			<div className="single-event">
				<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Manny Fernandez</h3>
				<h6>Our Mindset Founder</h6>
<p>Manny Fernandez, the founder of Our Mindset, is a Silicon Valley angel investor, angel group founder, serial entrepreneur and keynote speaker. He has been successful leading his own ventures as well as advising other startups on their paths to success. He had previously founded SF Angels Group in San Francisco, and he has been an investor with TiE Angels since 2012. Fernandez was named in Inc. Magazine’s list of the top 33 entrepreneurs to watch in 2016 and was named 2014 SF Angel Investor of the Year. </p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={manny} alt="Manny Fernandez" width="200px" heighht="200px" />

				</div>

				</div>

				<div className="flexbox-headline">
								<img width="460" height="360" src={manny_coverage} />
</div>

				<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Elliot Barnett</h3>
				<h6>Our Mindset CEO</h6>
				<p>Elliot Barnett is an inspirational speaker, a cognitive science researcher, an entrepreneur, an author, and a 
				technology innovator. With over 20 years of technology leadership experience and over 20 years of cognitive science research 
				and development, Elliot has been uniquely positioned to positively disrupt individual and corporate mental wellness approaches. 
				His passion is to provide easily accessible, affordable, and effective solutions to empower everyone to live their Optimal Life. 
				Elliot has run successful 
				businesses in e-commerce, business and sales consulting, engineering consulting, and the point-of-sale industry.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={elliot} alt="Elliot Barnett" width="200px" heighht="200px" />

				</div>
				</div>

				<div className="text-center p-5">	
			<h1>Keynote Speakers</h1>
	</div>
			<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Tyron Pool</h3>
				<h6>2X Super Bowl Champion</h6>
				<p>Tyrone Poole is much more than a best-selling author, sought-after motivational speaker and two-time Super Bowl champion. He is a change agent...someone who is able to tap into the driving forces necessary to cross goal lines.
				 Through his powerful yet empathetic approach, Tyrone helps individuals, organizations and communities make the positive changes that propel personal and professional growth. Author of “Ultimate Success In The Game Of Life,” Tyrone challenges all of us to stop settling for “average” success. Success is relative, and we all achieve some level of success in our lives. However, if you want to reach “ultimate” success – if you want to achieve goals beyond your wildest imagination – you need to follow Tyrone’s proven method for silencing the naysayers.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={tyrone} alt="Tyrone Poole" width="200px" heighht="200px" />

				</div>
				</div>
				<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Howard Berg</h3>
				<h6>Guinness World Record for World's Fastest Reader</h6>
				<p>EHoward Stephen Berg is recognized as the world’s fastest reader thanks to the cutting edge accelerated learning techniques he developed that turn information overload into information assets. Respected internationally for his contribution to the learning process, he is listed in the 1990 Guinness Book of World Records for reading more than 25,000 words a minute and writing more than 100 words a minute. Howard uses his talents to train you on how to stay on top of the information your success depends upon.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={howard} alt="Howard Berg" width="200px" heighht="200px" />

				</div>
				</div>
				<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>John Shin</h3>
				<h6>Entrepreneur, Investor, Philanthropist & Speaker</h6>
				<p>Sir John Shin is a serial entrepreneur, philanthropist, movie producer, author, motivational speaker and critically acclaimed business coach.

He has started over 26 different companies from scratch which have all become multi- million-dollar enterprises and is the Co-Founder of the ALL FOR ONE Foundation and sits on over 6 different boards of charities.

Sir John Shin also is the Executive Producer of the featured film, “Think and Grow Rich: The Legacy,” which is sweeping the world in multiple countries and the modern-day author of the bestselling book, Think and Grow Rich, written by Napoleon Hill in 1937.

His book, A Think and Grow Rich Publication, was released in 2020 called “HOW RICH ASIANS THINK AND GROW RICH,” published by the Napoleon Hill Foundation.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={john} alt="John Chen" width="200px" heighht="200px" />

				</div>
				</div>

											<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Ricardo Whyte</h3>
				<h6>Psychiatrist and Medical Doctor</h6>
				<p>Dr. Ricardo J. Whyte is an experienced medical director with a demonstrated history of almost 20 years in the healthcare industry. Dr, Whyte is the proud Founder of the Master Mind Institute that has developed the Thrive Membership that supports its members in establishing life balance and resilience fortification. 

Since 2017, Dr. Whyte has served as the Section Chief of Psychiatry at the Community Hospital of San Bernardino. Prior to his current leadership role, he was the Medical Director of the Chemical Dependency Unit at the Behavioral Medicine Center of Loma Linda University Health and the Medical Director of Outpatient Psychiatry at the Behavioral Health Institute of Loma Linda University Health. </p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={ricardo} alt="Ricardo Whyte" width="300px" heighht="300px" />

				</div>
				</div>
											<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Norman Friedman</h3>
				<h6>Clinical Psychologist</h6>
				<p>Norman J. Fried, Ph.D., is director of psycho-social services for the Division of Pediatric Hematology/Oncology at Winthrop University on Long Island, New York. A clinical psychologist with graduate degrees from Emory University, he has also taught in the medical schools of New York University and St. John's University, and has been a fellow in clinical and pediatric psychology at Harvard Medical School. Dr. Fried is a Disaster Mental Health Specialist for The American Red Cross of Greater New York, and he has a private practice in grief and bereavement counseling on Long Island.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={norman} alt="Norman Friendman" width="200px" heighht="200px" />

				</div>
				</div>
											<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Mark Chavez</h3>
				<h6>The Health MD</h6>
				<p>Dr. Mark Chavez also known as "The Health MD" on social media is a serial entrepreneur, medical doctor, inventor, coach, speaker and author of the book, The 5 Habits of Healthy People. 

Using his signature (CPR)² framework for entrepreneurialism, Dr. Chavez coaches today’s physicians to be tomorrow’s entrepreneurs.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={mark} alt="Mark Chavez" width="200px" heighht="200px" />

				</div>
				</div>
					<div className="flexbox-container item">
				<div className="flex-item-60">
				<h3>Steve Edward</h3>
				<h6>Entrepreneur and Real Estate Investor</h6>
				<p>Steve Edwards has been an entrepreneur for 36 years.  He's a founder of a software company since 2007 and is also a Real Estate Investor.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={steve} alt="Steve Edward" width="200px" heighht="200px" />

				</div>
				</div>
			</div>
	
			
			            <div className="flexbox-container">
            
              <div className="text-center">
            
                                       <h3>Get registered for the event <button onClick={this.DirectToEvents} className="custom-btn">HERE</button>
                                   </h3>
                                   </div>
                                    </div>

			<Footer />
			</div>
			);
	}

}

export default withRouter(LAEventInfo);