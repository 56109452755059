import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, withRouter } from 'react-router-dom';
import manny from './images/manny_thumbnail.png';
import elliot from './images/elliot.jpg';
import ricardo from './images/ricardo.jpeg';
import mark from './images/mark_chavez.jpeg';
import norman from './images/norman.jpeg';

class About extends Component {

	constructor(props) {
		super(props);
        this.state = {

        }
	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		return(
			<div className="About page-container">
			<div className="inner-container">
			<Header history={this.props.history} />
			<div className="m-30">
			<h1>Meet the team!</h1>
			</div>
			<div className="about">
				<div className="flexbox-container">
				<div className="flex-item-60">
				<h3>Manny Fernandez, Founder</h3>
				<p>Manny Fernandez, the founder of Our Mindset, is a Silicon Valley angel investor, angel group founder, serial entrepreneur and keynote speaker. He has been successful leading his own ventures as well as advising other startups on their paths to success. He had previously founded SF Angels Group in San Francisco, and he has been an investor with TiE Angels since 2012. Fernandez was named in Inc. Magazine’s list of the top 33 entrepreneurs to watch in 2016 and was named 2014 SF Angel Investor of the Year. </p>				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={manny} alt="Manny Fernandez" width="200px" heighht="200px" />

				</div>
				</div>

				<div className="flexbox-container">
				<div className="flex-item-60">
				<h3>Elliot Barnett, CEO</h3>
				<p>Elliot Barnett is an inspirational speaker, a cognitive science researcher, an entrepreneur, an author, and a 
				technology innovator. With over 20 years of technology leadership experience and over 20 years of cognitive science research 
				and development, Elliot has been uniquely positioned to positively disrupt individual and corporate mental wellness approaches. 
				His passion is to provide easily accessible, affordable, and effective solutions to empower everyone to live their Optimal Life. 
				His research and development have culminated in innovative scientific and technology intellectual property empowering a formalized 
				mental health program, Mental Fitness Training delivered in a revolutionary platform at My Core Insights. Elliot has run successful 
				businesses in e-commerce, business and sales consulting, engineering consulting, and the point-of-sale industry.</p>
				</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={elliot} alt="Elliot Barnett" width="200px" heighht="200px" />

				</div>
				</div>

						<div className="m-30">
			<h1>Advisory Board Members</h1>
			</div>
			</div>
			<div className="about">
				<div className="flexbox-container">
				<div className="flex-item-60">
				<h3>Dr. Ricardo Whyte</h3>
<p>Dr. Ricardo J. Whyte is an experienced medical director with a demonstrated history of almost 20 years in the healthcare industry. He is skilled in healthcare consulting, case management, prevention, addiction medicine, and healthcare information technology (HIT).  Dr, Whyte is the proud Founder of the Master Mind Institute that has developed the Thrive Membership that supports its members in establishing life balance and resilience fortification.</p>

<p>Since 2017, Dr. Whyte has served as the Section Chief of Psychiatry at the Community Hospital of San Bernardino a Dignity Health Hospital.  Prior to his current leadership role, he was the Medical Director of the Chemical Dependency Unit at the Behavioral Medicine Center of Loma Linda University Health and the Medical Director of Outpatient Psychiatry at the Behavioral Health Institute of Loma Linda University Health.  
</p>			</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={ricardo} alt="Ricardo Whyte" width="200px" heighht="200px" />

				</div>
				</div>
				<div className="flexbox-container">
				<div className="flex-item-60">
				<h3>Dr. Mark Chavez</h3>
				<p>Dr. Chavez is a medical doctor and entrepreneur who is passionate about combining health  and entrepreneurship through creating innovative ways to keep people healthy while helping them pursue their life passions.
				</p>
				<p>
				A graduate of the David Geffen School of Medicine at UCLA. He completed his residency training at Stony Brook University Hospital in Long Island, New York. His interests are in sports medicine and functional movement training. Dr. Chavez teaches a unique resistance exercise method that he created called the Gravity Ball Method which uses an exercise device he invented called the Gravity Ball. 
				</p>
				<p>
				Known as "The Health MD" under which he offers health and entrepreneurial coaching to a select number of physicians each year in his Key Physician of Influence (KPI) program. The program focus is to help transition doctors out of medicine to pursue their passion and calling.
				</p>			</div>
				<div className="flex-item-40 md:pl-20">
				 <img src={mark} alt="Mark Chavez" width="200px" heighht="200px" />

				</div>
				</div>
				</div>
				</div>
			<Footer />

			</div>
			);
	}

}

export default withRouter(About);