import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/OMTransparentLogo.png';

class Footer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="Footer">
                <footer className="footer">
                <div className="flexbox-container">
                    <div className="py-10">
                        <img src={logo} alt="Our Mindset logo" width="200" height="160" />
                        <p className="pt-5 copy">
                            © 2023 Our Mindset Technologies Inc. <br />All Rights Reserved
                        </p>
                    </div>
                    <div className="flex">
                        <div className="px-3">
                            <Link className="link" to="/PrivacyPolicy">Privacy Policy</Link>
                        </div>
                        <div className="px-3">
                            <Link className="link" to="/TermsOfUse">Terms of Use</Link>
                        </div>
                    </div>
                    <nav className="py-3">
                        <div className="py-3">
                            <Link className="link font-bold" to={{ pathname: "/" }}>Home</Link>
                                                        </div>
                                <div className="py-3">
                            <Link className="link font-bold" to={{ pathname: "/About" }}>About</Link>
                                                </div>
                                <div className="py-3">
                            <Link className="link font-bold" to={{ pathname: "/Events" }}>Events</Link>
                                                        </div>

                            <div className="py-3">
                                <Link className="link font-bold" to={{ pathname: "/Contact" }}>Contact</Link>
                            </div>
                                                        <div className="py-3">
                                <Link className="link font-bold" to={{ pathname: "/Donate" }}>Donate</Link>
                            </div>
                    </nav>
                    </div>
                </footer>
            </div>
            );
           /* return (
                <>
                    <div className="Footer">
                        <footer className="footer">
                            <div className="footer-top">
                                <div className="container">
                                    <div className="row py-3">
                                        <div className="col-md-4">
                                            <div className="f-top-left">
                                                <img src={logo} alt="My Core Insights" className="img-fluid" width="200" height="200" />
                                                <p className="copy">
                                                    © 2022 elliotbarnett.com - All Rights Reserved
                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <div className="f-top-center">
                                                <nav className="f-soc-nav">

                                                    <div className="mt-3">
                                                        <Link to="/PrivacyPolicy" className="mr-3">Privacy Policy</Link>
                                                        <Link to="/TermsOfUse">Terms of Use</Link>
                                                    </div>
                                                </nav>

                                            </div>
                                            <br /><br />

                                        </div>
                                        <div className="col-md-4 justify-content-center">
                                            <div className="f-top-right d-flex justify-content-center">

                                                <ul className="f-right-list">
                                                    <h3>Navigation</h3>
                                                    <li><Link to={{ pathname: "/" }}>Home</Link></li>
                                                    <li><Link to={{ pathname: "/About" }}>About</Link></li>
                                                    <li><Link to={{ pathname: "/Workshops" }}>Workshops</Link></li>
                                                    <li><Link to={{ pathname: "/Research" }}>Research</Link></li>
                                                    <li><Link to={{ pathname: "/Contact" }}>Contact</Link></li>
                                                    <li><Link to={{ pathname: "/Register" }}>Register</Link></li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </>
            );*/
 
    }
}

export default Footer;