import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import LAEventCheckout from './LAEventCheckout';
import { Link, withRouter } from 'react-router-dom';
import {APIHost} from './GlobalVariables.js';
import axios from 'axios';
import Popup from "reactjs-popup";


class Events extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isLogged: false,
			rooms: []
		}
    this.DirectToPaypal = this.DirectToPaypal.bind(this);
    this.GetUserDetails = this.GetUserDetails.bind(this);
    this.CheckLogin = this.CheckLogin.bind(this);
    this.GetAdmins = this.GetAdmins.bind(this);
    this.GetUserDetails = this.GetUserDetails.bind(this);
    this.RemoveRoomCallback = this.RemoveRoomCallback.bind(this);
    this.RemoveRoom = this.RemoveRoom.bind(this);
    this.SendReminderEmail = this.SendReminderEmail.bind(this);
    this.SendReminderPush = this.SendReminderPush.bind(this);
    this.SendReminderSMS = this.SendReminderSMS.bind(this);

    this.DirectToRoom = this.DirectToRoom.bind(this);

	}

	componentDidMount() {
		window.scroll(0, 0);
		var tmp_rooms = [];
		var tmp_admins = []
		var promises = [];
		var tmp_user_details = null;
    this.CheckLogin().then(res => {
		    if (res.data.loggedIn) {
				    var rooms_promise = this.GetActiveRooms().then(rooms => {
				          tmp_rooms = rooms.data.rooms;
				          return rooms;
				      }).catch((error) => {
				          console.log("Failed to get active rooms " + error);
				      });
				                     var user_promise = this.GetUserDetails().then(user_details => {
				          tmp_user_details = user_details.data.user_details;
				          return user_details;
				      }).catch(error => {
				           console.log("GetUserDetails failed " + error);
				      });

				      var admin_promise = this.GetAdmins().then(admins => {
				          tmp_admins = admins.data.admins;
				          return admins;
				      }).catch((error) => {
				          console.log("Failed to get admins " + error);
				      });
				      promises.push(rooms_promise);
				      promises.push(user_promise);
				      promises.push(admin_promise);
				      Promise.all(promises).then(() => {
				      	if(tmp_user_details.account_type == 'admin') {
				      		this.setState({is_admin: true, isLogged: true, rooms: tmp_rooms, user_details: tmp_user_details, admins: tmp_admins}, () => {this.setState({loading: false})});

				      	} else {
				      		this.setState({isLogged: true, rooms: tmp_rooms, user_details: tmp_user_details, admins: tmp_admins}, () => {this.setState({loading: false})});

				      	}
				      }).catch((error) => {

				      });
			    } else {
			    	this.GetActiveRooms().then(rooms => {
				          this.setState({isLogged: false, rooms: rooms.data.rooms}, () => {this.setState({loading: false})});
				      }).catch((error) => {
				          console.log("Failed to get active rooms " + error);
				      });
			    }
			  }).catch((error) => {

			  });


	}

	    async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    async GetAdmins() {
        const res = await axios.get(APIHost + '/api/getAdmins', { withCredentials: true });
        return await res;
    }

    async GetActiveRooms() {
        const res = await axios.get(APIHost + '/api/getActiveRooms', { withCredentials: true });
        return await res;
    }

    SendReminderEmail(event, room) {
    event.preventDefault();
    this.setState({notified_email: true});
    console.log("Room is " + room.title);
    /*
   axios.post(APIHost + '/api/notifyAboutRoomEmail', 
        {
            withCredentials: true, 
            title: room.title,
            date: room.date,
            time: room.time,
            description: room.description,
            creator: room.creator
        }).catch((error) => {  
            console.log("Notifying via email failed " + error);
        }); */
}

    SendReminderPush(event, room) {
    event.preventDefault();
    this.setState({notified_push: true});
   axios.post(APIHost + '/api/notifyAboutRoomPush', 
        {
            withCredentials: true, 
            title: room.title,
            date: room.date,
            time: room.time,
            description: room.description,
            creator: room.creator
        }).catch((error) => {  
            console.log("Notifying via text failed " + error);
        }); 
}

    SendReminderSMS(event, room) {
    event.preventDefault();
    this.setState({notified_sms: true});
   axios.post(APIHost + '/api/notifyAboutRoomSMS', 
        {
            withCredentials: true, 
            title: room.title,
            date: room.date,
            time: room.time,
            description: room.description,
            creator: room.creator
        }).catch((error) => {  
            console.log("Failed to send push notifications" + error);
        }); 
}

    DirectToRoom(room_link, room_id) {
         const win = window.open(room_link, '_blank');
          if (win != null) {
            win.focus();
          }
          axios.post(APIHost + '/api/attendedRoom', 
          {
            withCredentials: true,
            room_id: room_id
          }).catch((error) => {
            console.log("Failed in attendedRoom call " + error);
          });
      }
      
        RemoveRoomCallback(room_id) {
        var new_rooms = [];
        var cnt = 0;
        var cnt2 = 0;
        this.state.rooms.forEach((room) => {
            if (room.id != room_id) {
                new_rooms.push({});
                new_rooms[cnt2].title = this.state.rooms[cnt].title;
                new_rooms[cnt2].date = this.state.rooms[cnt].date;
                new_rooms[cnt2].time = this.state.rooms[cnt].time;
                new_rooms[cnt2].creator = this.state.rooms[cnt].creator;
                new_rooms[cnt2].id = this.state.rooms[cnt].id;
                new_rooms[cnt2].active = this.state.rooms[cnt].active;
                new_rooms[cnt2].link = this.state.rooms[cnt].link;
                new_rooms[cnt2].meeting_id = this.state.rooms[cnt].meeting_id;
                new_rooms[cnt2].password = this.state.rooms[cnt].password;
                new_rooms[cnt2].description = this.state.rooms[cnt].description;
                cnt2++;
            }
                cnt++;
        });
        this.setState({rooms: new_rooms});

    }

    RemoveRoom(e, room_id) {
        e.preventDefault();
        axios.post(APIHost + '/api/removeRoom',
            { 
                withCredentials: true,
                room_id: room_id
            }).then(() => {
                this.RemoveRoomCallback(room_id);
            }).catch((error) => {
                console.log("Removing the room failed for " + room_id + " with error " + error);
                this.setState({server_failure: true});
            });
    }
	
	DirectToPaypal(event) {
	    event.preventDefault();
	    const path = "/PayPalLAEvent";
	    this.props.history.push({ pathname: path, state: { foo: 'bar' } });
  	}


	render() {
		/*						<div className="flex-item">
						                          <Elements stripe={promise}>
                            <LAEventCheckout {...props} />
                          </Elements>

                          <div className="form-input light-rounded-buttons mt-30">
                            <div className="d-flex align-items-center justify-content-center">
                            <p>Prefer PayPal? Go here</p>
                            <button className="custom-btn light-rounded-two ml-2" id="3" onClick={this.DirectToPaypal}>PayPal</button>
                            </div>
                            </div>
						</div>*/
	
	        if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else {
        	var display_rooms = [];
        	var display_event_button = [];

        	this.state.rooms.forEach((room) => {
        		display_event_button = [];
        		var display_buttons = [];
        		        	if (this.state.is_admin) {
        		display_buttons.push(<div className="mb-5 mt-5"><button className="simple-button" onClick={(e) => this.RemoveRoom(e, room.id)}>Remove room</button>
        			<button className={!this.state.notified ? "simple-button" : ""} disabled={this.state.notified_email} onClick={(e) => this.SendReminderEmail(e, room)}>Email</button>
        			<button className={!this.state.notified ? "simple-button" : ""} disabled={this.state.notified_push} onClick={(e) => this.SendReminderPush(e, room)}>Push</button>
        			<button className={!this.state.notified ? "simple-button" : ""} disabled={this.state.notified_sms} onClick={(e) => this.SendReminderSMS(e, room)}>SMS</button>

        			</div>);
        	}
        	if (this.state.isLogged) {
        		display_event_button.push(<button className={!this.state.is_admin ? "simple-button mb-5" : "simple-button"} onClick={() => this.DirectToRoom(room.link, this.state.rooms[0].id)}>Event link</button>);
        	} else {
        		display_event_button.push(<h5><Link to="/Login" className="link">Login</Link> to access this event</h5>);
        	}
        	display_rooms.push(<div className="flexbox-container-column">
                        <div><p>Host: {room.creator}</p></div><div><p>{room.title}</p></div>
                        <div>{room.date} at {room.time} PST</div>
            <div>{this.state.rooms[0].description && <Popup trigger={<p className="room-link">Description</p>} position="bottom center">
                                <div className="gray-background">{room.description}</div>
                            </Popup>}</div>
                <div>{display_event_button}</div>
            	{display_buttons}
                </div>)
            	
              });
		return (
			<div className="Events page-container">
			<div className="inner-container">
				<Header history={this.props.history} />
				<div className="events">
<div><div className="flexbox-container">
            <div classname="text-center">
            <h2>Current Our Mindset events</h2>

            {display_rooms}
            </div>
            </div>
            </div>
				</div>


</div>
				<Footer />
			</div>
			);
		}
	}
}

export default withRouter(Events);