import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Card } from 'antd';
import { CheckCircleTwoTone, UpSquareOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import social from './images/diverse-social.jpg';
import manny from './images/manny_thumbnail.png';
import placeholder from './images/placeholder-profile-image.jpeg';
import {APIHost} from './GlobalVariables.js';
import axios from 'axios';
//import Date from 'dropdown-date';
import { Carousel } from 'react-responsive-carousel';
import './css/carousel.css';
import Avatar from '@mui/material/Avatar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ShowMoreText from "react-show-more-text";
import Textarea from 'react-expanding-textarea';
import Popup from "reactjs-popup";
import ReactPlayer from 'react-player'
import { Player } from 'video-react';
import "../node_modules/video-react/dist/video-react.css";
import { Storage } from 'aws-amplify';

const BucketPath = "https://ommobileb60e6796b93b46e694852720faf795a4142905-dev.s3.amazonaws.com/public/";

const TitleError = () => (
    <p><font color="red">You must enter a room title</font></p>
    );

const DateError = () => (
    <p><font color="red">Please pick a valid date</font></p>
    );

const ImageLoading = () => (
    <h5><font color="blue">Please wait while your media loads</font></h5>
    )

const ServerFailure = () => (
    <div className="text-center">
    <h4><font color="red">Oops, there was an issue. Please refresh the page. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h4>
    </div>
    );

const PostFailure = () => (
    <h5><font color="red">Oops, there was an issue submitting your post. Please save it and refresh the page. If it continues, please reach out to support through the <Link to="/Contact" className="link">Contact</Link> page.</font></h5>
    );

const FileLarge = () => (
    <h5><font color="red">That file is too large. The size limit is 250MB.</font></h5>
    );

const UpdateLastActivity = () => {
    axios.post(APIHost + '/api/updateLastActivity', {
      withCredentials: true
    }).catch(error => {
        console.log("Error in updateLastActivity " + error)
        return false;
    });
}

class Home extends Component {

	constructor(props) {
		super(props);
        this.state = {
            loading: true,
            isLogged: false,
            is_admin: false,
            title_error: false,
            validated_email: false,
            notified: false,
            show_thread: false,
            new_comment: false,
            created_room: false,
            posts: [],
            admins: [],
            profile_image: null,
            server_failure: false,
            post_failure: false,
            profile_url: null,
            user_details: []
        }
        //this.DirectToBiz = this.DirectToBiz.bind(this)
        this.DirectToEvents = this.DirectToEvents.bind(this);
        this.GetUserDetails = this.GetUserDetails.bind(this);
        this.CheckLogin = this.CheckLogin.bind(this);
        this.DirectToRegister = this.DirectToRegister.bind(this);
        this.DisplayLoggedIn = this.DisplayLoggedIn.bind(this);
        this.DisplayNotLoggedIn = this.DisplayNotLoggedIn.bind(this);
        this.GetAdmins = this.GetAdmins.bind(this);
        this.GetActiveRooms = this.GetActiveRooms.bind(this);
        this.RemoveRoom = this.RemoveRoom.bind(this);
        this.RemoveRoomCallback = this.RemoveRoomCallback.bind(this);
        this.GetProfileURL = this.GetProfileURL.bind(this);
        this.DirectToEdit = this.DirectToEdit.bind(this);
        this.DirectToDiscover = this.DirectToDiscover.bind(this);
        this.SendReminder = this.SendReminder.bind(this);
        this.DirectToConnections = this.DirectToConnections.bind(this);
        this.DisplayUpdateEmail = this.DisplayUpdateEmail.bind(this);
        this.ValidateEmail = this.ValidateEmail.bind(this);
        this.UpdateEmail = this.UpdateEmail.bind(this);
        this.GetPosts = this.GetPosts.bind(this);
        this.onChange = this.onChange.bind(this);
        this.ShowThread = this.ShowThread.bind(this);
        this.NewComment = this.NewComment.bind(this);
        this.FormatDate = this.FormatDate.bind(this);
        this.SubmitPost = this.SubmitPost.bind(this);
        this.AddComment = this.AddComment.bind(this);
        this.UpvoteThread = this.UpvoteThread.bind(this);
        this.UpvotePost = this.UpvotePost.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.GetPosts = this.GetPosts.bind(this);
        this.CheckIfHasComments = this.CheckIfHasComments.bind(this);
        this.DirectToPremium = this.DirectToPremium.bind(this);
        this.ToggleAudio = this.ToggleAudio.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
        var promises = [];
        var tmp_admins = [];
        var tmp_user_details;
        var tmp_rooms = [];
        var tmp_url = null;
        var tmp_posts = [];
        var tmp_threads = [];
        var audio_files = [];
        var cnt = 0;
        this.CheckLogin().then(res => {
            if (res.data.loggedIn) {
                UpdateLastActivity();
               var user_promise = this.GetUserDetails().then(user_details => {
                    tmp_user_details = user_details.data.user_details;
                    return user_details;
                }).catch(error => {
                     console.log("GetUserDetails failed " + error);
                });
                var admin_promise = this.GetAdmins().then(admins => {
                    tmp_admins = admins.data.admins;
                    return admins;
                }).catch((error) => {
                    console.log("Failed to get admins " + error);
                });
                var rooms_promise = this.GetActiveRooms().then(rooms => {
                    tmp_rooms = rooms.data.rooms;
                    return rooms;
                }).catch((error) => {
                    console.log("Failed to get active rooms " + error);
                });
                var pic_promise = this.GetProfileURL().then(url => {
                    tmp_url = url.data.URL;
                }).catch((error) => {
                    console.log("Failed to get the profile URL " + error);
                });
                var post_promise = this.GetPosts().then(posts => {
                    tmp_posts = posts.data.posts;
                    tmp_threads = posts.data.replies;
                    tmp_posts.forEach((post) => {
                        if (post.content_type == 'audio') {
                            audio_files.push({});
                            audio_files[cnt].post_id = post.id;
                            audio_files[cnt].audio_file = new Audio(post.url);
                            cnt++;
                        }
                    });
                }).catch((error) => {
                    console.log("Failed to get the posts " + error);
                });
                promises.push(user_promise);
                promises.push(admin_promise);
                promises.push(rooms_promise);
                promises.push(pic_promise);
                promises.push(post_promise);
                Promise.all(promises).then( () => {
                    var is_admin = false;
                    tmp_admins.forEach((admin) => {
                        if (admin.userid == tmp_user_details.userID) {
                            is_admin = true;
                        }
                    });
                    if (tmp_user_details.validated_email && is_admin) {
                        this.setState({audio_files: audio_files, validated_email: true, posts: tmp_posts, threads: tmp_threads, is_admin: true, user_details: tmp_user_details, admins: tmp_admins, rooms: tmp_rooms, profile_url: tmp_url, isLogged: true}, () => { this.setState({loading: false})});

                    } else if (tmp_user_details.validated_email && !is_admin) {
                        this.setState({audio_files: audio_files, validated_email: true, posts: tmp_posts, threads: tmp_threads, is_admin: false, user_details: tmp_user_details, admins: tmp_admins, rooms: tmp_rooms, profile_url: tmp_url, isLogged: true}, () => { this.setState({loading: false})});

                    } else if (!tmp_user_details.validated_email && is_admin) {
                        this.setState({audio_files: audio_files, validated_email: false, posts: tmp_posts, threads: tmp_threads, is_admin: true, user_details: tmp_user_details, admins: tmp_admins, rooms: tmp_rooms, profile_url: tmp_url, isLogged: true}, () => { this.setState({loading: false})});

                    } else {
                        this.setState({audio_files: audio_files, posts: tmp_posts, threads: tmp_threads, user_details: tmp_user_details, admins: tmp_admins, rooms: tmp_rooms, profile_url: tmp_url, isLogged: true}, () => { this.setState({loading: false})});
                    }


                        const newIntervalId = setInterval(() => {
                                this.GetPosts().then((posts) => {
                                    if(posts.data.posts.length > this.state.posts.length || posts.data.replies.length > this.state.threads.length) {
                                        cnt = 0;
                                        audio_files = [];
                                        tmp_posts.forEach((post) => {
                                            if (post.content_type == 'audio') {
                                                audio_files.push({});
                                                audio_files[cnt].post_id = post.id;
                                                audio_files[cnt].audio_file = new Audio(post.url);
                                                cnt++;
                                            }
                                        });
                                    this.setState({audio_files: audio_files, posts: posts.data.posts, threads: posts.data.replies});
                                }
                            });
                                }, 10000);
                          this.setState(prevState => {
                            return {
                              ...prevState,
                              postInterval: newIntervalId,
                            };
                          });
                }).catch((error) => {
                    console.log("promises failed " + error);
                   this.setState({server_failure: true});
                });

            } else {
                this.setState({ isLogged: false, loading: false});
            }
                
        }).catch(error => {
            console.log("CheckLogin failed " + error);
            this.setState({ isLogged: false, loading: false, server_failure: true});
        });



      /*  promises.push(login_promise);
        promises.push(user_promise);

        Promise.all(promises).then(() => {
            this.setState({loading: false});

        }).catch((error) => {
            console.log("Failed to resolve the promises " + error);
        });*/
	}

    /*componentDidUpdate(prevProps, prevState) {
       if (prevState.isLogged != this.state.isLogged || prevState.is_admin != this.state.is_admin || prevState.user_details != this.state.user_details) {
            console.log("setting loading to false and " + this.state.is_admin);
            this.setState({loading: false});
        }
    }*/
componentWillUnmount(){
    clearInterval(this.state.postInterval);
  }
    async GetProfileURL() {
        const res = await axios.get(APIHost + '/api/getProfileURL', {withCredentials: true});
        return await res;
    }

    async GetPosts() {
        const res = await axios.get(APIHost + '/api/getPosts', {withCredentials: true});
        return await res;
    }

    async GetUserDetails() {
        const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    async GetAdmins() {
        const res = await axios.get(APIHost + '/api/getAdmins', { withCredentials: true });
        return await res;
    }

    async GetActiveRooms() {
        const res = await axios.get(APIHost + '/api/getActiveRooms', { withCredentials: true });
        return await res;
    }

   DirectToRegister() {
              //navigate('/Register');
        this.props.history.push({ pathname: "/Register", state: { loggedIn: true } });

    }

    DirectToEdit() {
        this.props.history.push({ pathname: "/EditProfile", state: {loggedIn: true}});
    }

    DirectToDiscover() {
        this.props.history.push({ pathname: "/Discover", state: {loggedIn: true}});
    }

    DirectToConnections() {
        this.props.history.push({ pathname: "/ViewConnections", state: {loggedIn: true}});
    }

   DirectToEvents() {
              //navigate('/Register');
        this.props.history.push({ pathname: "/Events", state: { from_registration: true } });
    }

   DirectToPremium() {
        this.props.history.push({ pathname: "/PremiumContent", state: { from_registration: true } });
    }

SendReminder(event, room) {
    event.preventDefault();
    this.setState({notified: true});
   axios.post(APIHost + '/api/notifyAboutRoom', 
        {
            withCredentials: true, 
            title: room.title,
            date: room.date,
            time: room.time,
            description: room.description,
            creator: room.creator
        }).catch((error) => {  
            console.log("inserting the meeting failed " + error);
        }); 
}

UpvotePost(event, post_id, userid) {
    event.preventDefault();
    if (userid != this.state.user_details.userID) {
        if (!this.state[`${post_id}_voted_post`]) {
            axios.post(APIHost + '/api/postVote', 
            {
                withCredentials: true,
                post_id: post_id
            }).then((res) => {
                axios.post(APIHost + '/api/clickEvent', {
                    withCredentials: true,
                    type: 2,
                    target: post_id
                }).catch((error) => {
                    console.log("Failed to send the event " + error);
                });
                var tmp_posts = this.state.posts;
                tmp_posts.find((post) => {
                    if (post.id == post_id) {
                        post.votes = res.data.votes;
                    }
                });
                this.setState({posts: tmp_posts, [post_id+"_voted_post"]: true});
            }).catch((error) => {
                console.log("Failed to update the post vote " + error);
                this.setState({server_failure: true});
            });
        }
    }
}

UpvoteThread(event, thread_id, userid) {
    event.preventDefault();
    if (userid != this.state.user_details.userID) {
        if (!this.state[`${thread_id}_voted_thread`]) {
            axios.post(APIHost + '/api/commentVote', 
            {
                withCredentials: true,
                thread_id: thread_id
            }).then((res) => {
                axios.post(APIHost + '/api/clickEvent', {
                    withCredentials: true,
                    type: 3,
                    target: thread_id
                }).catch((error) => {
                    console.log("Failed to send the event " + error);
                });
                var tmp_threads = this.state.threads;
                tmp_threads.find((thread) => {
                    if (thread.id == thread_id) {
                        thread.votes = res.data.votes;
                    }
                });
                this.setState({threads: tmp_threads, [thread_id+"_voted_thread"]: true});
            }).catch((error) => {
                console.log("Failed to update the thread vote " + error);
                this.setState({server_failure: true});
            });
        }
    }
}

SubmitPost(event) {
    event.preventDefault();
    this.setState({wait_post: true});
    if (this.state.image_chosen) {
        this.setState({image_loading: true});
Storage.put(this.state.post_image_name, this.state.post_image).then(() => {
    const imageURL = BucketPath + this.state.post_image_name;
                        axios.post(APIHost + '/api/addPost', 
                        {
                            withCredentials: true,
                            post: this.state.newpost,
                            name: this.state.user_details.name,
                            picture_url: this.state.user_details.picture_url,
                            fileType: this.state.post_file_type,
                            post_level: 'normal',
                            url: imageURL
                        }).then((res) => {
                            var tmp_posts = this.state.posts;
                            var tmp_post = {};
                            tmp_post.id = res.data.id[0].id;
                            tmp_post.userid = this.state.user_details.userID;
                            tmp_post.post = this.state.newpost;
                            tmp_post.picture_url = this.state.user_details.picture_url;
                            tmp_post.url = imageURL;
                            tmp_post.name = this.state.user_details.name;
                            tmp_post.content_type = this.state.post_file_type;
                            tmp_post.post_level = 'normal';
                            tmp_post.date = new Date();
                            tmp_posts.unshift(tmp_post);
                            this.setState({posts: tmp_posts, submitted_post: true, wait_post: false, image_loading: false, post_image_preview: null, image_chosen: false});
                            document.getElementById("newpost").value = "";
                        }).catch((error) => {
                            console.log("Sumitting a new post failed " + error);
                            this.setState({post_failure: true});
                        });

                }).catch((error) => {
                    console.log("posting the image URL failed " + error);
                    this.setState({post_failure: true});
                });
    } else {
    axios.post(APIHost + '/api/addPost', 
    {
        withCredentials: true,
        post: this.state.newpost,
        name: this.state.user_details.name,
        post_level: 'normal',
        picture_url: this.state.user_details.picture_url
    }).then((res) => {
        console.log("res.data.id " + res.data.id[0].id);
        var tmp_posts = this.state.posts;
        var tmp_post = {};
        tmp_post.id = res.data.id[0].id;
        tmp_post.userid = this.state.user_details.userID;
        tmp_post.post = this.state.newpost;
        tmp_post.picture_url = this.state.user_details.picture_url;
        tmp_post.name = this.state.user_details.name;
        tmp_post.post_level = 'normal';
        tmp_post.date = new Date();
        tmp_posts.unshift(tmp_post);
        /*tmp_posts.push({});
        tmp_posts[tmp_posts.length-1].id = res.data.id;
        tmp_posts[tmp_posts.length-1].userid = this.state.user_details.userID;
        tmp_posts[tmp_posts.length-1].post = this.state.newpost;
        tmp_posts[tmp_posts.length-1].picture_url = this.state.user_details.picture_url;
        tmp_posts[tmp_posts.length-1].date = new Date();
        tmp_posts.sort((a, b) => a.id - b.id);*/
        this.setState({posts: tmp_posts, submitted_post: true, wait_post: false});
        document.getElementById("newpost").value = "";
    }).catch((error) => {
        console.log("Sumitting a new post failed " + error);
        this.setState({post_failure: true});
    });
}




}


AddComment(event, post_id) {
    event.preventDefault();
    this.setState({wait_post: true});
    if (this.state[`image_chosen${post_id}`]) {
        this.setState({["image_loading"+post_id]: true});
Storage.put(this.state.comment_image_name, this.state[`comment_image${post_id}`]).then(() => {
    const imageURL = BucketPath + this.state.comment_image_name;
                    axios.post(APIHost + '/api/addPostComment',
                    {
                        withCredentials: true,
                        comment: this.state.newcomment,
                        userid: this.state.user_details.userID,
                        name: this.state.user_details.name,
                        picture_url: this.state.user_details.picture_url,
                        fileType: this.state.comment_file_type,
                        post_id: post_id,
                        url: imageURL
                    }).then(() => {
                        var tmp_threads = this.state.threads;
                        tmp_threads.push({});
                        tmp_threads[tmp_threads.length-1].userid = this.state.user_details.userID;
                        tmp_threads[tmp_threads.length-1].picture_url = this.state.user_details.picture_url;
                        tmp_threads[tmp_threads.length-1].name = this.state.user_details.name;
                        tmp_threads[tmp_threads.length-1].post_id = post_id;
                        tmp_threads[tmp_threads.length-1].post = this.state.newcomment;
                        tmp_threads[tmp_threads.length-1].url = imageURL;
                        tmp_threads[tmp_threads.length-1].content_type = this.state[`comment_file_type${post_id}`];
                        this.setState({[post_id+"_comment"]: false, [post_id+"_show"]: true, threads: tmp_threads, ["image_loading"+post_id]: false, wait_post: false, ["image_chosen"+post_id]: false, ["comment_image_preview"+post_id]: null});
                        document.getElementById([`${post_id}_newcomment`]).value = "";
                    }).catch((error) => {
                        console.log("Failed to submit comment " + error);
                        this.setState({post_failure: true});
                    });

                }).catch((error) => {
                    console.log("posting the image URL failed " + error);
                    this.setState({post_failure: true});
                });
    } else {
    axios.post(APIHost + '/api/addPostComment',
    {
        withCredentials: true,
        comment: this.state.newcomment,
        userid: this.state.user_details.userID,
        name: this.state.user_details.name,
        picture_url: this.state.user_details.picture_url,
        post_id: post_id,
    }).then(() => {
        var tmp_threads = this.state.threads;
        tmp_threads.push({});
        tmp_threads[tmp_threads.length-1].userid = this.state.user_details.userID;
        tmp_threads[tmp_threads.length-1].picture_url = this.state.user_details.picture_url;
        tmp_threads[tmp_threads.length-1].name = this.state.user_details.name;
        tmp_threads[tmp_threads.length-1].post_id = post_id;
        tmp_threads[tmp_threads.length-1].post = this.state.newcomment;
        this.setState({[post_id+"_comment"]: false, [post_id+"_show"]: true, threads: tmp_threads});
        document.getElementById([`${post_id}_newcomment`]).value = "";
    }).catch((error) => {
        console.log("Failed to submit comment " + error);
        this.setState({post_failure: true});
    });
}
}

onChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    RemoveRoomCallback(room_id) {
        var new_rooms = [];
        var cnt = 0;
        var cnt2 = 0;
        this.state.rooms.forEach((room) => {
            if (room.id != room_id) {
                new_rooms.push({});
                new_rooms[cnt2].title = this.state.rooms[cnt].title;
                new_rooms[cnt2].date = this.state.rooms[cnt].date;
                new_rooms[cnt2].time = this.state.rooms[cnt].time;
                new_rooms[cnt2].creator = this.state.rooms[cnt].creator;
                new_rooms[cnt2].id = this.state.rooms[cnt].id;
                new_rooms[cnt2].active = this.state.rooms[cnt].active;
                new_rooms[cnt2].link = this.state.rooms[cnt].link;
                new_rooms[cnt2].meeting_id = this.state.rooms[cnt].meeting_id;
                new_rooms[cnt2].password = this.state.rooms[cnt].password;
                new_rooms[cnt2].description = this.state.rooms[cnt].description;
                cnt2++;
            }
                cnt++;
        });
        this.setState({rooms: new_rooms});

    }

FormatDate(date) {
  // formats a JS date to 'yyyy-mm-dd'
  var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate()
    var year = d.getFullYear();
    var month = 12;
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("-");
}

    RemoveRoom(e, room_id) {
        e.preventDefault();
        axios.post(APIHost + '/api/removeRoom',
            { 
                withCredentials: true,
                room_id: room_id
            }).then(() => {
                this.RemoveRoomCallback(room_id);
            }).catch((error) => {
                console.log("Removing the room failed for " + room_id + " with error " + error);
                this.setState({server_failure: true});
            });
    }

    DisplayUpdateEmail() {
        var display = [];
        display.push(<div className="flexbox-container-column"><h5>Please validate your email: {this.state.user_details.email}</h5>
                <button className="simple-button" onClick={this.ValidateEmail}>It's correct</button>
                <h5>Or please enter an updated email address</h5>
                                                        <div className="form-input mt-25">

                <div className="input-items default">
                <input type="email" onChange={this.onChange} name="updated_email" placeholder="Updated email" />
                                <button className="simple-button" onClick={this.UpdateEmail}>Update my email</button>

                </div>
                </div>
                <hr /></div>);
        return display;
    }

    ShowThread(event, post_id) {
        event.preventDefault();
        this.setState({[post_id+"_show"]: !this.state[`${post_id}_show`]});
    }

    NewComment(event, post_id) {
        event.preventDefault();
        this.setState({[post_id+"_comment"]: !this.state[`${post_id}_comment`]});
    }

    ValidateEmail(event) {
        event.preventDefault();
        axios.post(APIHost + '/api/validateEmail',
        {
            withCredentials: true
        }).then(() => {
            this.setState({validated_email: true});
        }).catch((error) => {
            console.log("Validating the email failed " + error);
            this.setState({server_failure: true});
        });
    }

    handleFileChange(event, type, id) {
        console.log("Type and id " + type + " and " + id + " and " + event.target.files[0].type);
        this.setState({file_large: false});
        var filetype = event.target.files[0].type.split('/');
        if (event.target.files[0]) {
            if (type == 'post') {
                if (event.target.files[0].size > 262144000) {
                    this.setState({file_large: true});
                } else {
                    const objectUrl = URL.createObjectURL(event.target.files[0])
                    this.setState({post_file_type: filetype[0], post_image_preview: objectUrl, post_image: event.target.files[0], image_chosen: true, post_image_name: event.target.files[0].name});
                }
            } else {
                if (event.target.files[0].size > 262144000) {
                    this.setState({["file_large"+id]: true});
                } else {
                    const objectUrl = URL.createObjectURL(event.target.files[0])
                    this.setState({["comment_file_type"+id]: filetype[0], comment_image_name: event.target.files[0].name, ["comment_image_preview"+id]: objectUrl, ["comment_image"+id]: event.target.files[0], ["image_chosen"+id]: true});
                }           
            }
        }
    }

    UpdateEmail(event) {
        event.preventDefault();
        axios.post(APIHost + '/api/updateEmail',
        {
            withCredentials: true,
            email: this.state.updated_email
        }).then(() => {
            this.setState({validated_email: true});
        }).catch((error) => {
            console.log("UpdateEmail failed " + error);
            this.setState({server_failure: true});
        });
    }

    DirectToRoom(room_link, room_id) {
         const win = window.open(room_link, '_blank');
          if (win != null) {
            win.focus();
          }
          axios.post(APIHost + '/api/attendedRoom', 
          {
            withCredentials: true,
            room_id: room_id
          }).catch((error) => {
            console.log("Failed in attendedRoom call " + error);
          });
      }

    CheckIfHasComments(post) {
      var flag = false;
      this.state.threads.find((thread) => {
        if (post == thread.post_id) {
          flag = true;
        }
      });
      return flag;
    }

    ToggleAudio(post_id) {
        var audio = null;
        this.state.audio_files.find((file) => {
            if (file.post_id == post_id) {
                audio = file.audio_file;
            }
        });
        if (this.state[`post_audio${post_id}`]) {
            audio.pause();
        } else {
            audio.play();
        }
        this.setState({["post_audio"+post_id]: !this.state[`post_audio${post_id}`]});
    }

    DisplayLoggedIn() {
        var display = [];
        var display_rooms = [];
        var display_remove = [];
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        };
        var individual_rooms = [];
        if (this.state.rooms == null || this.state.rooms.length == 0) {
                        display_rooms.push(<div className="flexbox-container">
              <div className="text-center">
            <div><h5>There are no active rooms at this time. Check back often!</h5></div>
                                   </div>
                                   </div>);

        } else if (this.state.rooms.length === 1) {
            if(this.state.is_admin) {
           display_rooms.push(<div><div className="flexbox-container">
            <div classname="text-center">
            <h5>Current Our Mindset rooms</h5>

            </div>
            </div>
            <div className="flexbox-container">
            <ul><li>Host: {this.state.rooms[0].creator}</li><li>{this.state.rooms[0].title}</li><li>{this.state.rooms[0].date} at {this.state.rooms[0].time} PST</li>
            {this.state.rooms[0].description && <Popup trigger={<p className="room-link">Description</p>} position="bottom center">
                                <div className="gray-background">{this.state.rooms[0].description}</div>
                            </Popup>}
                <li><button className="simple-button" onClick={() => this.DirectToRoom(this.state.rooms[0].link, this.state.rooms[0].id)}>Room link</button></li><li>Meeting ID: {this.state.rooms[0].meeting_id}</li><li>Passcode: {this.state.rooms[0].password}</li><li>Want to dial-in? Find the best number <a href="https://us06web.zoom.us/zoomconference?m=ODc4ODA1OTg4MA.LhGu_dsBb6pKWBjDH3CqmnfRw6-BYCcB" className="link">here</a></li><li><button className="custom-btn" onClick={(e) => this.RemoveRoom(e, this.state.rooms[0].id)}>Remove room</button><button className={!this.state.notified ? "custom-btn" : "simple-button"} disabled={this.state.notified} onClick={(e) => this.SendReminder(e, this.state.rooms[0])}>Send Reminder</button></li></ul>
            </div></div>);
            } else {
           display_rooms.push(<div><div className="flexbox-container">
            <div classname="text-center">
            <h5>Current Our Mindset rooms</h5>
            
            </div>
            </div>
            <div className="flexbox-container">
            <ul><li>Host: {this.state.rooms[0].creator}</li><li>{this.state.rooms[0].title}</li><li>{this.state.rooms[0].date} at {this.state.rooms[0].time} PST</li>
            {this.state.rooms[0].description && <Popup trigger={<p className="room-link">Description</p>} position="bottom center">
                                <div className="gray-background">{this.state.rooms[0].description}</div>
                            </Popup>}
                <li><button className="simple-button" onClick={() => this.DirectToRoom(this.state.rooms[0].link, this.state.rooms[0].id)}>Room link</button></li><li>Meeting ID: {this.state.rooms[0].meeting_id}</li><li>Passcode: {this.state.rooms[0].password}</li><li>Want to dial-in? Find the best number <a href="https://us06web.zoom.us/zoomconference?m=ODc4ODA1OTg4MA.LhGu_dsBb6pKWBjDH3CqmnfRw6-BYCcB" className="link">here</a></li></ul>
            </div></div>);
       }
        } else {
                    /*this.state.rooms.forEach((room) => {

            individual_rooms.push(<div className="flex-item"><ul><li>{room.title}</li><li>{room.date}</li><li>{room.time}</li><li>{room.link}</li></ul></div>);
        });*/
            /*for(var i=0; i<this.state.rooms.length; i+2) {
                individual_rooms.push(<div className="flexbox-container"><div className="flex-item"><ul><li>{this.state.rooms[i].title}</li><li>{this.state.rooms[i].date}</li>
                <li>{this.state.rooms[i].time}</li><li>{this.state.rooms[i].link}</li></ul></div>
<div className="flex-item"><ul><li>{this.state.rooms[i].title}</li><li>{this.state.rooms[i].date}</li>
                <li>{this.state.rooms[i].time}</li><li><a href={this.state.rooms[i].link} className="link">Room link</a></li></ul></div></div>);
            }*/
            if (this.state.is_admin) {
            this.state.rooms.forEach((room) => {
                individual_rooms.push(<div className="pb-10"><ul><li>Host: {room.creator}</li><li>{room.title}</li><li>{room.date} at {room.time} PST</li>
                {room.description && <Popup trigger={<p className="room-link">Description</p>} position="bottom center">
                                <div className="gray-background">{room.description}</div>
                            </Popup>}
                <li><button className="simple-button" onClick={() => this.DirectToRoom(room.link, room.id)}>Room link</button></li><li>Meting ID: {room.meeting_id}</li><li>Passcode: {room.password}</li><li>Want to dial-in? Find the best number <a href="https://us06web.zoom.us/zoomconference?m=ODc4ODA1OTg4MA.LhGu_dsBb6pKWBjDH3CqmnfRw6-BYCcB" className="link">here</a></li><li><button className="custom-btn" onClick={(e) => this.RemoveRoom(e, room.id)}>Remove room</button><button className={!this.state.notified ? "custom-btn" : "simple-button"} disabled={this.state.notified} onClick={(e) => this.SendReminder(e, room)}>Send Reminder</button></li></ul></div>);
            });
            } else {
             this.state.rooms.forEach((room) => {
                individual_rooms.push(<div className="pb-10"><ul><li>Host: {room.creator}</li><li>{room.title}</li><li>{room.date} at {room.time} PST</li>
               { room.description && <Popup trigger={<p className="room-link">Description</p>} position="bottom center">
                                <div className="gray-background">{room.description}</div>
                            </Popup>}
                <li><button className="simple-button" onClick={() => this.DirectToRoom(room.link, room.id)}>Room link</button></li><li>Meeting ID: {room.meeting_id}</li><li>Passcode: {room.password}</li><li>Want to dial-in? Find the best number <a href="https://us06web.zoom.us/zoomconference?m=ODc4ODA1OTg4MA.LhGu_dsBb6pKWBjDH3CqmnfRw6-BYCcB" className="link">here</a></li></ul></div>);
            });               
            }


                       display_rooms.push(<div><div className="flexbox-container-column">
            <div classname="text-center pb-10">

            <h5 className="text-center">Current Our Mindset rooms</h5>
                        <h5 className="text-center">Click or swipe to see additional rooms</h5>

            </div>
            <div>

                                    <Carousel showThumbs={false}>
                        {individual_rooms}
            </Carousel>


            
            </div>
            </div>
            </div>);
        }

        var display_posts = [];
        this.state.posts.forEach(post => {
            var post_media = [];
            if (post.url != null) {
                if (post.content_type == 'image') {
                    post_media.push(<img src={post.url} alt="" />)
                } else if (post.content_type == 'video') {
                    post_media.push(<Player 
                            width={300}
                            height={250}
                            fluid={false}
                            src={post.url}
                     />);
                } else if (post.content_type == 'audio') {
                    post_media.push(<button className="simple-button" onClick={() => this.ToggleAudio(post.id)}>Play audio/Pause audio</button>);
                }
            }

            var display_ext_link = [];
            if (post.ext_link != null) {
                display_ext_link.push(<iframe src={post.ext_link} width="300" height="250" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>);
            }

            var flag = this.CheckIfHasComments(post.id);
                var profile_link_post= "/ViewProfile/" + post.userid;

            var display_threads = [];     
            this.state.threads.find(thread => {

                if (thread.post_id == post.id) {
                    var profile_link_thread = "/ViewProfile/" + thread.userid;
                    var comment_media = [];
                    if (thread.url != null) {
                        if (thread.content_type == 'image') {
                            comment_media.push(<img src={thread.url} alt="" />)
                        } else {
                            comment_media.push(<Player 
                                    width={300}
                                    height={250}
                                    fluid={false}
                                    src={thread.url}
                             />);
                        }
                    }
                    var thread_date = this.FormatDate(thread.date);
                    display_threads.push(
        <div className={this.state[`${post.id}_show`] ? "thread" : "thread hide"}>
           <div className="flexbox-container-plain">
                                       <div className="flexbox-container-column">
                            <div>
                            <p>Upvote</p>
                            </div>
                            <div>
                               <p>{thread.votes}</p>
                               
                               </div>
                               <div>
                                <div onClick={(e) => this.UpvoteThread(e, thread.id, thread.userid)}>
                                <UpSquareOutlined style={{ fontSize: '50px', color: '#08c' }} />
                                </div>
                                </div>
                                </div>
                    <div className="pr-5">
                          {thread.picture_url ?  <Link to={profile_link_thread} className="link"><Avatar
                                      alt="Profile picture"
                                      src={thread.picture_url}
                                      sx={{ width: 34, height: 34 }}
                                    /></Link>: <Link to={profile_link_thread} className="link"><Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                      sx={{ width: 34, height: 34 }}
                                    /></Link>}
                    </div>
                    <div className="pr-10">
                        <p>{thread.name}</p>
                    </div>
                    <div>
                        <p>{thread_date}</p>
                    </div>
                </div>
                <div className="flexbox-container-column">
                        <div className="flexbox-container-plain">

                                    { !thread.post ?
                                                                               <div className="flex-item-80 pl-5">
                        <div className="pb-5">
                                          <p>{thread.post}</p>
                                          </div>
                                          {comment_media}
                                        </div>
                                        : 


                                        thread.post.length<61 ?
                                        <div className="pl-5">
                        <div className="pb-5">
                                          <p>{thread.post}</p>
                                          </div>
                                          {comment_media}
                                        </div>
                                    : 
                                        <div className="flex-item-80">
                                        <div className="pb-5">
                                            <ShowMoreText
                                            lines={3}
                                            more="Show more"
                                            less="Show less"
                                            className="content-css"
                                            anchorClass="show-more-less-clickable"
                                            onClick={this.executeOnClick}
                                            expanded={false}
                                            width={280}
                                            truncatedEndingComponent={"... "}>
                                        
                                            <p>{thread.post}</p>
                                            </ShowMoreText>
                                            </div>
                                            {comment_media}
                                        </div>}
                        </div>
                 </div>
 
            </div>
                    );
                }
            });
            var post_date = this.FormatDate(post.date);
/*                                            {post.url != null && post.content_type == 'image' ? <img src={post.url} alt="" /> :
                                            <iframe src={post.url} width="240" height="160" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>}*/

            var display_comment_preview = [];
            if(this.state[`comment_file_type${post.id}`] == 'image') {
                            console.log("file type for comment " + this.state[`comment_image_preview${post.id}`]);

                display_comment_preview.push(<img src={this.state[`comment_image_preview${post.id}`]} />);
            } else if (this.state[`comment_file_type${post.id}`] == 'video') {
                display_comment_preview.push(<Player 
                            width={300}
                            height={250}
                            fluid={false}
                            src={this.state[`comment_image_preview${post.id}`]} />)
            }
        display_posts.push(
        <div>
        <div className="posts">
                <div className="flexbox-container-plain">
                                            <div className="flexbox-container-column align-self">
                            <div>
                            <p>Upvote</p>
                            </div>
                            <div>
                               <p>{post.votes}</p>
                               </div>
                               <div>
                                <div onClick={(e) => this.UpvotePost(e, post.id, post.userid)}>
                                <UpSquareOutlined style={{ fontSize: '50px', color: '#08c' }} />
                                </div>
                                </div>
                                </div>
                    <div className="pr-5">
                           {post.picture_url ? <Link to={profile_link_post} className="link"><Avatar
                                      alt="Profile picture"
                                      src={post.picture_url}
                                      sx={{ width: 34, height: 34 }}
                                    /> </Link>: <Link to={profile_link_post} className="link"><Avatar
                                      alt="Profile picture"
                                      src={placeholder}
                                      sx={{ width: 34, height: 34 }}
                                    /></Link>}
                    </div>
                    <div className="pr-10">
                        <p>{post.name}</p>
                    </div>
                    <div className="pr-10">
                        <p>{post_date}</p>
                    </div>
                </div>
                    <div className="flexbox-container-column">
                        <div className="flexbox-container-plain">

                                    {!post.post ? 

                                  <div className="pl-5">
                                           
                                        <div className="pb-5">
                                            <p>{post.post}</p>
                                            </div>
                                            {post_media}
                                            {display_ext_link}
                                        </div>
                                        :

                                        post.post.length<61 && post.post.length>10
                                     ?
                                        <div className="pl-5">
                                           
                                <div className="pb-5">
                                            <p>{post.post}</p>
                                            </div>
                                                {post_media}
                                                {display_ext_link}
                                            
                                        </div>
                                    : post.post.length<10 ?
                                        <div className="pl-10">
<div className="pb-5">
                                        <p>{post.post}</p>
                                        </div>
                                                {post_media}
                                                {display_ext_link}
                                        </div>
                                    :
                                        <div>
                                        <div className="pb-5">
                                            <ShowMoreText
                                            lines={3}
                                            more="Show more"
                                            less="Show less"
                                            className="content-css"
                                            anchorClass="show-more-less-clickable"
                                            onClick={this.executeOnClick}
                                            expanded={false}
                                            width={280}
                                            truncatedEndingComponent={"... "}>
                                        
                                            <p>{post.post}</p>
                                            </ShowMoreText>
                                            </div>
                                                {post_media}
                                                {display_ext_link}
                                        </div>}
                        </div>
                    </div>
                            
                                 <div className="flexbox-container-end">
                                    <div className="pr-10 pl-10">
                                        <button onClick={(e) => this.NewComment(e, post.id)} className="post-link">comment</button>
                                    </div>
                                   {flag && <div className="pr-10 pl-10">
                                        <button onClick={(e) => this.ShowThread(e, post.id)} className="post-link">{!this.state[`${post.id}_show`] ? "show comments" : "hide comments"}</button>
                                    </div>}
                                </div>
            
        </div>
                <div className="comment-padding">

            <div className={this.state[`${post.id}_comment`] ? "new-post" : "new-post hide"}>
                <div>
                    <div className="form-input">
                        <div className="input-items default">
                            <textarea onChange={this.onChange} rows="5" cols="50" id={[`${post.id}_newcomment`]} name="newcomment"></textarea>
                        </div>

                                                                        {this.state[`image_chosen${post.id}`] && display_comment_preview}
              
                    </div>


                </div>       


<div className="flexbox-container-end">
                               
                                            <div className="pr-5">
                                            {this.state[`image_loading${post.id}`] && <ImageLoading />}
                                            </div>
                                            <div className="pr-5 pt-2">
                                                  <label class="custom-file-upload">
                                                  <input type="file" accept="image/*,video/*" onChange={(e) => this.handleFileChange(e, 'comment', post.id)} />
                                                        Choose Media
                                                    </label>
                                                  </div>
                                                  <div className="pr-5">
                        <button className="simple-button" onClick={(e) => this.AddComment(e, post.id)}>Comment</button>
                                                 
                                                 </div>
                                                 </div>
{this.state.post_failure && <PostFailure />}


            </div>
        </div>
        {display_threads}
        </div>
                
                );
             });
        

            /*var videoJsOptions = {
  autoplay: true,
  controls: true,
  sources: [{
    src: 'https://ourmindset-profile-pics-bucket.s3.amazonaws.com/52d6574c66e6fd7765ac3704c6d98686',
    type: 'video/mp4'
  }]
}
var videoJsOptions = {
  autoplay: true,
  controls: true,
  sources: [{
    src: 'https://ommobileb60e6796b93b46e694852720faf795a4142905-dev.s3.amazonaws.com/public/BC21506C-D5AD-47AA-B157-152C0B79803F.mov',
    type: 'video/mov'
  }]
}*/

  var display_preview = [];
  if (this.state.post_file_type == 'image') {
    display_preview.push(<div className="flexbox-container-plain"><img src={this.state.post_image_preview} /></div>)
  } else if (this.state.post_file_type == 'video') {
    display_preview.push(<div className="flexbox-container-plain"><Player 
                            width={300}
                            height={250}
                            fluid={false}
                            src={this.state.post_image_preview} /></div>);
  } else if (this.state.post_file_type == 'audio') {
    display_preview.push(<h5>Audio file loaded and ready to submit</h5>);
  }


        display.push(
            <div>
                   <div className="profile-header">       
                <div className="flexbox-container">
                <div className="pt-10">
<div className="flexbox-container-column">
<div>
                <Avatar
  alt="Profile picture"
  src={this.state.profile_url}
  sx={{ width: 114, height: 114 }}
/>
</div>
<div className="text-center">

<h3>Welcome! <br />{this.state.user_details.name} </h3>
</div>
</div>
                </div>
                <div className="flexbox-container-profile">
                <div className="margin-horizontal-5">
                <button className="custom-btn" onClick={this.DirectToDiscover}>Discover</button>
            </div>
            <div className="margin-horizontal-5">

                <button className="custom-btn" onClick={this.DirectToEdit}>Edit Profile</button>
                </div>
             <div>

                <button className="custom-btn" onClick={this.DirectToConnections}>Connections</button>
                </div>
                </div>
                </div>
                
                </div>
                <hr />
                <div className="about">
                
                {!this.state.validated_email && this.DisplayUpdateEmail()}
                <h2 className="text-center margin-horizontal-10">This web platform is no longer supported. Please download the Our Mindset app for <a className="link" href="https://apps.apple.com/us/app/our-mindset/id1661721366">IOS</a> or <a className="link" href="https://play.google.com/store/apps/details?id=com.OurMindset.Social">Android</a>?</h2>
                <div className="flexbox-container-buttons">
                                <div>
                <button className="custom-btn" onClick={this.DirectToEvents}>Our Mindset Events</button>
            </div>
                            <div>
                <button className="custom-btn" onClick={this.DirectToPremium}>Premium Content</button>
            </div>
                </div>

                
                </div>

            
            <div className="new-post">
            <h5>Share with the community!</h5>
            <div>
<div className="form-input">
                                            <div className="input-items default">
                                                <textarea onChange={this.onChange} rows="5" cols="50" id="newpost" name="newpost"></textarea>
                                            </div>
                                            {this.state.image_chosen && display_preview}
                                          
                                            </div>


                                                  </div>       
                                                   <div className="flexbox-container-end">
                               
                                            <div className="pr-5">
                                            {this.state.image_loading && <ImageLoading />}
                                            </div>
                                            <div className="pr-5 pt-2">
                                                  <label class="custom-file-upload">
                                                  <input type="file" accept="image/*,video/*,audio/*" onChange={(e) => this.handleFileChange(e, 'post')} />
                                                        Choose Media
                                                    </label>
                                                  </div>
                                                  <div className="pr-5">
                                                 <button className="simple-button" disabled={this.state.wait_post} onClick={this.SubmitPost}>Post</button>
                                                 </div>
                                                 </div>
                                                    {this.state.post_failure && <PostFailure />}
                                                    {this.state.file_large && <FileLarge />}
                                                 </div>
                                                 {display_posts}

        
    </div>  
    );
    return display;
    }

    DisplayNotLoggedIn() {
        var display = [];

        display.push(
        <div>
        <section id="welcome" className="flexbox-container">
                            <div className="flex-item">
                                    <h2 className="text-center">Welcome to Our Mindset</h2>
                                    <h5 className="text-center">Make Growth a Daily Habit!</h5>
                                    <h5 className="text-justified">
                                    We are a social, e-learning platform that empowers people to live a better life!
                                    </h5>
                                    <h5 className="text-center pt-5">We are changing the world One-Mindset at a time!</h5>
                                    <div>
                                    <div className="flexbox-container-column">
                                    <div><h5>What are you waiting for!?
                                    Download the Our Mindset mobile app for <a className="link" href="https://apps.apple.com/us/app/our-mindset/id1661721366">IOS</a> or <a className="link" href="https://play.google.com/store/apps/details?id=com.OurMindset.Social">Android</a> or</h5>
                                    </div>

                                    <div><button onClick={this.DirectToRegister} className="custom-btn" to="/Register">Register</button></div>
                                    </div>
             
                                    </div>


                                </div>
                            <div className="p-10 flex-item image-min">
                            <img src={social} alt="diverse social group" className="img-fluid" />
                                                                <h5 className="pt-5">"What one thing would you dare to dream if you knew you couldn't fail?"</h5><h5 className="text-align-right"> ~Manny Fernandez, Founder</h5>

                            </div>    

                                   
                </section>
            <div className="features-area">
            <div className="flexbox-container">
            
              <div className="text-center">
            
                                       <h3>Don't miss upcoming events! Head over to the <button onClick={this.DirectToEvents} className="custom-btn">Events</button> page!
                                   </h3>
                                   </div>
                                    </div>

                                               <div className="flexbox-container">
            
              <div className="text-center">
                                    <h3>You'll be in great company! Here's a few people we've welcomed to the Our Mindset community.</h3>
                                    </div>
                                    </div>
                                    
                                    <div className="flexbox-container">
                                    <div className="pt-5 md:pl-20">
                                    <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Les Brown</h6>
                                    </div>
                                    <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Grant Cardone</h6>
                                    </div>
                                     <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Pierre Dupont of the Dupont Family</h6>
                                    </div>  
                                     <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Frank Shamrock, UFC undefeated champion</h6>
                                    </div>
                                     <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Actor Glenn Morshower</h6>
                                    </div>                                 
                                    </div>
                                    <div className="pt-5 md:pl-20">
                                    <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Star on OWN Network Mahisha Dellinger</h6>
                                    </div>
                                    <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>SEAC Ramon Colon Lopez</h6>
                                    </div>
                                    <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Forbes Riley</h6>
                                    </div>
                                    <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Denis Waitley</h6>
                                    </div>
                                    <div className="list">
                                    <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#1678a6" className="mr-2" /><h6>Author John Assaraf</h6>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
            <div className="about">
                <div className="flexbox-container">
                <div className="flex-item-60 mt-20">
                                    <h2 className="text-center">A message from our founder</h2>
                                    <h4>Manny Fernandez</h4>
                                    <p>
                                        First and foremost, welcome to the future of mindset. We are changing the world one mindset at a time, and you can be part of it. I started the Our Mindset community during the pandemic to answer the need for positivity and the struggles people were facing. I wanted to create a safe and empowering community where people can learn the mindset of success and start living a better life. Our community has grown to 10s of 1000s of people now, but we aren’t stopping there. We are here to make a global impact and touch the lives of millions. Join us in the mindset revolution!</p>
                                </div>
                                
                           <div className="flex-item-40 md:pl-20 image-min">
                            <img src={manny} alt="diverse social group" width="200px" heighht="200px" />
                            </div>
                </div>
                </div>
            </div>
            );

        return display;
    }

	render() {
        if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else if (!this.state.isLogged && (this.props.location.state != null && !this.props.location.state.loggedIn)) {
                   return (
                               <div className="Home page-container">
                               <div className="inner-container">
            <Header history={this.props.history} loggedIn={this.state.isLogged} />
            {this.state.server_failure && <ServerFailure />}
            {this.DisplayNotLoggedIn()}
            </div>
            <Footer />
            </div>
           
            );
        } else if ((this.props.location.state != null && this.props.location.state.loggedIn == undefined) && this.state.isLogged) {
            return (<div className="Home page-container">
            <div className="inner-container">
            <Header history={this.props.history} loggedIn={this.state.isLogged} location={this.props.location} />
            {this.state.server_failure && <ServerFailure />}
            {this.DisplayLoggedIn()}
            </div>
            <Footer />
            </div>
            );
        } else if ((this.props.location.state != null && !this.props.location.state.loggedIn) && this.state.isLogged) {
                                      return (
                               <div className="Home page-container">
                               <div className="inner-container">
            <Header history={this.props.history} loggedIn={this.state.isLogged} />
            {this.state.server_failure && <ServerFailure />}
            {this.DisplayNotLoggedIn()}
            ?=</div>
            <Footer />
            </div>
            );
        } else if (this.state.isLogged || (this.props.location.state != null && this.props.location.state.loggedIn)) {
              return (<div className="Home page-container">
              <div className="inner-container">
            <Header history={this.props.history} loggedIn={this.state.isLogged} location={this.props.location} />
            {this.state.server_failure && <ServerFailure />}
            {this.DisplayLoggedIn()}
            </div>
            <Footer />
            </div>
            );
        } else {
                                          return (
                               <div className="Home page-container">
                               <div className="inner-container">
            <Header history={this.props.history} loggedIn={this.state.isLogged} />
            {this.state.server_failure && <ServerFailure />}
            {this.DisplayNotLoggedIn()}
            </div>
            <Footer />
            </div>
           
            );     
        }
			
	}
}

export default withRouter(Home);